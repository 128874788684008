import PropTypes from 'prop-types';
import ImageProp from '../../propTypes/imageProp';

const propType = {
  code: PropTypes.string.isRequired,
  name: PropTypes.string,
  flagEmoji: PropTypes.string,
  flag: PropTypes.shape(ImageProp.propType),
};

export default { propType };
