import PropTypes from 'prop-types';
import React from 'react';
import DefaultLayerIcon from './DefaultLayerIcon';

const FileIcon = ({ ext }) => {
  const extension = ext?.includes('.') ? ext.split('.')[1] : ext;
  let color;
  let icon;
  let fontSize = '12px';
  let className = '';
  switch (extension?.toLowerCase()) {
    case 'ppt':
    case 'pptx':
    case 'pps':
    case 'key':
    case 'odp':
      color = '#E03303';
      fontSize = '10px';
      className = 'icon-ppt';
      break;
    case 'jpg':
    case 'png':
    case 'gif':
    case 'jpeg':
    case 'bmp':
    case 'svg':
      color = '#0AC963';
      icon = 'image';
      className = 'icon-image';
      break;
    case 'xls':
    case 'xlsx':
    case 'xlm':
    case 'ods':
    case 'csv':
      color = '#00733B';
      fontSize = '11px';
      className = 'icon-xlsx';
      break;
    case 'docx':
    case 'doc':
    case 'odt':
    case 'rtf':
    case 'tex':
    case 'wpd':
      color = '#0263D1';
      fontSize = '10px';
      className = 'icon-docx';
      break;
    case 'pdf':
      color = '#E5252A';
      className = 'icon-pdf';
      break;
    case 'mov':
    case 'mp4':
    case 'mpg':
    case 'mpeg':
    case 'avi':
      color = '#FA0000';
      icon = 'media';
      break;
    default:
      color = null;
      break;
  }

  return (
    <DefaultLayerIcon
      color={color}
      ext={extension}
      icon={icon}
      fontSize={fontSize}
      iconClass={className}
    />
  );
};
FileIcon.propTypes = {
  ext: PropTypes.string.isRequired,
};

export default FileIcon;
