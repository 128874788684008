/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

function RemoveIcon({ color }) {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3392 0.988095H9.63387L9.34361 0.410679C9.28211 0.287231 9.18741 0.183395 9.07011 0.110837C8.95288 0.0382879 8.81763 -9.73342e-05 8.67977 5.42712e-06H5.15041C5.01281 -0.000524189 4.87785 0.0377188 4.76099 0.110347C4.64412 0.182984 4.55007 0.287065 4.48963 0.410679L4.19938 0.988095H0.494045C0.363016 0.988095 0.237355 1.04014 0.144704 1.13279C0.0520525 1.22544 0 1.35111 0 1.48214V2.47023C0 2.60126 0.0520525 2.72692 0.144704 2.81957C0.237355 2.91222 0.363016 2.96427 0.494045 2.96427H13.3392C13.4703 2.96427 13.5959 2.91222 13.6885 2.81957C13.7812 2.72692 13.8333 2.60126 13.8333 2.47023V1.48214C13.8333 1.35111 13.7812 1.22544 13.6885 1.13279C13.5959 1.04014 13.4703 0.988095 13.3392 0.988095ZM1.64269 14.4199C1.66626 14.7962 1.83234 15.1494 2.10712 15.4075C2.3819 15.6656 2.74472 15.8093 3.12175 15.8094H10.7115C11.0885 15.8093 11.4513 15.6656 11.7261 15.4075C12.0009 15.1494 12.167 14.7962 12.1906 14.4199L12.8452 3.95235H0.988089L1.64269 14.4199Z"
        fill={color}
      />
    </svg>
  );
}

RemoveIcon.propTypes = {
  color: PropTypes.string,
};

RemoveIcon.defaultProps = {
  color: '#FFFFFF',
};

const memoized = React.memo(RemoveIcon);
memoized.displayName = 'RemoveIcon';
export default memoized;
