/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';

export default function EditIcon({ width, height }) {
  return (
    <svg width={width} height={height} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M6.02215 16.0819L14.3204 5.35069C14.7714 4.77199 14.9318 4.10295 14.7814 3.42171C14.6512 2.80241 14.2703 2.21356 13.6991 1.76685L12.306 0.660222C11.0933 -0.304263 9.58998 -0.202735 8.72808 0.903889L7.79601 2.11305C7.67578 2.26432 7.70582 2.48768 7.85617 2.60951C7.85617 2.60951 10.2113 4.49788 10.2615 4.53849C10.4218 4.69078 10.5421 4.89383 10.5722 5.13748C10.6223 5.61466 10.2915 6.06137 9.8005 6.12229C9.56996 6.15274 9.34943 6.08167 9.18916 5.94969L6.71368 3.9801C6.59341 3.88974 6.41301 3.90904 6.31279 4.03086L0.429853 11.6453C0.0490152 12.1224 -0.0812711 12.7417 0.049015 13.3407L0.80067 16.5996C0.840752 16.7723 0.991089 16.8941 1.17148 16.8941L4.47876 16.8535C5.08008 16.8433 5.64132 16.5692 6.02215 16.0819ZM10.653 15.067H16.0459C16.5721 15.067 17 15.5005 17 16.0335C17 16.5675 16.5721 17 16.0459 17H10.653C10.1269 17 9.69897 16.5675 9.69897 16.0335C9.69897 15.5005 10.1269 15.067 10.653 15.067Z" fill="#7ED322"/>
    </svg>
  );
}

EditIcon.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
};

EditIcon.defaultProps = {
  width: 17,
  height: 17,
};
