import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

/**
 * Metronic modal container
 */
export default function Modal({ id, tabIndex, onClose, children }) {
  useEffect(() => {
    const modalEl = $(`#${id}`);
    modalEl.modal({ backdrop: 'static' });
    modalEl.on('hidden.bs.modal', () => {
      document
        .querySelectorAll('.modal-backdrop.fade.show')
        .forEach(element => element.parentNode.removeChild(element));

      if (onClose) {
        onClose(null);
      }
    });

    return () => modalEl.modal('hide');
  }, []);

  return (
    <div className="modal fade" id={id} tabIndex={tabIndex} role="dialog">
      {children}
    </div>
  );
}

Modal.propTypes = {
  /** modal container html id */
  id: PropTypes.string,
  /** React components to render inside the modal */
  children: PropTypes.node.isRequired,
  onClose: PropTypes.func,
  tabIndex: PropTypes.number,
};

Modal.defaultProps = {
  id: 'modalContainer',
  tabIndex: -1,
  onClose: null,
};
