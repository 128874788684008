import React from 'react';
import PropTypes from 'prop-types';
import translate from '../translate';
import EditIcon from '../icon/EditIcon';
import FileIcon from '../icon/FileIcon';
import DownloadIcon from '../icon/DownloadIcon';
import RemoveIcon from '../icon/RemoveIcon';

function DocumentCardItem(props) {
  const {
    file,
    onClickDownload,
    onRemoveFile,
    id,
    removeColor,
    classWrapper,
    onClickEdit,
    onClickShow,
  } = props;
  const fallbackExt =
    file?.name?.split('.')?.length > 0 ? file?.name?.split('.')[1] : ';';
  const ext = file?.ext || fallbackExt;
  const name = file?.originalName || file?.name || '';
  return (
    <div className={`document-card-wrapper ${classWrapper}`} id={id}>
      <div className="document-card">
        <div className="document-info">
          <FileIcon ext={ext} />
          <p>{name}</p>
        </div>
        {onClickEdit && (
          <button
            onClick={onClickEdit}
            type="button"
            className="documents-edit"
          >
            <EditIcon />
          </button>
        )}
        {onClickDownload && (
          <button
            onClick={onClickDownload}
            type="button"
            className="document-download"
          >
            <DownloadIcon />
          </button>
        )}
        {onRemoveFile && (
          <button
            onClick={onRemoveFile}
            type="button"
            className="document-remove"
          >
            <RemoveIcon color={removeColor} />
          </button>
        )}
        {onClickShow && (
          <button onClick={onClickShow} type="button" className="document-show">
            <i className="fa fa-file-signature" />
          </button>
        )}
      </div>
    </div>
  );
}

DocumentCardItem.propTypes = {
  file: PropTypes.shape({
    _id: PropTypes.string,
    size: PropTypes.number,
    ext: PropTypes.string,
    originalName: PropTypes.string,
    name: PropTypes.string,
    url: PropTypes.string,
  }).isRequired,
  onClickDownload: PropTypes.func,
  onRemoveFile: PropTypes.func,
  onClickEdit: PropTypes.func,
  onClickShow: PropTypes.func,
  id: PropTypes.string,
  removeColor: PropTypes.string,
  classWrapper: PropTypes.string,
};
DocumentCardItem.defaultProps = {
  onClickDownload: null,
  onRemoveFile: null,
  removeColor: null,
  onClickEdit: null,
  onClickShow: null,
  id: null,
  classWrapper: '',
};

export default translate(DocumentCardItem);
