import React, { useCallback, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import translate from '../../../translate';
import Notifications from '../../../../services/notifications';
import Request from '../../../../services/request';
import { displayUserName } from '../../../../services/utilityFunctions';
import { useModal } from '../../../../services/customHooks';
import UserFilterModal from './UserFilterModal';
import UserTypeNamesProp from '../../../propTypes/userTypeNamesProp';
import AddNewUserModal from './AddNewUserModal';

const LENGTH = 20;

const clearErrors = (selectElement, required, name) => {
  if (required) {
    const form = selectElement.closest('.form-group');
    form.removeClass('has-danger').addClass('has-success');
    form.find(`#${name}-error`).remove();
  }
};

function fetchUser(userId) {
  return new Promise((resolve, reject) => {
    new Request()
      .get(`/user/${userId}/json`, undefined, true)
      .done(result => resolve(result.data))
      .fail(err => reject(err));
  });
}

function setValue(t, selectElement, value, noneTextOption) {
  if (value && value.length) {
    const usersToFetch = (typeof value === 'string' ? [value] : value).filter(
      userId => !selectElement.find(`option[value='${userId}']`).length,
    );
    if (value !== 'none') {
      Promise.all(usersToFetch.map(fetchUser))
        .then(users => {
          users.forEach(user => {
            const optionUser = new Option(
              user._id === 'none' ? noneTextOption : displayUserName(user),
              user._id,
              false,
              true,
            );
            selectElement.append(optionUser);
          });
          selectElement.trigger('change');
        })
        .catch(err => {
          const { error } = err.responseJSON;
          Notifications.showNotificationError(t('error'), t(error));
        });
    } else {
      usersToFetch.forEach(user => {
        const optionUser = new Option(
          user._id === 'none' ? noneTextOption : displayUserName(user),
          user._id,
          false,
          true,
        );
        selectElement.append(optionUser);
      });
      selectElement.trigger('change');
    }
  }
}

function UserSelector(props) {
  const {
    t,
    id,
    name,
    url,
    filterUrl,
    required,
    onChange,
    onUnselect,
    disabled,
    value,
    className,
    multiple,
    hideAll,
    placeholder,
    transformItems,
    showFilter,
    userTypeNames,
    allowClear,
    dropdownParent,
    noneTextOption,
    query,
    showAddNewUser,
    onCloseAddUser,
  } = props;

  const languages = useRef([]);
  const hardSkills = useRef([]);

  const [{ show: isModalShown }, setShowModal] = useModal({ show: false });
  const [{ show: isAddNewUserShown }, setAddNewUserModal] = useModal({
    show: false,
  });

  const handleAddUserModal = useCallback(
    user => {
      if (onCloseAddUser) {
        onCloseAddUser(user);
      }
      setAddNewUserModal({ show: false });
    },
    [setAddNewUserModal, onCloseAddUser],
  );
  const showModal = evt => {
    evt.preventDefault();
    setShowModal({ show: true });
  };
  const hideModal = () => setShowModal({ show: false });

  useEffect(() => {
    const langEl = document.querySelector('script[data-org-languages]');
    if (langEl) {
      languages.current = JSON.parse(langEl.getAttribute('data-org-languages'));
    }
    const hsEl = document.querySelector('script[data-org-hardskills]');
    if (hsEl) {
      hardSkills.current = JSON.parse(hsEl.getAttribute('data-org-hardskills'));
    }

    const selectElement = $(`#${id}`);

    let width = '100%';
    if (showFilter) {
      width = '90%';
    }
    if (showAddNewUser) {
      width = '66%';
    }
    const select2Options = {
      allowClear,
      multiple,
      placeholder: placeholder || t('user_selector_placeholder'),
      width,
      ajax: {
        url,
        data: params => {
          const page = params.page || 1;
          return {
            term: params.term,
            page,
            start: (page - 1) * LENGTH,
            length: LENGTH,
            query: query || {},
          };
        },
        dataType: 'json',
        delay: 200,
        processResults: res => {
          let results = res.data.map(user => ({
            id: user._id,
            text: user._id === 'none' ? noneTextOption : displayUserName(user),
          }));
          if (transformItems) {
            results = transformItems(results);
          }

          const pagination = { more: res.start + LENGTH < res.recordsTotal };
          return { results, pagination };
        },
      },
    };
    if (dropdownParent) {
      select2Options.dropdownParent = $(dropdownParent);
    }
    selectElement.select2(select2Options);

    selectElement.on('select2:select', evt => {
      clearErrors(selectElement, required, name);
      onChange(evt.params.data.id);
    });

    if (!hideAll) {
      const optionAll = new Option(t('all'), 'all', false, true);
      selectElement.append(optionAll).trigger('change');
    }

    // Handle this case when we have allowClear=true on single selection mode
    if (!multiple && allowClear) {
      selectElement.on('select2:unselecting', () => {
        clearErrors(selectElement, required, name);
        onChange(null);
      });
    }

    if (onUnselect) {
      selectElement.on('select2:unselect', evt => {
        clearErrors(selectElement, required, name);
        onUnselect(evt.params.data.id);
      });
    }

    if (disabled) {
      selectElement.select2('enable', false);
    }

    return () => selectElement.select2('destroy');
  }, []);

  useEffect(() => {
    if (value) {
      setValue(t, $(`#${id}`), value, noneTextOption);
    } else {
      $(`#${id}`).val(null).trigger('change');
    }
  }, [value]);

  const handleModalClose = selected => {
    if (selected && multiple) {
      const toUnselect = value.filter(v => !selected.includes(v));
      const toSelect = selected.filter(v => !value.includes(v));
      toUnselect.forEach(v => onUnselect(v));
      toSelect.forEach(v => onChange(v));
    }
    hideModal();
  };

  const finalClassName = showFilter
    ? `select-show-filter ${className}`
    : className;

  const handleLineClick = !multiple
    ? user => {
        onChange(user._id);
        setShowModal(false);
      }
    : null;

  const handleAddNewUser = useCallback(
    show => {
      setAddNewUserModal({ show });
    },
    [setAddNewUserModal],
  );

  const buttonClassName =
    'btn btn-outline-accent m-btn m-btn--icon m-btn--icon-only m-btn--custom m-btn--pill m-btn--air user-search-btn';
  return (
    <>
      {isModalShown && (
        <UserFilterModal
          selectedUsers={value}
          onRowClick={handleLineClick}
          t={t}
          url={filterUrl}
          languages={languages.current}
          hardSkills={hardSkills.current}
          onClose={handleModalClose}
          userTypeNames={userTypeNames}
          multiple={multiple}
        />
      )}
      {isAddNewUserShown && (
        <AddNewUserModal
          t={t}
          languages={languages.current}
          hardSkills={hardSkills.current}
          onClose={handleAddUserModal}
        />
      )}
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <select
        id={id}
        name={name || id}
        value={value || ''}
        className={`m-bootstrap-select m_selectpicker ${finalClassName}`}
        data-actions-box="true"
        multiple={multiple}
        readOnly
      />
      {showFilter && !disabled && (
        <a
          style={{ marginLeft: '15px' }}
          href=""
          onClick={showModal}
          className={buttonClassName}
        >
          <i className="la la-search" />
        </a>
      )}
      {showAddNewUser && (
        <button
          type="button"
          onClick={handleAddNewUser}
          className="userSelectorAddUser"
        >
          {t('add.new.user')}
        </button>
      )}
    </>
  );
}

UserSelector.propTypes = {
  t: PropTypes.func.isRequired,
  /** Selector id used by the jQuery libraries */
  id: PropTypes.string.isRequired,
  /** Selector html name, required for validation */
  name: PropTypes.string,
  /** Class names to be added to the default ones */
  className: PropTypes.string,
  /** initial value */
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  /** onChange handler */
  onChange: PropTypes.func.isRequired,
  /** onUnselect */
  onUnselect: PropTypes.func,
  /** Whether the selector is required in the form, used to display validation on change */
  required: PropTypes.bool,
  /** Allow multiple choices */
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  hideAll: PropTypes.bool,
  placeholder: PropTypes.string,
  transformItems: PropTypes.func,
  showFilter: PropTypes.bool,
  url: PropTypes.string,
  filterUrl: PropTypes.string,
  userTypeNames: PropTypes.shape(UserTypeNamesProp.propType),
  allowClear: PropTypes.bool,
  dropdownParent: PropTypes.string,
  noneTextOption: PropTypes.string,
  query: PropTypes.shape({
    users: PropTypes.arrayOf(PropTypes.string),
  }),
  showAddNewUser: PropTypes.bool,
  onCloseAddUser: PropTypes.func,
};

UserSelector.defaultProps = {
  value: null,
  url: '/user/table/list',
  filterUrl: '/user/table/dataList',
  userTypeNames: null,
  name: null,
  className: '',
  required: false,
  onUnselect: undefined,
  multiple: false,
  disabled: false,
  hideAll: true,
  placeholder: null,
  transformItems: null,
  showFilter: false,
  allowClear: false,
  dropdownParent: null,
  noneTextOption: '(None)',
  query: null,
  showAddNewUser: false,
  onCloseAddUser: undefined,
};

export default translate(UserSelector);
