import PropTypes from 'prop-types';
import ImageProp from '../../../commonComponents/propTypes/imageProp';

const propType = {
  _id: PropTypes.string,
  identifier: PropTypes.string,
  name: PropTypes.string,
  translations: PropTypes.shape({}),
  icon: PropTypes.shape(ImageProp.propType),
  usersCanShare: PropTypes.bool,
  description: PropTypes.string,
};

export const TROPHY_FIELDS = {
  IDENTIFIER: 'identifier',
  NAME: 'name',
  TRANSLATIONS: 'translations',
  ICON: 'icon',
  USERS_CAN_SHARE: 'usersCanShare',
};

export default { propType };
