import React from 'react';
import PropTypes from 'prop-types';

/**
 * Simple wrapper to reuse the metronic base form group classes
 */
export default function FormGroup({ className, children, ignoreWrapper }) {
  if (ignoreWrapper) {
    return children;
  }
  return (
    <div className={`form-group m-form__group ${className}`}>{children}</div>
  );
}

FormGroup.propTypes = {
  /** Additional class names */
  className: PropTypes.string,
  /** Standard react children to be wrapped */
  children: PropTypes.node.isRequired,
  ignoreWrapper: PropTypes.bool,
};

FormGroup.defaultProps = {
  className: '',
  ignoreWrapper: false,
};
