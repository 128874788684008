import PropTypes from 'prop-types';

const propType = {
  url: PropTypes.string,
  thumb: PropTypes.string,
  medium: PropTypes.string,
};

const defaultProp = {
  url: '',
  thumb: '',
  medium: '',
};

export default { propType, defaultProp };
