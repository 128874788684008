import React from 'react';
import PropTypes from 'prop-types';
import { debounce } from '../../services/utilityFunctions';

/**
 * Basic mDataTable implementation,
 * see https://keenthemes.com/metronic/?page=docs&section=datatable#docs
 */
export default class List extends React.Component {
  constructor(props) {
    super(props);

    this.updateQuery = debounce(this.updateQuery.bind(this), 300);
  }

  componentDidMount() {
    setTimeout(() => {
      this.initTable();
    }, 300);
  }

  componentDidUpdate(prevProps) {
    const { query } = this.props;
    for (const key of Object.keys(query)) {
      if (query[key] !== prevProps.query[key]) {
        this.updateQuery(query);
        break;
      }
    }
  }

  componentWillUnmount() {
    $(`#${this.props.id}`).mDatatable().destroy();
  }

  initTable() {
    const {
      id,
      dataSourceURL,
      dataSourceHeaders,
      dataSourceParams,
      dataTransform,
      dataRequestTransform,
      columns,
      onInit,
      onRowClick,
      defaultPageSize,
      detail,
      query,
      sortable,
      onReload,
      defaultSortField,
    } = this.props;

    let dataConfig = {
      type: 'local',
      source: [],
    };

    if (dataSourceURL.length > 0) {
      dataConfig = {
        type: 'remote',
        source: {
          read: {
            url: dataSourceURL,
            method: 'GET',
            headers: dataSourceHeaders,
            map: raw => {
              if (dataRequestTransform) {
                return dataRequestTransform(raw);
              }
              let data = raw;
              if (raw.data) {
                data = dataTransform ? raw.data.map(dataTransform) : raw.data;
              }
              return data;
            },
            params: dataSourceParams,
          },
        },
        pageSize: defaultPageSize,
        serverPaging: true,
        serverFiltering: true,
        serverSorting: true,
        saveState: {
          cookie: false,
          webstorage: false,
        },
      };
    }

    const config = {
      data: dataConfig,
      layout: {
        theme: 'default',
        class: '',
        scroll: false,
        footer: false,
      },
      sortable,
      pagination: true,
      pagingType: 'full_numbers',
      columns,
      detail,
      rows: onRowClick
        ? {
            beforeTemplate: (row, data) =>
              row.addClass('clickable-row').on('click', () => onRowClick(data)),
          }
        : undefined,
    };

    const tableElement = $(`#${id}`);
    const table = tableElement.mDatatable(config);
    if (table && query && Object.keys(query).length !== 0) {
      table.setDataSourceParam('query', { ...query });
      table.reload();
    }
    if (defaultSortField && defaultSortField.length === 2) {
      table.sort(defaultSortField[0], defaultSortField[1]);
    }
    if (onInit) {
      table.on('m-datatable--on-init', onInit);
    }

    if (onReload) {
      table.on('m-datatable--on-reloaded', onReload);
    }
  }

  forceReload() {
    const table = $(`#${this.props.id}`).mDatatable();
    table.reload();
  }

  destroy() {
    const table = $(`#${this.props.id}`).mDatatable();
    table.destroy();
  }

  updateQuery(query) {
    const table = $(`#${this.props.id}`).mDatatable();
    const newQuery = { ...table.getDataSourceQuery(), ...query };
    table.setDataSourceParam('query', newQuery);
    const newPagination = {
      ...table.getDataSourceParam('pagination'),
      page: 1,
    };
    table.setDataSourceParam('pagination', newPagination);
    table.reload();
  }

  render() {
    return <div className="m_datatable" id={this.props.id} />;
  }
}

List.propTypes = {
  /** DataTable div container id */
  id: PropTypes.string.isRequired,
  /** Server endpoint for table data */
  dataSourceURL: PropTypes.string.isRequired,
  /** Data source constant headers */
  dataSourceHeaders: PropTypes.shape(),
  /** Data source constant params, such as a userId for a tabled displayed in the profile page */
  dataSourceParams: PropTypes.shape(),
  /** Query configuration object, where we define the filters for the lists */
  query: PropTypes.shape().isRequired,
  /** Data transformation function, typically uses utilityFunctions to convert dates/numbers, etc */
  dataTransform: PropTypes.func,
  dataRequestTransform: PropTypes.func,
  /** Column definitions, passed directly to jQuery component, see it's docs */
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      textAlign: PropTypes.string,
      width: PropTypes.number,
      sortable: PropTypes.bool,
      overflow: PropTypes.string,
      template: PropTypes.func,
    }),
  ).isRequired,
  /**
   * initialization event handler so we can do extra work, like add events to the
   * components rendered as a string
   */
  onInit: PropTypes.func,
  onReload: PropTypes.func,
  /**
   * Row click event handler, row data is passed as an argument
   */
  onRowClick: PropTypes.func,
  /**
   * Default number of items per page
   */
  defaultPageSize: PropTypes.number,
  detail: PropTypes.shape({
    title: PropTypes.string,
    content: PropTypes.func,
  }),
  sortable: PropTypes.bool,
  defaultSortField: PropTypes.arrayOf(PropTypes.string),
};

List.defaultProps = {
  dataSourceHeaders: null,
  dataSourceParams: null,
  dataTransform: null,
  dataRequestTransform: null,
  onInit: null,
  onRowClick: null,
  defaultPageSize: 20,
  detail: null,
  sortable: true,
  onReload: null,
  defaultSortField: [],
};
