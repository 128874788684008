import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from './FormGroup';
import translate from '../translate';
import DatePicker from './inputs/DatePicker';
import DateTimePicker from './inputs/DateTimePicker';
import DropZone from './inputs/DropZone';
import Select from './inputs/Select';
import FormLabel from './FormLabel';
import GoogleInput from './inputs/GoogleInput';
import TagsInput from './inputs/TagsInput';
import UserSelector from './inputs/UserSelector/UserSelector';
import Checkbox from './inputs/Checkbox';
import ToggleSwitch from './inputs/ToggleSwitch';
import PasswordInput from './PasswordInput';

/**
 * Standard html input wrapped in a FormGroup component, using metronic's base form input classes
 */
function FormInput(props) {
  const {
    inputClassName,
    containerClassName,
    id,
    name,
    type,
    value,
    onChange,
    placeholder,
    label,
    labelClassName,
    disabled,
    hint,
    currency,
    inputProps,
    groupProps,
    tooltip,
    autoFocus,
    showClassName,
  } = props;

  const renderInput = () => {
    switch (type) {
      case 'date':
        return (
          <DatePicker
            id={id}
            value={value}
            onChange={onChange}
            name={name}
            className={`form-control m-input ${inputClassName}`}
            placeholder={placeholder}
            disabled={disabled}
            {...inputProps}
          />
        );
      case 'dateTime':
        return (
          <DateTimePicker
            id={id}
            value={value}
            onChange={onChange}
            name={name}
            className={`form-control m-input ${inputClassName}`}
            placeholder={placeholder}
            disabled={disabled}
            {...inputProps}
          />
        );
      case 'dropZone':
        return (
          <DropZone
            id={id}
            name={name}
            title={placeholder}
            description=""
            value={value}
            onChange={onChange}
            disabled={disabled}
            {...inputProps}
          />
        );
      case 'googleSearch':
        return (
          <GoogleInput
            id={id}
            name={name}
            className={`form-control ${inputClassName}`}
            onChange={onChange}
            placeholder={placeholder}
            {...inputProps}
          />
        );
      case 'textarea':
        return (
          <textarea
            className={`form-control m-input ${inputClassName}`}
            id={id}
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={({ currentTarget }) => onChange(currentTarget.value)}
            readOnly={onChange ? null : true}
            disabled={disabled ? 'disabled' : ''}
            {...inputProps}
          />
        );
      case 'select':
      case 'select2':
        return (
          <Select
            type={type}
            name={name}
            className={`form-control m-input ${inputClassName}`}
            onChange={onChange}
            value={value}
            id={id}
            disabled={disabled}
            placeholder={placeholder}
            {...inputProps}
          />
        );
      case 'users':
        return (
          <UserSelector
            name={name}
            className={`form-control ${inputClassName}`}
            onChange={onChange}
            value={value}
            id={id}
            disabled={disabled}
            placeholder={placeholder}
            {...inputProps}
          />
        );
      case 'tags':
        return (
          <TagsInput
            className={inputClassName}
            id={id}
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={onChange}
            disabled={disabled}
            {...inputProps}
          />
        );
      case 'price':
        return (
          <div className="input-group">
            <div className="input-group-prepend">
              <span className="input-group-text">{currency}</span>
            </div>
            <input
              className={`form-control m-input ${inputClassName}`}
              id={id}
              type="number"
              placeholder={placeholder}
              name={name}
              value={value}
              onChange={({ currentTarget }) => onChange(currentTarget.value)}
              readOnly={onChange ? null : true}
              disabled={disabled}
              {...inputProps}
            />
          </div>
        );
      case 'checkbox':
        return (
          <Checkbox
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            className={`${
              showClassName ? 'form-control m-input' : ''
            } ${inputClassName}`}
            placeholder={placeholder}
            disabled={disabled}
            {...inputProps}
          />
        );
      case 'toggleSwitch':
        return (
          <ToggleSwitch
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            {...inputProps}
          />
        );
      case 'password2':
        return (
          <PasswordInput
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            {...props}
            {...inputProps}
          />
        );
      default:
        return (
          <input
            className={`form-control m-input ${inputClassName}`}
            id={id}
            type={type}
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={({ currentTarget }) => onChange(currentTarget.value)}
            readOnly={onChange ? null : true}
            disabled={disabled}
            /* eslint-disable-next-line jsx-a11y/no-autofocus */
            autoFocus={autoFocus}
            {...inputProps}
          />
        );
    }
  };

  return (
    <FormGroup {...groupProps}>
      {label && (
        <FormLabel
          id={id}
          label={label}
          tooltip={tooltip}
          className={labelClassName}
        />
      )}
      <div className={containerClassName}>
        {renderInput()}
        {hint && <span className="m-form__help">{hint}</span>}
      </div>
    </FormGroup>
  );
}

FormInput.propTypes = {
  t: PropTypes.func.isRequired,
  /** Additional class names for the <input /> */
  inputClassName: PropTypes.string,
  /** Additional class names for the <div /> container */
  containerClassName: PropTypes.string,
  /** Html id */
  id: PropTypes.string,
  /** Html name */
  name: PropTypes.string,
  /** Html type, "text" if omitted */
  type: PropTypes.string,
  /** Html value */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.bool,
    PropTypes.shape({}),
  ]),
  /** OnChange handler, should expect a react synthetic event */
  onChange: PropTypes.func,
  /** Html placeholder */
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** Label text, if the input has a <label> associated */
  label: PropTypes.string,
  /** Label classes if a <label> exists */
  labelClassName: PropTypes.string,
  /** Prop to put input disabled */
  disabled: PropTypes.bool,
  hint: PropTypes.string,
  currency: PropTypes.string,
  inputProps: PropTypes.shape(),
  autoFocus: PropTypes.bool,
  showClassName: PropTypes.bool,
  tooltip: PropTypes.string,
  groupProps: PropTypes.shape({}),
  query: PropTypes.shape({}),
};

FormInput.defaultProps = {
  inputClassName: '',
  containerClassName: '',
  id: undefined,
  name: undefined,
  type: 'text',
  onChange: undefined,
  placeholder: undefined,
  label: undefined,
  labelClassName: undefined,
  value: undefined,
  disabled: false,
  hint: undefined,
  currency: '€',
  inputProps: {},
  autoFocus: false,
  showClassName: true,
  tooltip: undefined,
  groupProps: {},
  query: {},
};

export default translate(FormInput);
