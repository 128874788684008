import PropTypes from 'prop-types';
import ImageProp from './imageProp';

const propType = {
  _id: PropTypes.string,
  createdAt: PropTypes.number,
  userNumber: PropTypes.number,
  username: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  email: PropTypes.string,
  password: PropTypes.string,
  genre: PropTypes.string,
  image: PropTypes.shape(ImageProp.propType),
  progressByBlock: PropTypes.shape({
    info: PropTypes.number.isRequired,
    company: PropTypes.number.isRequired,
    career: PropTypes.number.isRequired,
    experience: PropTypes.number.isRequired,
    hobbies: PropTypes.number.isRequired,
    softSkills: PropTypes.number.isRequired,
    hardSkills: PropTypes.number.isRequired,
    languages: PropTypes.number.isRequired,
    contacts: PropTypes.number.isRequired,
    moods: PropTypes.number.isRequired,
    media: PropTypes.number.isRequired,
    socialMedia: PropTypes.number.isRequired,
    legalFinances: PropTypes.number.isRequired,
  }),
  trainer: PropTypes.shape({
    biography: PropTypes.string.isRequired,
    professionalPath: PropTypes.string.isRequired,
    trainerType: PropTypes.string.isRequired,
    sessionType: PropTypes.arrayOf(PropTypes.string).isRequired,
  }),
  level: PropTypes.number,
  invoicingCode: PropTypes.string,
  competenciesDomains: PropTypes.arrayOf(PropTypes.string),
  competenciesDomainsList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      _id: PropTypes.string,
      translations: PropTypes.shape({}),
    }),
  ),
  currencyIsBlocked: PropTypes.bool,
  userCanChangeEmail: PropTypes.bool,
  userCanChangeName: PropTypes.bool,
  walletSettings: PropTypes.shape({
    isWithdrawalsEnabled: PropTypes.bool,
  }),
};

const defaultProp = {
  _id: '',
  username: '',
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  genre: '',
  image: ImageProp.defaultProp,
  competenciesDomains: [],
  competenciesDomainsList: [],
  currencyIsBlocked: false,
  invoicingCode: '',
  userCanChangeEmail: true,
  userCanChangeName: true,
  walletSettings: {
    isWithdrawalsEnabled: false,
  },
};

const ROLES = {
  SUPER_ADMIN: 'super_admin',
  INSTANCE_ADMIN: 'instance_admin',
  ADMIN: 'admin',
  USER: 'user',
};

const TYPES = {
  COLLABORATOR: 'collaborator', // co-worker
  CUSTOMER: 'customer', // client
  LEAD: 'lead',
  RECOMMENDATION: 'recommendation',
  VISITOR: 'visitor',
};

const STATUS = {
  LIMITED: 'limited',
  INVITED: 'invited',
  ACTIVE: 'active',
};

export default { propType, defaultProp, ROLES, TYPES, STATUS };
