import React from 'react';
import PropTypes from 'prop-types';

/**
 * Checkbox component wrapping the label -> input -> span pattern
 */
export default function Checkbox({ id, name, label, value, onChange, className, children, disabled }) {
  return (
    <label
      htmlFor={id}
      className={`m-checkbox m-checkbox--dynamic ${className}`}
    >
      <input
        id={id}
        type="checkbox"
        name={name}
        disabled={disabled}
        defaultChecked={value}
        onChange={onChange}
        readOnly={onChange ? null : true}
      />
      {label}
      <span />
      {children}
    </label>
  );
}

Checkbox.propTypes = {
  /** Input html id */
  id: PropTypes.string.isRequired,
  /** Input html name */
  name: PropTypes.string,
  /** Label text shown */
  label: PropTypes.string,
  /** Checkbox input value, true or false */
  value: PropTypes.bool,
  /** Change handler, should negate the previous value. If not given the component will be read only */
  onChange: PropTypes.func,
  /** String of class names to be passed on to the wrapping <label /> */
  className: PropTypes.string,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.string, PropTypes.number])),
  ]),
};

Checkbox.defaultProps = {
  name: '',
  label: '',
  onChange: null,
  className: '',
  children: null,
  value: false,
  disabled: false,
};
