import React from 'react';
import PropTypes from 'prop-types';
import { localToUtc } from '../../../services/dateUtilityFunctions';

/**
 * DateTimePicker component to be used as an <input type="date" /> in forms
 */
export default class DateTimePicker extends React.Component {
  componentDidMount() {
    const {
      id,
      value,
      onChange,
      autoClose,
      endDate,
      startDate,
      startView,
      required,
      autoComplete,
    } = this.props;

    const jqueryPicker = $(`#${id}`);
    const endDateString =
      endDate || endDate === 0
        ? new Date(endDate)
        : undefined;
    const startDateString =
      startDate || startDate === 0
        ? new Date(startDate)
        : undefined;

    jqueryPicker.datetimepicker({
      autoclose: autoClose,
      endDate: endDateString,
      startDate: startDateString,
      format: 'dd MM yyyy - hh:ii',
      startView,
      clear: true,
    });

    if (autoComplete) {
      jqueryPicker.attr('autocomplete', autoComplete);
    }

    if (value || value === 0) {
      jqueryPicker.datetimepicker('update', new Date(value));
    }

    jqueryPicker.on('hide', evt => {
      if (required) {
        if (evt.target.value) {
          $(this)
            .closest('.form-group')
            .removeClass('has-danger')
            .addClass('has-success');
        } else {
          $(this)
            .closest('.form-group')
            .removeClass('has-success')
            .addClass('has-danger');
        }
      }

      const { value: newValue } = evt.target;
      const date = newValue
        ? localToUtc(newValue, 'DD MMM YYYY - HH:mm')
        : null;
      onChange(date);
    });
  }

  componentDidUpdate(prevProps) {
    const { value, id } = this.props;
    if (value !== prevProps.value) {
      const date =
        value || value === 0
          ? new Date(value)
          : undefined;
      $(`#${id}`).datetimepicker('update', date);
    }
  }

  componentWillUnmount() {
    const { id } = this.props;
    $(`#${id}`).datetimepicker('destroy');
  }

  // eslint-disable-next-line class-methods-use-this
  cleanInput(id, onChange) {
    $(`#${id}`).val('');
    onChange(null);
  }

  render() {
    const { id, className, name, placeholder, disabled, value, onChange, required } = this.props;

    return (
      <div className="datetimepickerWrapper">
        <div className="wrapper">
          <input
            id={id}
            name={name}
            type="text"
            className={className}
            placeholder={placeholder}
            disabled={disabled}
          />
        </div>
        {!required && value && (
          <button id={`clearDateTimePicker-${id}`} type="button" onClick={() => this.cleanInput(id, onChange)}>
            <i className="la la-close" />
          </button>
        )}
      </div>
    );
  }
}

DateTimePicker.propTypes = {
  /** Element id, required for jquery functionality */
  id: PropTypes.string.isRequired,
  /** Date value in unix epoch format from moment.valueOf() or date.getTime() */
  value: PropTypes.number,
  placeholder: PropTypes.string,
  /** Change handler, receives the value in epoch format */
  onChange: PropTypes.func.isRequired,
  /** Additional class names for the <input /> */
  className: PropTypes.string,
  /** Html name */
  name: PropTypes.string,
  /** Last valid date, passed to jquery, unlike the jQuery component the date is in epoch format
   * see https://bootstrap-datepicker.readthedocs.io/en/latest/options.html */
  endDate: PropTypes.number,
  /** First valid date, passed to jquery, unlike the jQuery component the date is in epoch format
   * see https://bootstrap-datepicker.readthedocs.io/en/latest/options.html */
  startDate: PropTypes.number,
  /** Component start view, passed to jquery */
  startView: PropTypes.string,
  /** Whether the component should auto close on date select, passed to jquery */
  autoClose: PropTypes.bool,
  /** Whether the input is required in the form */
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.string,
};

DateTimePicker.defaultProps = {
  placeholder: undefined,
  endDate: undefined,
  startDate: undefined,
  startView: undefined,
  autoClose: true,
  required: false,
  className: '',
  name: '',
  value: null,
  disabled: false,
  autoComplete: 'off',
};
