import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const clearErrors = (selectElement, required, name) => {
  if (required) {
    const form = selectElement.closest('.form-group');
    form.removeClass('has-danger').addClass('has-success');
    form.find(`#${name}-error`).remove();
  }
};
/**
 * Select component, uses either
 * Select2 (https://select2.org/) or
 * Bootstrap's SelectPicker (https://developer.snapappointments.com/bootstrap-select/options/)
 * depending on the "type" prop
 */
export default function Select(props) {
  const {
    id,
    type,
    name,
    placeholder,
    searchable,
    width,
    required,
    onChange,
    onUnselect,
    disabled,
    items: propItems,
    value,
    className,
    multiple,
    allowClear,
    dropdownParent,
  } = props;
  const [items, setItems] = useState(propItems);
  useEffect(() => {
    let hasChanges = false;
    if (propItems.length !== items.length) {
      hasChanges = true;
    } else {
      for (const [i, item] of propItems.entries()) {
        const { id: newId, text: newText } = item;
        const { id: oldId, text: oldText } = items[i];
        if (newId !== oldId || newText !== oldText) {
          hasChanges = true;
          break;
        }
      }
    }
    if (hasChanges) setItems(propItems);
  }, [propItems]);
  useEffect(() => {
    const selectElement = $(`#${id}`);
    switch (type) {
      case 'selectpicker':
        selectElement.selectpicker();
        selectElement.on('change', evt => {
          clearErrors(selectElement, required, name);
          onChange(evt.currentTarget.value);
        });
        if (disabled) {
          selectElement.attr('disabled', true);
        }
        break;
      case 'select2':
      default: {
        let minimumResultsForSearch;
        if (!searchable) {
          minimumResultsForSearch = -1;
        }
        const options = {
          disabled,
          allowClear,
          placeholder,
          minimumResultsForSearch,
          width,
        };
        if (dropdownParent) {
          options.dropdownParent = $(dropdownParent);
        }
        selectElement.select2(options);
        selectElement.on('select2:select', evt => {
          clearErrors(selectElement, required, name);
          onChange(evt.params.data.id);
        });
        // Handle this case when we have allowClear=true on single selection mode
        if (!onUnselect && !multiple && allowClear) {
          selectElement.on('select2:unselecting', () => {
            clearErrors(selectElement, required, name);
            onChange(null);
          });
        }
        if (onUnselect) {
          selectElement.on('select2:unselect', evt => {
            clearErrors(selectElement, required, name);
            onUnselect(evt.params.data.id);
          });
        }
        if (disabled) {
          selectElement.select2('enable', false);
        }
      }
    }
    return () => {
      switch (type) {
        case 'selectpicker':
          selectElement.selectpicker('destroy');
          break;
        case 'select2':
        default:
          selectElement.select2('destroy');
      }
    };
  }, [items, disabled]);
  useEffect(() => {
    const selectElement = $(`#${id}`);
    selectElement.val(value);
    selectElement.trigger('change');
  }, [value]);
  return (
    <select
      id={id}
      name={name}
      defaultValue={value}
      className={`m_selectpicker ${className}`}
      data-actions-box="true"
      multiple={multiple}
      onChange={({ currentTarget }) => {
        if (type === 'select') {
          onChange(currentTarget.value);
        }
      }}
    >
      {placeholder && <option />}
      {items.map(item => (
        <option key={item.id} value={item.id}>
          {item.text}
        </option>
      ))}
    </select>
  );
}
Select.propTypes = {
  /** Selector id used by the jQuery libraries */
  id: PropTypes.string.isRequired,
  /** Selector html name, required for validation */
  name: PropTypes.string,
  /** Class names to be added to the default ones */
  className: PropTypes.string,
  /** Selector options, id will be the returned value on the onChange handler */
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  ).isRequired,
  /** initial value */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.number,
  ]),
  /** onChange handler */
  onChange: PropTypes.func.isRequired,
  /** onUnselect */
  onUnselect: PropTypes.func,
  /** Custom placeholder text */
  placeholder: PropTypes.string,
  /** Whether the selector is required in the form, used to display validation on change */
  required: PropTypes.bool,
  /** Type of selector, changes the underlying library */
  type: PropTypes.oneOf(['select', 'select2', 'selectpicker']),
  /** Whether the input has a search box */
  searchable: PropTypes.bool,
  /** Select2 width argument */
  width: PropTypes.string,
  /** Allow multiple choices */
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  allowClear: PropTypes.bool,
  dropdownParent: PropTypes.string,
};
Select.defaultProps = {
  value: null,
  name: null,
  className: '',
  placeholder: undefined,
  required: false,
  type: 'select2',
  searchable: true,
  width: '100%',
  onUnselect: undefined,
  multiple: false,
  disabled: false,
  allowClear: false,
  dropdownParent: null,
};
