/* eslint-disable */
import React, { useContext } from 'react';
import MetaContext from '../../contextProviders/MetaContext';

function DownloadIcon({ width, height, color }) {
  const { branding = {} } = useContext(MetaContext);
  const { primaryColor: brandColor } = branding || {};
  const primaryColor = brandColor || '#7ED321';
  return (
    <svg
      width="30"
      height="36"
      viewBox="0 0 30 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.2709 25.8353L20.2853 20.7706C20.7921 20.2412 20.7921 19.4118 20.2678 18.9C19.7612 18.3882 18.9401 18.3882 18.4332 18.9L15.6552 21.7235V13.2176C15.6552 12.4765 15.0787 11.8941 14.3622 11.8941C13.6459 11.8941 13.0518 12.4765 13.0518 13.2176V21.7235L10.2737 18.9C9.76703 18.3882 8.94584 18.3882 8.43914 18.9C7.93244 19.4118 7.93244 20.2412 8.43914 20.7706L13.4363 25.8353C13.5586 25.9588 13.6984 26.0471 13.8556 26.1176C14.0128 26.1882 14.1875 26.2235 14.3622 26.2235C14.5369 26.2235 14.6942 26.1882 14.8514 26.1176C15.0088 26.0471 15.1486 25.9588 15.2709 25.8353ZM27.9321 12.3981C28.3428 12.3934 28.7899 12.3882 29.1964 12.3882C29.6506 12.3882 30 12.7412 30 13.1824V27.3706C30 31.7471 26.4706 35.2941 22.1375 35.2941H8.24695C3.68667 35.2941 0 31.5706 0 26.9824V7.95882C0 3.58235 3.51194 0 7.88003 0H17.2103C17.6471 0 18.0139 0.370588 18.0139 0.811765V6.49412C18.0139 9.72353 20.6349 12.3706 23.8498 12.3882C24.5882 12.3882 25.2489 12.3938 25.8258 12.3987C26.2726 12.4025 26.6691 12.4059 27.0122 12.4059C27.261 12.4059 27.5834 12.4022 27.9321 12.3981ZM28.4197 9.82253C26.9852 9.82782 25.2921 9.82253 24.0743 9.81018C22.1418 9.81018 20.55 8.20253 20.55 6.25076V1.599C20.55 0.838412 21.4639 0.460765 21.9845 1.00959C23.3299 2.42116 25.3894 4.58435 27.1302 6.41285C27.8356 7.15375 28.4887 7.83969 29.0206 8.39841C29.5309 8.93312 29.157 9.82076 28.4197 9.82253Z"
        fill={primaryColor}
      />
    </svg>
  );
}

DownloadIcon.propTypes = {};

DownloadIcon.defaultProps = {};

const memoized = React.memo(DownloadIcon);
memoized.displayName = 'DownloadIcon';
export default memoized;
