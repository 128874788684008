/* globals toastr */
import i18n from './i18n';

const template = (title, message) => `<h5>${title}</h5><span>${message}</span>`;

function showNotificationError(title, message) {
  toastr.error(template(title, message));
}

function showNotificationWarning(title, message) {
  toastr.warning(template(title, message));
}

function showNotificationSuccess(title, message) {
  toastr.success(template(title, message));
}

function showErrorFromRequest(err, defaultErrorKey) {
  let error = i18n.__(defaultErrorKey);
  if (err && err.responseJSON) {
    if (err.responseJSON.message || err.responseJSON.error) {
      error = err.responseJSON.message || i18n.__(err.responseJSON.error);
    }
  } else if (err && err.response && err.response.data) {
    if (err.response.data.error || err.response.data.message) {
      error = err.response.data.error ? i18n.__(err.response.data.error) : err.response.data.message;
    }
  }
  showNotificationError(i18n.__('error'), error);
}

export default {
  showErrorFromRequest,
  showNotificationError,
  showNotificationWarning,
  showNotificationSuccess,
};
