import React from 'react';
import PropTypes from 'prop-types';
import FormValidator from '../../services/formValidation';

/**
 * Simple form component to encapsulate jquery validation logic, passes a submitHandler function to
 * it's children to be used as a clickHandler on any chosen button
 *
 * Any undocumented prop will be passed along to the inner <form />
 */
export default class Form extends React.Component {
  componentDidMount() {
    FormValidator.config();
    // Prevent form to send when we press enter
    $(document).on('keydown', 'form', function(event) {
      return event.key !== 'Enter';
    });
  }

  render() {
    const {
      id,
      validationRules,
      customMessages,
      submitMethod,
      onInvalidForm,
      children,
      ...rest
    } = this.props;

    const submitHandler = function submitForm(evt) {
      evt.preventDefault();

      const form = $(`#${id}`);
      const options = { rules: validationRules };
      if (customMessages) {
        options.messages = customMessages;
      }
      form.validate(options);

      if (form.valid()) {
        submitMethod();
      } else if (onInvalidForm) {
        onInvalidForm();
      } else {
        const el = document.querySelector('.error');
        if (el) {
          el.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'start',
          });
        }
      }
    };

    return (
      <form id={id} {...rest}>
        {children(submitHandler)}
      </form>
    );
  }
}

Form.propTypes = {
  /** Html form element id */
  id: PropTypes.string.isRequired,
  /** Validation rules as defined in the rules section of https://jqueryvalidation.org/validate/ */
  validationRules: PropTypes.shape({}).isRequired,
  customMessages: PropTypes.shape({}),
  /** Function to be called if the form is valid */
  submitMethod: PropTypes.func.isRequired,
  /** Function to be called if the form is invalid */
  onInvalidForm: PropTypes.func,
  /** The react child passed to the form is a function that receives a submitHandler that can be
   * passed as an onClick handler to a button */
  children: PropTypes.func.isRequired,
};

Form.defaultProps = {
  onInvalidForm: null,
  customMessages: null,
};
