import React from 'react';
import PropTypes from 'prop-types';
import UserProp from '../../propTypes/userProp';
import { displayUserName } from '../../../services/utilityFunctions';

export default function UserColumn({
  t,
  user,
  includeWrapper,
  includeInvitedStatus,
  smallFormat,
  invitedTxt,
}) {
  if (!user) {
    return null;
  }

  const avatar =
    user.image && user.image.thumb ? user.image.thumb : '/images/no_user.png';
  let customStyle = {};
  if (smallFormat) {
    customStyle = {
      marginTop: 0,
      marginBottom: 0,
    };
  }
  const Content = () => {
    const badgeClass = `user-column__invited ${
      user.status === UserProp.STATUS.LIMITED ? 'user-column__limited' : ''
    }`;
    const defaultText =
      user.status === UserProp.STATUS.INVITED
        ? t('pending_registration')
        : t('limited');
    return (
      <div className="user-column__header" style={customStyle}>
        <div className="user-column__user-img">
          <img className="user-column__img" src={avatar} alt={user.username} />
        </div>
        <div className="user-column__info">
          <div className="user-column__username">{displayUserName(user)}</div>
          {includeInvitedStatus &&
            (user.status === UserProp.STATUS.INVITED ||
              user.status === UserProp.STATUS.LIMITED) && (
              <>
                <br />
                <div className={badgeClass}>
                  <span>{invitedTxt || defaultText}</span>
                </div>
              </>
            )}
        </div>
      </div>
    );
  };
  return includeWrapper ? (
    <div className="user-column">
      <Content />
    </div>
  ) : (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Content />
    </div>
  );
}

UserColumn.propTypes = {
  t: PropTypes.func.isRequired,
  user: PropTypes.shape(UserProp.propType),
  includeWrapper: PropTypes.bool,
  includeInvitedStatus: PropTypes.bool,
  smallFormat: PropTypes.bool,
  invitedTxt: PropTypes.string,
};

UserColumn.defaultProps = {
  user: null,
  includeWrapper: false,
  includeInvitedStatus: true,
  smallFormat: false,
  invitedTxt: null,
};
