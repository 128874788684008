import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import LanguageInput from '../../../../../commonComponents/forms/inputs/LanguageInput';
import TrophyProp from '../../../../journey/propTypes/trophyProp';
import CountryProp from '../../../../../commonComponents/forms/Address/CountryProp';
import FormInput from '../../../../../commonComponents/forms/FormInput';
import ToggleSwitchWithLabel from '../../../../../commonComponents/forms/inputs/ToggleSwitchWithLabel';
import ImageInput from '../../../../../commonComponents/forms/ImageInput';
import { handleImageUpload } from '../../../../../services/utilityFunctions';
import Notifications from '../../../../../services/notifications';
import ReactLoader from '../../../../../commonComponents/loader/ReactLoader';

function LanguageForm(props) {
  const {
    t,
    id,
    organizationLanguages,
    onChange,
    defaultLanguage,
    trophy,
    formValidation,
  } = props;
  return organizationLanguages.map(language => {
    const isDefaultLanguage =
      defaultLanguage?.toLowerCase() === language?.code?.toLowerCase();
    const label = !isDefaultLanguage
      ? language.name
      : `${t('name')} (${language.name})`;
    const value = trophy?.translations?.[language.code];

    const hasError = formValidation && !formValidation?.[language?.code];
    return (
      <div>
        <LanguageInput
          key={language?.code}
          id={`${id}-${language?.code}`}
          label={label}
          language={language}
          onChange={onChange}
          value={value}
        />
        {hasError ? (
          <div className="language-inputContainer">
            <div className="language-label" />
            <span className="language-inputWrapper help-block help-block-error">
              {t('ERROR_FIELD_REQUIRED')}
            </span>
          </div>
        ) : null}
      </div>
    );
  });
}

function TrophyCard(props) {
  const {
    t,
    number,
    trophy,
    onChange,
    defaultLanguage,
    organizationLanguages,
    onRemoveTrophy,
    _csrf,
    formValidation,
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  const onUploadNewIcon = useCallback(async img => {
    try {
      setIsLoading(true);
      const data = await handleImageUpload(t, _csrf, img, false);
      onChange(trophy?._id, 'icon', data);
      setIsLoading(false);
    } catch (error) {
      Notifications.showErrorFromRequest(error, 'error_imageUpload');
      setIsLoading(false);
    }
  }, []);

  return (
    <ReactLoader isLoading={isLoading} message={t('status.processing')}>
      <div className="trophy-card">
        <h5 className="trophy-number">{t('trophy.number', number)}</h5>
        <div className="blocks-wrapper">
          <div className="left-block">
            <LanguageForm
              t={t}
              id={trophy._id}
              onChange={(id, code, value) =>
                onChange(trophy?._id, 'translations', value, code)
              }
              organizationLanguages={organizationLanguages}
              defaultLanguage={defaultLanguage}
              trophy={trophy}
              formValidation={formValidation?.translations}
            />
            <FormInput
              id={`identifier-${trophy._id}`}
              name={`identifier-${trophy._id}`}
              value={trophy?.identifier || ''}
              onChange={value => onChange(trophy?._id, 'identifier', value)}
              labelClassName="language-label"
              containerClassName="language-inputWrapper fix-margin-code"
              label={t('code')}
              groupProps={{
                className: 'language-inputContainer code-input-wrapper',
              }}
            />
            <ToggleSwitchWithLabel
              id={`usersCanShare-${trophy._id}`}
              name={`usersCanShare-${trophy._id}`}
              label={t('organization.settings.trophies.usersCanShare')}
              value={trophy.usersCanShare}
              onChange={value => onChange(trophy?._id, 'usersCanShare', value)}
              wrapperClass="form-group m-form__group row"
              labelClass="col-form-label col-4"
              inputClassWrapper="col-8"
              t={t}
            />
          </div>
          <div className="right-block">
            <div className="icon-trophy-wrapper">
              <ImageInput
                t={t}
                id={`image-${trophy._id}`}
                inputId={`imageInput-${trophy._id}`}
                name={`imageInput-${trophy._id}`}
                type="image"
                initialValue={trophy?.icon?.thumb}
                onChange={onUploadNewIcon}
                inputProps={{ cropAspect: 1 }}
                label={t('icon')}
                labelClassName="col-1"
                containerClassName="col-11"
                groupClassName="form-group m-form__group row icon-trophy-container"
                isCustom
              />
              {formValidation && !formValidation?.icon ? (
                <span className="help-block help-block-error">
                  {t('ERROR_FIELD_REQUIRED')}
                </span>
              ) : null}
            </div>
            <div className="remove-trophy-wrapper">
              <button
                className="btn btn-circle red"
                type="button"
                onClick={() => onRemoveTrophy(trophy._id)}
              >
                <i className="fa fa-times" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </ReactLoader>
  );
}

TrophyCard.propTypes = {
  t: PropTypes.func.isRequired,
  number: PropTypes.number.isRequired,
  trophy: PropTypes.shape(TrophyProp.propType).isRequired,
  onChange: PropTypes.func.isRequired,
  onRemoveTrophy: PropTypes.func.isRequired,
  defaultLanguage: PropTypes.string.isRequired,
  organizationLanguages: PropTypes.arrayOf(CountryProp.propType).isRequired,
  _csrf: PropTypes.string.isRequired,
  formValidation: PropTypes.shape({
    name: PropTypes.bool,
    icon: PropTypes.bool,
    translations: PropTypes.shape({}),
  }).isRequired,
};

export default TrophyCard;
