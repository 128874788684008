const DIRECT_SELLING_TYPE = {
  LYRIANGE: 'lyriange',
  WAKEUP: 'wakeup',
  WEELODGE: 'weelodge',
  LIVEOBEN: 'liveoben',
};

const RECURRENCE = {
  MONTHLY: 'monthly',
  WEEKLY: 'weekly',
  QUARTERLY: 'quarterly',
};

export default { DIRECT_SELLING_TYPE, RECURRENCE };
