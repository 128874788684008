import * as moment from 'moment-timezone'; // Use moment-timezone for proper timezone handling
import OrganizationDirectSelling from '../commonComponents/propTypes/OrganizationDirectSelling';

const utcToLocal = timestamp => {
  const date = new Date(timestamp);
  return date.getTime();
};

const formatDate = (timestamp, format = 'DD MMM, YYYY HH:mm') =>
  moment(new Date(timestamp).getTime()).format(format);

const localToUtc = (timestamp, format) => {
  if (format) {
    return moment(timestamp, format).utc().valueOf();
  }
  return moment(timestamp).utc().valueOf();
};

// Should return 'Europe/Lisbon' or 'Europe/Luxembourg' etc.
const getTimeZoneAbbreviation = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

const getStartDateAndEndDateInterval = (directSelling = null, date = null) => {
  const { recurrence = null, schedule = null } = directSelling || {};
  const {
    recurrenceStartDayOfWeek = null,
    recurrenceStartDayOfMonth = null,
    recurrenceStartHour = null,
    timezone = null,
  } = schedule || {};

  // Determine the date to use
  let dateToUse = date ? moment(date) : moment();
  if (timezone) {
    dateToUse = dateToUse.tz(timezone);
  }
  dateToUse = moment(dateToUse).utc();

  // Initialize start and end dates
  let startDate;
  let endDate;

  if (recurrence === OrganizationDirectSelling.RECURRENCE.WEEKLY) {
    // Start and end of the current week
    const startOfWeek = dateToUse.clone().startOf('isoWeek');
    const startOfWeekDay = dateToUse.clone().startOf('isoWeek').isoWeekday();
    // Adjust startDate based on recurrenceStartDayOfWeek
    if (recurrenceStartDayOfWeek) {
      const currentDayOfWeek = moment()
        .isoWeekday(recurrenceStartDayOfWeek)
        .isoWeekday();
      if (currentDayOfWeek > startOfWeekDay) {
        // If the recurrenceStartDayOfWeek is after start of weekDay
        startDate = startOfWeek
          .clone()
          .subtract(6, 'days')
          .isoWeekday(recurrenceStartDayOfWeek);
      } else {
        // If the recurrenceStartDayOfWeek is earlier in the week
        startDate = startOfWeek.clone().isoWeekday(recurrenceStartDayOfWeek);
      }
      endDate = startDate.clone().add(6, 'days');
    } else {
      startDate = startOfWeek;
      endDate = startOfWeek.clone().endOf('isoWeek');
    }
    // Adjust for recurrenceStartHour if exists
    if (recurrenceStartHour) {
      startDate = startDate.startOf('day').add(recurrenceStartHour, 'hours');
      endDate = endDate.startOf('day').add(recurrenceStartHour, 'hours');
    }
  } else if (
    recurrence === OrganizationDirectSelling.RECURRENCE.MONTHLY ||
    !recurrence
  ) {
    // Start and end of the current month
    startDate = dateToUse.clone().startOf('month');
    endDate = dateToUse.clone().endOf('month');

    // Adjust startDate based on recurrenceStartDayOfWeek
    if (recurrenceStartDayOfMonth) {
      startDate = startDate.date(recurrenceStartDayOfMonth);
      endDate = startDate.clone().add(1, 'month').subtract(1, 'day');
    }

    // Adjust for recurrenceStartHour if available
    if (recurrenceStartHour) {
      startDate = startDate.startOf('day').add(recurrenceStartHour, 'hours');
      endDate = endDate.startOf('day').add(recurrenceStartHour, 'hours');
    }
  } else {
    // Default to start and end of the month if no recurrence is specified
    startDate = dateToUse.startOf('month');
    endDate = dateToUse.endOf('month');
  }

  // Ensure endDate doesn't go past today
  const today = moment().endOf('day');
  if (endDate.isAfter(today)) {
    endDate = today;
  }
  if (startDate.isAfter(today)) {
    startDate = today;
  }

  return [startDate, endDate];
};
const RECURRENCE_INTERVALS = {
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  THIS_WEEK: 'this_week',
  LAST_WEEK: 'last_week',
  TWO_WEEKS_AGO: 'two_weeks_ago',
  THIS_MONTH: 'this_month',
  LAST_MONTH: 'last_month',
  TWO_MONTHS_AGO: 'two_months_ago',
  THIS_QUARTERLY: 'this_quarterly',
  LAST_QUARTERLY: 'last_quarterly',
  TWO_QUARTERLY_AGO: 'two_quarterly_ago',
};

const WEEKLY_INTERVALS = {
  THIS_WEEK: RECURRENCE_INTERVALS.THIS_WEEK,
  LAST_WEEK: RECURRENCE_INTERVALS.LAST_WEEK,
  TWO_WEEKS_AGO: RECURRENCE_INTERVALS.TWO_WEEKS_AGO,
};

const MONTHLY_INTERVALS = {
  THIS_MONTH: RECURRENCE_INTERVALS.THIS_MONTH,
  LAST_MONTH: RECURRENCE_INTERVALS.LAST_MONTH,
  TWO_MONTHS_AGO: RECURRENCE_INTERVALS.TWO_MONTHS_AGO,
};

const QUARTERLY_INTERVALS = {
  THIS_QUARTERLY: RECURRENCE_INTERVALS.THIS_QUARTERLY,
  LAST_QUARTERLY: RECURRENCE_INTERVALS.LAST_QUARTERLY,
  TWO_QUARTERLY_AGO: RECURRENCE_INTERVALS.TWO_QUARTERLY_AGO,
};

const RECURRENCE_INTERVAL_CUSTOM = 'custom';

/**
 * Determines if the given startDate and endDate form a valid report interval
 * based on the recurrence schedule of the direct selling organization.
 *
 * @param {Object} directSelling - The direct selling organization data.
 * @param {number} startDate - The start date of the interval (in milliseconds).
 * @param {number} endDate - The end date of the interval (in milliseconds).
 * @param {string} rangeType - The type of recurrence interval to check.
 * @returns {boolean} - Returns true if the interval matches the recurrence rules or if rangeType is valid, false otherwise.
 */
const checkIfIsRecurrenceInterval = (
  directSelling,
  startDate,
  endDate,
  rangeType,
) => {
  const {
    recurrence = OrganizationDirectSelling.RECURRENCE.MONTHLY,
    schedule = {},
  } = directSelling || {};
  const { recurrenceStartDayOfWeek = 1, recurrenceStartDayOfMonth = 1 } =
    schedule || {};

  const startMoment = moment(startDate).utc();
  const endMoment = moment(endDate).utc();

  const recurrenceIntervals = () => {
    switch (recurrence) {
      case OrganizationDirectSelling.RECURRENCE.WEEKLY:
        return WEEKLY_INTERVALS;
      case OrganizationDirectSelling.RECURRENCE.QUARTERLY:
        return QUARTERLY_INTERVALS;
      default:
        return MONTHLY_INTERVALS;
    }
  };

  // Check if the rangeType is one of the defined intervals
  if (Object.values(recurrenceIntervals()).includes(rangeType)) {
    return true;
  }

  // Monthly recurrence logic
  if (
    !recurrence ||
    recurrence === OrganizationDirectSelling.RECURRENCE.MONTHLY
  ) {
    const isStartOfMonth = startMoment.date() === 1; // Check if the start date is the first day of the month
    const isEndOfMonth = endMoment.isSame(
      endMoment.clone().endOf('month'),
      'day',
    ); // Check if the end date is the last day of the month

    return isStartOfMonth && isEndOfMonth; // Return true if both conditions are met
  }

  // Weekly recurrence logic
  if (recurrence === OrganizationDirectSelling.RECURRENCE.WEEKLY) {
    const isOneWeekInterval = endMoment.diff(startMoment, 'days') === 6; // Ensure it's a full week
    const isStartOfWeek = startMoment.isoWeekday() === recurrenceStartDayOfWeek; // Check if it's the defined start of the week
    return isStartOfWeek && isOneWeekInterval;
  }

  // Quarterly recurrence logic
  if (recurrence === OrganizationDirectSelling.RECURRENCE.QUARTERLY) {
    const isStartOfQuarter =
      [0, 3, 6, 9].includes(startMoment.month()) && // Check if the month is the start of a quarter
      startMoment.date() === recurrenceStartDayOfMonth; // Check the defined day of the month
    const isThreeMonthInterval = endMoment.isSame(
      startMoment.clone().add(3, 'months').date(recurrenceStartDayOfMonth),
      'day', // Check if the end date is exactly 3 months later on the same day
    );
    return isStartOfQuarter && isThreeMonthInterval;
  }

  return false; // Default case if no recurrence matches
};

export {
  utcToLocal,
  formatDate,
  localToUtc,
  getTimeZoneAbbreviation,
  getStartDateAndEndDateInterval,
  checkIfIsRecurrenceInterval,
  RECURRENCE_INTERVAL_CUSTOM,
};
