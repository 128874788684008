import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

function PasswordInput(props) {
  const {
    id,
    name,
    value,
    onChange,
    autoFocus,
    showEyeIcon,
    inputClassName,
    inputProps,
    disabled,
    placeholder,
  } = props;
  const [show, setShow] = useState(false);

  const handleClick = useCallback(() => {
    setShow(prevShow => !prevShow);
  }, [setShow]);

  return (
    <div className="password-wrapper">
      <input
        className={`form-control m-input ${inputClassName}`}
        id={id}
        type={show ? 'text' : 'password'}
        placeholder={placeholder}
        name={name}
        value={value || ''}
        onChange={({ currentTarget }) => onChange(currentTarget.value)}
        readOnly={onChange ? null : true}
        disabled={disabled}
        /* eslint-disable-next-line jsx-a11y/no-autofocus */
        autoFocus={autoFocus}
        {...inputProps}
      />
      {showEyeIcon ? (
        <button
          type="button"
          className="password-toggle"
          id="togglePassword"
          onClick={handleClick}
        >
          <i className={`fa ${show ? 'fa-eye-slash' : 'fa-eye'}`} />
        </button>
      ) : null}
    </div>
  );
}

PasswordInput.propTypes = {
  t: PropTypes.func.isRequired,
  /** Additional class names for the <input /> */
  inputClassName: PropTypes.string,
  /** Additional class names for the <div /> container */
  containerClassName: PropTypes.string,
  /** Html id */
  id: PropTypes.string,
  /** Html name */
  name: PropTypes.string,
  /** Html type, "text" if omitted */
  type: PropTypes.string,
  /** Html value */
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.bool,
    PropTypes.shape({}),
  ]),
  /** OnChange handler, should expect a react synthetic event */
  onChange: PropTypes.func,
  /** Html placeholder */
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** Label text, if the input has a <label> associated */
  label: PropTypes.string,
  /** Label classes if a <label> exists */
  labelClassName: PropTypes.string,
  /** Prop to put input disabled */
  disabled: PropTypes.bool,
  hint: PropTypes.string,
  currency: PropTypes.string,
  inputProps: PropTypes.shape(),
  autoFocus: PropTypes.bool,
  showClassName: PropTypes.bool,
  tooltip: PropTypes.string,
  groupProps: PropTypes.shape({}),
  showEyeIcon: PropTypes.bool,
};

PasswordInput.defaultProps = {
  inputClassName: '',
  containerClassName: '',
  id: undefined,
  name: undefined,
  showEyeIcon: true,
  type: 'text',
  onChange: undefined,
  placeholder: undefined,
  label: undefined,
  labelClassName: undefined,
  value: '',
  disabled: false,
  hint: undefined,
  currency: '€',
  inputProps: {},
  autoFocus: false,
  showClassName: true,
  tooltip: undefined,
  groupProps: {},
};

export default PasswordInput;
