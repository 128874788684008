import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import i18n from '../../services/i18n';
import CountryProp from '../../commonComponents/forms/Address/CountryProp';
import organizationProp from '../../commonComponents/propTypes/organizationProp';
import TabCustomTrophies from '../../sections/settings/configurations/general/TabCustomTrophies';

const TabCustomTrophiesWebHandler = forwardRef((props, ref) => {
  const { propagateChanges, ...childProps } = props;
  const {
    t,
    organizationLanguages,
    organization,
    trophies: trophiesParam,
  } = props;
  const { defaultLanguage } = organization;
  const [trophies, setTrophies] = useState(trophiesParam || []);
  useEffect(() => propagateChanges(trophies), [trophies]);

  useImperativeHandle(ref, () => ({
    getValue: () => trophiesParam,
    setValue: trophy => setTrophies(trophy),
  }));

  return (
    <>
      <TabCustomTrophies
        {...childProps}
        // onChange={handleChanges}
        trophies={trophies}
        organizationLanguages={organizationLanguages}
        defaultLanguage={defaultLanguage}
        t={t}
      />
    </>
  );
});

TabCustomTrophiesWebHandler.propTypes = {
  t: PropTypes.func.isRequired,
  trophies: PropTypes.arrayOf(PropTypes.shape(CountryProp.propType)),
  propagateChanges: PropTypes.func.isRequired,
  organization: PropTypes.shape(organizationProp.propType).isRequired,
  error: PropTypes.shape({
    error: PropTypes.string,
    message: PropTypes.string,
  }),
  organizationLanguages: PropTypes.arrayOf(
    PropTypes.shape(CountryProp.propType),
  ).isRequired,
};

TabCustomTrophiesWebHandler.defaultProps = {
  error: null,
  trophies: [],
};

window.TabCustomTrophies = class TabCustomTrophiesRenderer {
  constructor(divId) {
    this.divId = divId;
    this.ref = null;
    this.listeners = {};
  }

  init(divId, props) {
    if (divId) {
      this.divId = divId;
    }

    const divEl = document.getElementById(divId);
    if (!divEl) {
      throw 'TabCustomTrophies container not found';
    }

    let finalProps = {};

    const propsData = divEl.getAttribute('data-props');
    if (propsData) {
      finalProps = JSON.parse(propsData);
    }

    const data = document.querySelector('script[data-react-props]');
    let globalProps = {};
    if (data) {
      globalProps = JSON.parse(data.getAttribute('data-react-props'));
    }

    if (props) {
      finalProps = { ...globalProps, ...finalProps, ...props };
    }

    ReactDOM.render(
      <TabCustomTrophiesWebHandler
        ref={c => {
          this.ref = c;
        }}
        t={i18n.__}
        propagateChanges={changes => this.emit('change', changes)}
        {...finalProps}
      />,
      divEl,
    );
    return this;
  }

  val(value) {
    if (value) {
      this.ref.setValue(value);
      return undefined;
    }

    return this.ref.getValue();
  }

  on(type, callback) {
    if (!this.listeners[type]) {
      this.listeners[type] = [];
    }
    this.listeners[type].push(callback);
  }

  emit(type, ...args) {
    if (this.listeners[type]) {
      for (let i = 0; i < this.listeners[type].length; i += 1) {
        if (this.listeners[type][i]) {
          this.listeners[type][i](...args);
        }
      }
    }
  }
};
