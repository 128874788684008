import React from 'react';
import PropTypes from 'prop-types';
import ReactTags from 'react-tagsinput';

export default function TagsInput(props) {
  const { id, name, placeholder, onChange, disabled, value, className, validationRegex, onValidationReject, keysToAdd } = props;

  return (
    <ReactTags
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      disabled={disabled}
      addKeys={keysToAdd}
      validationRegex={validationRegex}
      onValidationReject={onValidationReject}
      inputProps={{
        placeholder,
        className: `react-tagsinput-input ${className}`,
      }}
    />
  );
}

TagsInput.propTypes = {
  /** Selector id used by the jQuery libraries */
  id: PropTypes.string.isRequired,
  /** Selector html name, required for validation */
  name: PropTypes.string,
  /** Class names to be added to the default ones */
  className: PropTypes.string,
  /** initial value */
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  /** onChange handler */
  onChange: PropTypes.func.isRequired,
  /** Custom placeholder text */
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  validationRegex: PropTypes.instanceOf(RegExp),
  onValidationReject: PropTypes.func,
  /** Select custom keys to add tag, or use the default in prop type */
  keysToAdd: PropTypes.arrayOf(PropTypes.number),
};

TagsInput.defaultProps = {
  name: null,
  className: '',
  placeholder: undefined,
  disabled: false,
  validationRegex: /.*/,
  onValidationReject: null,
  keysToAdd: [9, 13, 188], // tab, enter, comma
};
