import React from 'react';
import PropTypes from 'prop-types';
import translate from '../../translate';

/**
 * Checkbox component wrapping the label -> input -> span pattern
 */
function ToggleSwitchWithLabel({
  t,
  id,
  name,
  label,
  value,
  onChange,
  labelClass,
  disabled,
  hint,
  wrapperClass,
  hintClass,
  inputClassWrapper,
}) {
  return (
    <div className={`toggle-wrapper-switchLabel ${wrapperClass}`}>
      {label && (
        <label
          htmlFor={`wrapperInputToggle-${id}`}
          className={`switch-label ${labelClass}`}
        >
          {label}
        </label>
      )}
      <div
        id={`wrapperInputToggle-${id}`}
        className={`toggle-switch-with-label ${inputClassWrapper}`}
      >
        <label className="switch" htmlFor={id}>
          <input
            name={name}
            id={id}
            type="checkbox"
            checked={value}
            onChange={event => {
              const isChecked =
                typeof event.target?.checked !== 'undefined'
                  ? event.target.checked
                  : event;
              if (onChange) {
                onChange(isChecked);
              }
            }}
            disabled={disabled}
          />
          <span className="slider">
            <span className="circle" />
            <span className="toggle-text-left">{t('yes')}</span>
            <span className="toggle-text-right">{t('no')}</span>
          </span>
        </label>
        {hint && <span className={`m-form__help ${hintClass}`}>{hint}</span>}
      </div>
    </div>
  );
}

ToggleSwitchWithLabel.propTypes = {
  t: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  labelClass: PropTypes.string,
  inputClassWrapper: PropTypes.string,
  disabled: PropTypes.bool,
  hint: PropTypes.string,
  wrapperClass: PropTypes.string,
  hintClass: PropTypes.string,
};

ToggleSwitchWithLabel.defaultProps = {
  label: null,
  labelClass: '',
  inputClassWrapper: '',
  disabled: false,
  hint: null,
  wrapperClass: '',
  hintClass: '',
};

export default translate(ToggleSwitchWithLabel);
