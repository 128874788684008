import React from 'react';
import PropTypes from 'prop-types';
import Select from '../../forms/inputs/Select';

/**
 * List SelectFilter, used to filter lists based on a list of options
 */
export default class SelectFilter extends React.Component {
  constructor(props) {
    super(props);
    if (props.multiple) {
      this.state = { value: props.initialValue || [] };
      this.handleChange = this.handleMultipleChange.bind(this);
      this.handleUnselect = this.handleUnselect.bind(this);
    } else {
      this.state = { value: props.initialValue || '' };
      this.handleChange = this.handleChange.bind(this);
    }
  }

  handleChange(value) {
    this.setState({ value }, () => this.props.onChange(value));
  }

  handleMultipleChange(value) {
    this.setState(
      old => ({
        value: old.value.includes(value)
          ? old.value.filter(v => v !== value)
          : old.value.concat(value),
      }),
      () => this.props.onChange(value),
    );
  }

  handleUnselect(value) {
    this.setState(
      old => ({
        value: old.value.filter(v => v !== value),
      }),
      () => this.props.onChange(value),
    );
  }

  render() {
    const {
      id,
      label,
      items,
      placeholder,
      allowClear,
      searchable,
      disabled,
      multiple,
      hint,
      wrapperClass,
    } = this.props;
    const { value } = this.state;

    return (
      <div className={`m-form__group m-form__group--inline ${wrapperClass}`}>
        {label && (
          <div className="m-form__label">
            <label htmlFor={id}>{label}</label>
          </div>
        )}
        <div className="m-form__control">
          <Select
            className="form-control"
            id={id}
            items={items}
            value={value}
            allowClear={allowClear}
            searchable={searchable}
            onChange={this.handleChange}
            onUnselect={multiple ? this.handleUnselect : undefined}
            placeholder={placeholder}
            multiple={multiple}
            disabled={disabled}
          />
          {hint && <span className="m-form__help">{hint}</span>}
        </div>
        <div className="d-md-none m--margin-bottom-10" />
      </div>
    );
  }
}

SelectFilter.propTypes = {
  /** Input html id */
  id: PropTypes.string,
  /** Label to placed behind the input */
  label: PropTypes.string,
  initialValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  /**
   * List of items to be used as the select data source,
   * the objects passed in should have id (used in selection) and text
   */ items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }),
  ).isRequired,
  /** onChange event handler, the selected item's id is passed as an argument */
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  allowClear: PropTypes.bool,
  searchable: PropTypes.bool,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  hint: PropTypes.string,
  wrapperClass: PropTypes.string,
};

SelectFilter.defaultProps = {
  id: 'selectFilter',
  initialValue: null,
  label: undefined,
  allowClear: false,
  placeholder: undefined,
  searchable: false,
  multiple: false,
  disabled: false,
  hint: null,
  wrapperClass: '',
};
