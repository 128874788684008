import React from 'react';

const i18n = { __: s => s };

if (typeof document !== 'undefined') {
  const data = document.querySelector('script[data-lang][data-i18n]');
  const lang = JSON.parse(data.getAttribute('data-i18n'));

  i18n.__ = (...args) => {
    const tag = args[0];
    let s = lang[tag];
    if (s) {
      for (let i = 0; i < args.length - 1; i += 1) {
        const param = `%${i + 1}\\$s`;
        s = s.replace(new RegExp(param, 'gm'), args[i + 1]);
      }
      return s;
    }
    return tag;
  };

  i18n.__n = (...args) => {
    const tag = args[0];
    let s = lang[tag];
    if (s) {
      for (let i = 0; i < args.length - 1; i += 1) {
        const param = /%[sdj]/g;
        s = s.replace(new RegExp(param, 'gm'), args[i + 1]);
      }
      return s;
    }
    return tag;
  };

  i18n.__WithReact = (tag, ...values) => {
    const template = i18n.__(tag); // Get the string template

    if (!template) return tag;

    const ReactElement = () => {
      const elements = [];
      let lastIndex = 0;

      // Regex to match the placeholders like %1$s, %2$s
      const regex = /%(\d+)\$s/g;

      template.replace(regex, (match, index) => {
        // Add text before the placeholder
        const start = template.indexOf(match);
        elements.push(template.substring(lastIndex, start));

        const value = values[index - 1]; // index is 1-based

        // Check if the value is a valid React child (string, number, or React element)
        if (
          React.isValidElement(value) ||
          typeof value === 'string' ||
          typeof value === 'number'
        ) {
          elements.push(value);
        } else {
          console.warn(
            `Invalid React child for placeholder %${index}$s:`,
            value,
          );
        }

        lastIndex = start + match.length;
      });

      // Add any remaining text after the last placeholder
      elements.push(template.substring(lastIndex));

      // Return the final React element
      return (
        <>
          {elements.map((el, i) =>
            React.isValidElement(el) ? (
              el
            ) : (
              <React.Fragment key={i}>{el}</React.Fragment>
            ),
          )}
        </>
      );
    };

    return <ReactElement />;
  };

  const t = (...args) => args[0];

  i18n.__l = t;
  i18n.__h = t;
  i18n.__mf = t;
}

export default i18n;
