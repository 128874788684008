import React, { useEffect, useRef, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import PropTypes from 'prop-types';
import Modal from '../../../modal/Modal';
import ModalHeader from '../../../modal/ModalHeader';
import List from '../../../lists/List';
import UserColumn from '../../../lists/columns/UserColumn';
import SelectFilter from '../../../lists/filters/SelectFilter';
import { throttle } from '../../../../services/utilityFunctions';
import CountryProp from '../../Address/CountryProp';
import HardSkillProp from '../../../propTypes/hardSkillProp';
import TextFilter from '../../../lists/filters/TextFilter';
import UserTypeNamesProp from '../../../propTypes/userTypeNamesProp';
import UserProp from '../../../propTypes/userProp';

export default function UserFilterModal(props) {
  const {
    t,
    url,
    languages,
    hardSkills,
    onClose,
    userTypeNames,
    onRowClick,
    multiple,
    selectedUsers,
  } = props;
  const optionAny = [{ id: '', text: t('any') }];
  const userTypesFilterList = optionAny.concat([
    { id: UserProp.ROLES.ADMIN, text: t('role_admin') },
    ...Object.keys(userTypeNames).map(userType => ({
      id: userType,
      text: userTypeNames[userType],
    })),
  ]);

  const tableId = 'userFilterTable';
  const tableEl = useRef(null);
  const selectedRef = useRef([]);
  const [selected, setSelected] = useState(selectedUsers || []);
  const [query, setQuery] = useState({
    search: '',
    role: '',
    type: '',
    language: '',
    hardSkillId: '',
  });

  useEffect(() => {
    selectedRef.current = selected;
  }, [selected]);

  const checkbox = multiple
    ? [
        {
          field: '_id',
          title: '#',
          sortable: false,
          width: 20,
          textAlign: 'center',
          selector: {
            class: 'm-checkbox--solid m-checkbox--brand',
          },
        },
      ]
    : [];
  const columns = [
    {
      field: 'name',
      title: t('name'),
      textAlign: 'left',
      template: user =>
        renderToStaticMarkup(
          <UserColumn t={t} user={user} includeWrapper={false} />,
        ),
    },
    ...checkbox,
  ];

  const handleQueryChange = field => value => {
    setSelected([]);
    setQuery(prev => ({ ...prev, [field]: value }));
  };

  const handleUserTypeChange = value => {
    setSelected([]);
    if (value === '') {
      setQuery(prev => ({ ...prev, type: value, role: value }));
    } else if (value === UserProp.ROLES.ADMIN) {
      setQuery(prev => ({ ...prev, type: '', role: value }));
    } else {
      setQuery(prev => ({ ...prev, type: value, role: UserProp.ROLES.USER }));
    }
  };

  const handleCancel = () => onClose(null);

  function handleListInitialization() {
    const $table = $(`#${tableId}`);
    $table.on('change', '.m-checkbox input', function handleChange() {
      const userId = $(this).val();
      const isChecked = $(this).prop('checked');
      if (userId === 'on' && isChecked) {
        const userIds = [];
        $(`#${tableId} .m-datatable__body .m-checkbox input`).each(
          function handleCheckbox() {
            userIds.push($(this).val());
          },
        );
        setSelected(prev => prev.concat(userIds));
      } else if (userId === 'on' && !isChecked) {
        const userIds = [];
        $(`#${tableId} .m-datatable__body .m-checkbox input`).each(
          function handleCheckbox() {
            userIds.push($(this).val());
          },
        );
        setSelected(prev => prev.filter(id => !userIds.includes(id)));
      } else if (isChecked) {
        setSelected(prev =>
          prev.includes(userId) ? prev : prev.concat(userId),
        );
      } else {
        setSelected(prev => prev.filter(id => id !== userId));
      }
    });
    $table.mDatatable().on('m-datatable--on-layout-updated', () => {
      $(`#${tableId} .m-datatable__body .m-checkbox input`).each(
        function handleCheckbox() {
          const userId = $(this).val();
          if (selectedRef.current.includes(userId)) {
            $(this).prop('checked', true);
          }
        },
      );
    });
  }

  return (
    <Modal onClose={handleCancel} id="userSelectorModal">
      <div className="modal-dialog modal-lg">
        <div className="modal-content">
          <ModalHeader title={t('userFilterModal_title')} />
          <div className="modal-body">
            <div className="userSelectorFilters">
              <div className="userSelectorFilters-top">
                <TextFilter
                  onChange={throttle(handleQueryChange('search'), 300)}
                  id="nameFilter"
                  placeholder={t('user_selector_placeholder')}
                  wrapperClass="userFilter"
                />
                <SelectFilter
                  id="roleFilter"
                  items={userTypesFilterList}
                  onChange={handleUserTypeChange}
                  placeholder={t('role_type')}
                  wrapperClass="roleFilter"
                  allowClear
                />
              </div>
              <div className="userSelectorFilters-bottom">
                <SelectFilter
                  id="languageFilter"
                  items={optionAny.concat(
                    languages.map(l => ({
                      id: l.code,
                      text: l.native,
                    })),
                  )}
                  onChange={handleQueryChange('language')}
                  placeholder={t('language')}
                  wrapperClass="languageFilter"
                  allowClear
                />
                <SelectFilter
                  id="hardSkillFilter"
                  items={optionAny.concat(
                    hardSkills.map(hs => ({
                      id: hs._id,
                      text: hs.name,
                    })),
                  )}
                  onChange={handleQueryChange('hardSkillId')}
                  placeholder={t('hard_skill')}
                  wrapperClass="hardSkillFilter"
                  allowClear
                />
                <div className="filterPlaceholder" />
              </div>
            </div>
            <List
              onRowClick={onRowClick}
              ref={tableEl}
              columns={columns}
              id={tableId}
              dataSourceURL={url}
              defaultPageSize={7}
              query={query}
              onInit={handleListInitialization}
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleCancel}
            >
              {t('cancel')}
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={() => onClose(selected)}
            >
              {t('confirm')}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

UserFilterModal.propTypes = {
  t: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  languages: PropTypes.arrayOf(PropTypes.shape(CountryProp.propType))
    .isRequired,
  hardSkills: PropTypes.arrayOf(PropTypes.shape(HardSkillProp.propType))
    .isRequired,
  onClose: PropTypes.func.isRequired,
  userTypeNames: PropTypes.shape(UserTypeNamesProp.propType),
  onRowClick: PropTypes.func,
  multiple: PropTypes.bool,
  selectedUsers: PropTypes.arrayOf(PropTypes.string),
};

UserFilterModal.defaultProps = {
  onRowClick: null,
  multiple: true,
  selectedUsers: [],
  userTypeNames: null,
};
