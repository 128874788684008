import PropTypes from 'prop-types';

const propType = {
  _id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.bool,
  level: PropTypes.number,
  skillLevel: PropTypes.shape({
    name: PropTypes.string,
    icon: PropTypes.string,
  }),
};

export default { propType };
