import PropTypes from 'prop-types';

const propType = {
  collaborator: PropTypes.string,
  customer: PropTypes.string,
  lead: PropTypes.string,
  recommendation: PropTypes.string,
  visitor: PropTypes.string,
};

const defaultProp = {
  collaborator: '',
  customer: '',
  lead: '',
  recommendation: '',
  visitor: '',
};

export default { propType, defaultProp };
