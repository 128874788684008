import PropTypes from 'prop-types';

const propType = {
  city: PropTypes.string,
  country: PropTypes.string,
  countryCode: PropTypes.string,
  address_1: PropTypes.string,
  address_2: PropTypes.string,
  zipCode: PropTypes.string,
  state: PropTypes.string,
};

const defaultProp = {
  city: '',
  country: '',
  countryCode: '',
  address_1: '',
  address_2: '',
  zipCode: '',
  state: '',
};

const requiredFields = {
  city: true,
  country: true,
  address_1: true,
  address_2: false,
  zipCode: true,
  state: true,
};

export default { propType, defaultProp, requiredFields };
