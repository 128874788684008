import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom/server';
import PropTypes from 'prop-types';
import withCSRF from '../../withCSRF';
import Notifications from '../../../services/notifications';
import i18n from '../../../services/i18n';
import DocumentCard from '../../cards/DocumentCardItem';

/**
 * DropZone item template to render as static markup for the main DropZone component
 */
function Template({ isImage, isCustomTemplate }) {
  return (
    <div
      className="dropzone-template-container"
      style={isCustomTemplate ? { display: 'none' } : {}}
    >
      <div className="dz-preview dz-complete dz-image-preview">
        {isImage && (
          <div className="dz-image">
            <img alt="file thumbnail" data-dz-thumbnail="" />
          </div>
        )}
        <div className="dz-details">
          <div className="dz-size">
            <span data-dz-size="" />
          </div>
          <a className="dz-filename" target="_blank" download>
            <span data-dz-name="" />
          </a>
        </div>
        <div className="dz-progress">
          <span className="dz-upload" data-dz-uploadprogress="" />
        </div>
        <div className="dz-error-message">
          <span data-dz-errormessage="" />
        </div>
      </div>
    </div>
  );
}

Template.propTypes = {
  isImage: PropTypes.bool,
  isCustomTemplate: PropTypes.bool,
};

Template.defaultProps = {
  isImage: true,
  isCustomTemplate: true,
};

/**
 * DropZone component to be used as a file picker/drag'n'drop in forms
 */
function DropZone({
  id,
  _csrf,
  name,
  value,
  title,
  description,
  maxFiles,
  required,
  isImage,
  type,
  onUploadProgress,
  disabled,
  acceptedFiles,
  isCustomTemplate,
  onCompleted,
  onRemoveFile,
  onChange,
}) {
  const [files, setFiles] = useState(JSON.parse(value)?.filter(v => v));

  const dropZoneRef = useRef(null);

  const showSuccess = () => {
    $(`#${id}`)
      .closest('.form-group')
      .removeClass('has-danger')
      .addClass('has-success');
  };

  const showError = () => {
    $(`#${id}`)
      .closest('.form-group')
      .removeClass('has-success')
      .addClass('has-danger');
  };

  const handleFilesChange = newFiles => {
    if (newFiles.length === maxFiles) {
      $(`#${id} .dz-hidden-input`).prop('disabled', true);
    } else {
      $(`#${id} .dz-hidden-input`).prop('disabled', false);
    }

    if (required) {
      if (newFiles.length === 0) {
        showError();
      } else {
        showSuccess();
      }
    }

    onChange(newFiles);
  };

  const completeHandler = file => {
    try {
      if (file && file.xhr && file.xhr.status !== 200) {
        if (file.xhr.status === 413) {
          Notifications.showNotificationError(
            i18n.__('error'),
            i18n.__('413error-too-large'),
          );
        }
        onCompleted(false);
      } else {
        const url = file.xhr ? JSON.parse(file.xhr.response).data : file.url;
        onCompleted(false);
        $('.dz-filename', file.previewElement).attr('href', url);
      }
    } catch (err) {
      Notifications.showNotificationError(i18n.__('error'), err);
      console.log('*ERR DropZone-completeHandler', err);
      onCompleted(false);
    }
  };

  // const fileAddedHandler = file => {
  //   dropZoneRef.current.emit('thumbnail', file, file.thumb);
  //   $(file.previewElement)
  //     .find('.dz-filename')
  //     .attr({ href: file.url, download: file.name });
  //   onCompleted(true);
  // };

  const fileRemovedHandler = file => {
    let fileURL = file.url;
    if (file && file.xhr && file.xhr.response) {
      const response = JSON.parse(file.xhr.response);
      fileURL = response.data?.url ? response.data.url : response.data;
    }
    setFiles(prevFiles => {
      if (type === 'video') {
        return prevFiles.filter(f => f.url.url !== fileURL);
      }
      handleFilesChange(prevFiles.filter(f => f.url !== fileURL));
      return prevFiles.filter(f => f.url !== fileURL);
    });
    $(file.previewElement).remove();
  };

  const successHandler = (file, result) => {
    const uploaded = {
      url: result.data,
      name:
        type !== 'video'
          ? result.data.substring(result.data.lastIndexOf('/') + 1)
          : file.name,
      originalName: file.name,
      ext: file.name.split('.').pop(),
      size: file.size,
    };
    setFiles(prevFiles => {
      handleFilesChange(prevFiles.concat(uploaded));
      return prevFiles.concat(uploaded);
    });
  };

  useEffect(() => {
    let url = isImage ? '/upload/image' : '/upload/file';
    let paramName = 'file';
    if (type === 'video') {
      url = '/upload/video';
      paramName = 'video';
    }

    $(`#${id}`).dropzone({
      url,
      paramName,
      maxFiles,
      createImageThumbnails: true,
      addRemoveLinks: true,
      headers: {
        'X-CSRF-Token': _csrf,
        _csrf,
      },
      timeout: 900000,
      acceptedFiles: acceptedFiles || null,
      previewTemplate: ReactDOM.renderToStaticMarkup(
        <Template isImage={isImage} isCustomTemplate={isCustomTemplate} />,
      ),
      accept: (file, done) => done(),
      success: successHandler,
      error: (file, error) => {
        console.log('*Err, error method', error);
        $(file.previewElement)
          .find('.dz-error-message')
          .text('File upload failed');
      },
      // addedfile: this.fileAddedHandler,
      removedfile: fileRemovedHandler,
      complete: completeHandler,
      thumbnail: (file, thumbParam) => {
        file.previewElement.querySelector('img').src = thumbParam || file.thumb;
        file.previewElement.querySelector('img').setAttribute('height', 120);
      },
      uploadprogress: (file, progress) => {
        onUploadProgress(progress);
      },
      clickable: !disabled,
      hiddenInputContainer: `#${id}`,
    });

    dropZoneRef.current = $(`#${id}`)[0].dropzone;

    if (value) {
      const filesArray = JSON.parse(value)?.filter(v => v);
      filesArray.forEach(file => {
        const fileNameParts = file.url.split('/');
        const fileName = fileNameParts[fileNameParts.length - 1];

        const mockFile = {
          name: file.originalName || fileName,
          size: file.size || 0,
          thumb: file.thumb,
          url: file.url,
          medium: file.medium || '',
        };
        dropZoneRef.current.emit('addedfile', mockFile);
        if (mockFile.thumb) {
          dropZoneRef.current.emit('thumbnail', mockFile);
        }
        dropZoneRef.current.emit('complete', mockFile);
      });
    }
  }, []);

  return (
    <div className="m-dropzone m-dropzone--secundary" id={id}>
      <div className="m-dropzone__msg dz-message needsclick">
        {title && <h3 className="m-dropzone__msg-title">{title}</h3>}
        <span className="m-dropzone__msg-desc">{description}</span>
      </div>
      {isCustomTemplate && files?.length > 0
        ? files.map(file => (
            <DocumentCard
              key={file?.name}
              file={file}
              onRemoveFile={
                onRemoveFile
                  ? async () => {
                      await fileRemovedHandler(file);
                      await onRemoveFile(file);
                      await dropZoneRef.current.removeAllFiles();
                    }
                  : null
              }
              id={file?.size}
              classWrapper={file?.name?.split('.')[0]}
              removeColor="#FFFFFF"
            />
          ))
        : null}
      <input
        name={name}
        type="hidden"
        value={files.length > 0 ? JSON.stringify(files) : ''}
        readOnly
      />
    </div>
  );
}

DropZone.propTypes = {
  id: PropTypes.string.isRequired,
  _csrf: PropTypes.string.isRequired,
  name: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  description: PropTypes.string.isRequired,
  maxFiles: PropTypes.number,
  required: PropTypes.bool,
  isImage: PropTypes.bool,
  type: PropTypes.string,
  onUploadProgress: PropTypes.func,
  disabled: PropTypes.bool,
  onCompleted: PropTypes.func,
  acceptedFiles: PropTypes.string,
  isCustomTemplate: PropTypes.bool,
  onRemoveFile: PropTypes.func,
};

DropZone.defaultProps = {
  name: null,
  maxFiles: 1,
  required: false,
  isImage: true,
  type: '',
  disabled: false,
  onUploadProgress: () => {},
  onCompleted: () => {},
  acceptedFiles: null,
  isCustomTemplate: null,
  onRemoveFile: null,
};

export default withCSRF(DropZone);
