/* eslint-disable */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import MetaContext from '../../contextProviders/MetaContext';

function DefaultLayerIcon({ color, icon, ext, fontSize, iconClass }) {
  const { branding = {} } = useContext(MetaContext);
  const { primaryColor: brandColor } = branding || {};
  const primaryColor = color || brandColor || '#7ED321';
  return (
    <div className="file-icon">
      <svg
        width="44"
        height="59"
        viewBox="0 0 44 59"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="file-template"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.2 17.17V0H7.63C3.41 0 0 3.41 0 7.63V50.96C0 55.18 3.41 58.59 7.63 58.59H36.2C40.4 58.59 43.81 55.18 43.81 50.96V17.17"
          fill={primaryColor}
        />
        <path
          opacity="0.5"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.2 0V17.17H43.81L27.2 0Z"
          fill={primaryColor}
        />
      </svg>
      {!icon && (
        <p className={`file-ext ${iconClass}`} style={{ fontSize: fontSize }}>
          {ext}
        </p>
      )}
      {icon === 'image' && (
        <svg
          width="19"
          height="14"
          viewBox="0 0 19 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="image"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.9187 0H2.03127C0.916594 0 0 0.91652 0 2.03127V11.4938C0 12.6085 0.91652 13.5251 2.03127 13.5251H16.9187C18.0333 13.5251 18.9251 12.6085 18.9251 11.4938V2.03119C18.9251 0.91652 18.0334 0 16.9187 0ZM6.09372 2.5514C7.3075 2.5514 8.27354 3.54224 8.27354 4.73123C8.27354 5.94501 7.3075 6.93585 6.09372 6.93585C4.87993 6.93585 3.8891 5.94501 3.8891 4.73123C3.8891 3.54224 4.87993 2.5514 6.09372 2.5514ZM17.5875 11.4938C17.5875 11.8654 17.2902 12.1874 16.9187 12.1874H2.03127C1.65968 12.1874 1.36242 11.8654 1.36242 11.4938V11.0975L4.06246 8.39745L6.29187 10.6269C6.56434 10.8993 6.98544 10.8993 7.25791 10.6269L12.8562 5.02857L17.5875 9.75987V11.4938Z"
            fill="white"
          />
        </svg>
      )}
      {icon === 'media' && (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="media"
        >
          <path
            d="M9.91238 0.582031C4.68679 0.582031 0.441895 4.82693 0.441895 10.0525C0.441895 15.2781 4.68679 19.5083 9.91238 19.5083C15.138 19.5083 19.3682 15.278 19.3682 10.0524C19.3682 4.82685 15.1233 0.596612 9.91238 0.582031ZM13.8938 10.3452C13.8206 10.477 13.7181 10.5794 13.5864 10.6526L8.18517 13.3605C7.84848 13.5215 7.43866 13.3898 7.27768 13.0531C7.21913 12.9653 7.20447 12.8629 7.20447 12.7458V7.34454C7.20447 6.96395 7.51186 6.67123 7.87779 6.67123C7.98022 6.67123 8.08273 6.68588 8.18517 6.74443L13.5864 9.43775C13.9231 9.61331 14.0548 10.0086 13.8938 10.3452Z"
            fill="white"
          />
        </svg>
      )}
    </div>
  );
}

DefaultLayerIcon.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  ext: PropTypes.string,
  fontSize: PropTypes.string,
  iconClass: PropTypes.string,
};

DefaultLayerIcon.defaultProps = {
  color: null,
  icon: null,
  ext: null,
  fontSize: '12px',
  iconClass: '',
};

const memoized = React.memo(DefaultLayerIcon);
memoized.displayName = 'DefaultLayerIcon';
export default memoized;
