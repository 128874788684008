import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import TrophyProp, {
  TROPHY_FIELDS,
} from '../../../journey/propTypes/trophyProp';
import TrophyCard from './components/TrophyCard';
import CountryProp from '../../../../commonComponents/forms/Address/CountryProp';
import { uuid } from '../../../../services/utilityFunctions';

function hasFieldNotValid(obj) {
  for (const key in obj) {
    if (typeof obj[key] === 'object') {
      if (hasFieldNotValid(obj[key])) {
        return true;
      }
    } else if (obj[key] === false) {
      return true;
    }
  }
  return false;
}

function TabCustomTrophies(props) {
  const {
    t,
    trophies: savedTrophies,
    defaultLanguage,
    organizationLanguages,
    _csrf,
  } = props;

  const [trophies, setTrophies] = useState(savedTrophies);
  const [formValidation, setFormValidation] = useState({});

  const handleAddTrophies = useCallback(() => {
    setTrophies(prevTrophies => [
      ...prevTrophies,
      {
        _id: uuid(),
        identifier: '',
        name: '',
        translations: {},
        icon: null,
        usersCanShare: false,
      },
    ]);
  }, [setTrophies]);

  const handleChange = useCallback(
    (_id, field, value, languageCode) => {
      setTrophies(prevTrophies =>
        prevTrophies.map(trophy => {
          if (trophy._id === _id) {
            switch (field) {
              case TROPHY_FIELDS.TRANSLATIONS:
                return {
                  ...trophy,
                  name:
                    languageCode?.toLowerCase() ===
                    defaultLanguage?.toLowerCase()
                      ? value
                      : trophy.name,
                  translations: {
                    ...trophy.translations,
                    [languageCode]: value,
                  },
                };
              case TROPHY_FIELDS.NAME:
              case TROPHY_FIELDS.IDENTIFIER:
              case TROPHY_FIELDS.ICON:
              case TROPHY_FIELDS.USERS_CAN_SHARE:
              default:
                return { ...trophy, [field]: value };
            }
          }
          return trophy;
        }),
      );
    },
    [setTrophies, defaultLanguage],
  );

  const handleRemoveTrophy = useCallback(
    trophyId => {
      setTrophies(prevTrophies =>
        prevTrophies.filter(trophy => trophy._id !== trophyId),
      );
    },
    [setTrophies],
  );

  const validationForm = useCallback(
    trophiesToBeSaved => {
      const formValid = {};

      trophiesToBeSaved.forEach(trophyObj => {
        const nameIsValid = trophyObj?.name?.length > 0;
        const translationsErrors = {};
        organizationLanguages.forEach(lang => {
          const language = lang?.code?.toLowerCase();
          translationsErrors[language] =
            trophyObj.translations[language]?.length > 0;
        });

        const iconIsValid =
          trophyObj?.icon?.thumb?.length > 0 &&
          trophyObj?.icon?.url?.length > 0 &&
          trophyObj?.icon?.medium?.length > 0;

        formValid[trophyObj._id] = {
          name: nameIsValid,
          icon: iconIsValid,
          translations: translationsErrors,
        };
      });

      setFormValidation(formValid);
      return formValid;
    },
    [trophies],
  );
  const handleSaveChanges = useCallback(() => {
    const validation = validationForm(trophies);
    const fieldNotValid = hasFieldNotValid(validation);
    if (fieldNotValid) {
      return;
    }
    $('#trophies').val(JSON.stringify(trophies));
    $('#editOrganizationButton').click();
  }, [trophies, validationForm]);

  return (
    <div id="tabCustomTrophies">
      <h4 className="title">{t('trophies')}</h4>
      <div>
        {trophies?.map((trophy, index) => (
          <TrophyCard
            t={t}
            trophy={trophy}
            key={trophy._id}
            number={index + 1}
            organizationLanguages={organizationLanguages}
            defaultLanguage={defaultLanguage}
            onChange={handleChange}
            _csrf={_csrf}
            onRemoveTrophy={handleRemoveTrophy}
            formValidation={formValidation[trophy?._id]}
          />
        ))}
        <button
          className="btn btn-success mt-repeater-add add-trophy-btn"
          type="button"
          onClick={handleAddTrophies}
        >
          <i className="fa fa-plus" />
          <span>{t('add_trophy')}</span>
        </button>
      </div>
      <div className="save-wrapper">
        <button className="btn green" type="button" onClick={handleSaveChanges}>
          <span>{t('save_changes')}</span>
        </button>
      </div>
    </div>
  );
}

TabCustomTrophies.propTypes = {
  t: PropTypes.func.isRequired,
  trophies: PropTypes.arrayOf(PropTypes.shape(TrophyProp.propType)).isRequired,
  _csrf: PropTypes.string.isRequired,
  defaultLanguage: PropTypes.string.isRequired,
  organizationLanguages: PropTypes.arrayOf(CountryProp.propType).isRequired,
};

export default TabCustomTrophies;
