import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactCrop from 'react-image-crop';
import translate from '../translate';
import ModalHeader from '../modal/ModalHeader';
import Modal from '../modal/Modal';

function getCroppedImg(image, crop, fileType) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  // Calculate crop dimensions
  const cropWidth = (crop.width * image.naturalWidth) / 100;
  const cropHeight = (crop.height * image.naturalHeight) / 100;
  const cropX = (crop.x * image.naturalWidth) / 100;
  const cropY = (crop.y * image.naturalHeight) / 100;

  // Set canvas dimensions to match crop dimensions
  canvas.width = cropWidth;
  canvas.height = cropHeight;

  // Draw the cropped image onto the canvas
  ctx.drawImage(
    image,
    cropX,
    cropY,
    cropWidth,
    cropHeight,
    0,
    0,
    canvas.width,
    canvas.height,
  );

  return new Promise(resolve => canvas.toBlob(resolve, fileType, 1.0));
}

function ImageCropModal(props) {
  const {
    t,
    title,
    originalFile,
    fileType,
    onFinished,
    cropLocked,
    cropAspect,
    cropHeight,
    cropWidth,
  } = props;

  const [cropState, setCropState] = useState({});

  const onImageLoaded = img => {
    const aspect =
      cropHeight && cropWidth ? cropWidth / cropHeight : cropAspect;
    const width =
      img.width / aspect < img.height * aspect
        ? 100
        : ((img.height * aspect) / img.width) * 100;
    const height =
      img.width / aspect > img.height * aspect
        ? 100
        : (img.width / aspect / img.height) * 100;
    const y = (100 - height) / 2;
    const x = (100 - width) / 2;

    setCropState({
      unit: '%',
      width,
      height,
      x,
      y,
      aspect,
      cropHeight,
      cropWidth,
    });

    return false;
  };

  const onCropChange = (crop, cropPercent) => {
    setCropState({
      ...cropPercent,
      cropHeight,
      cropWidth,
    });
  };

  const handleSave = async () => {
    const croppedImage = await getCroppedImg(
      document.querySelector('.ReactCrop__image'),
      {
        ...cropState,
        cropWidth,
        cropHeight,
      },
      fileType,
    );
    onFinished(croppedImage);
  };

  const handleClose = () => onFinished(null);

  return (
    <Modal onClose={handleClose}>
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div
          className="modal-content"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
          }}
        >
          <ModalHeader title={title} onClose={handleClose} />
          <div className="modal-body">
            <div className="cropper-container">
              <ReactCrop
                src={originalFile}
                crop={cropState}
                locked={cropLocked}
                onChange={onCropChange}
                onImageLoaded={onImageLoaded}
                keepSelection
              />
            </div>
            <div className="m-portlet__foot" style={{ marginTop: 'auto' }}>
              <button
                type="button"
                className="btn btn-accent m-btn m-btn--air m-btn--custom loader-button"
                onClick={handleSave}
              >
                {t('save')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

ImageCropModal.propTypes = {
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  originalFile: PropTypes.string.isRequired,
  fileType: PropTypes.string.isRequired,
  onFinished: PropTypes.func.isRequired,
  cropLocked: PropTypes.bool,
  cropAspect: PropTypes.number,
  cropWidth: PropTypes.number,
  cropHeight: PropTypes.number,
};

ImageCropModal.defaultProps = {
  cropLocked: false,
  cropAspect: 1,
  cropWidth: null,
  cropHeight: null,
};

export default translate(ImageCropModal);
