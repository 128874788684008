import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Modal from '../../../modal/Modal';
import FormInput from '../../FormInput';
import translate from '../../../translate';
import ModalHeader from '../../../modal/ModalHeader';
import Form from '../../Form';
import WorldContext from '../../../../contextProviders/WorldContext';
import FormValidation from '../../../../services/formValidation';
import ConfigsContext from '../../../../contextProviders/ConfigsContext';
import Notifications from '../../../../services/notifications';
import {
  compose,
  isBusinessTypeESAMAllowed,
} from '../../../../services/utilityFunctions';
import withCSRF from '../../../withCSRF';

function AddNewUserModal(props) {
  const { t, onClose, _csrf } = props;
  const { currencies, countries, vatTypes } = useContext(WorldContext);
  const { organization } = useContext(ConfigsContext);
  const { defaultLanguage, businessCurrency, organizationLanguages } =
    organization || {};
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({
    firstName: null,
    lastName: null,
    businessType: 'individual',
    email: null,
    phone: null,
    company: null,
    taxCode: null,
    taxType: null,
    NIF: null,
    address_1: null,
    address_2: null,
    zipCode: null,
    city: null,
    state: null,
    country: null,
    currency: businessCurrency,
    language: defaultLanguage,
  });
  const [placeholder, setPlaceholder] = useState('');
  const [businessTypes, setBusinessTypes] = useState([]);
  const [isBusinessTypesLoaded, setIsBusinessTypesLoaded] = useState(false);

  const getBusinessTypes = useCallback(async () => {
    try {
      const { data: response } = await axios.get(
        '/api/organization/business/types/list/available',
        {
          headers: {
            'Accept-Version': '1.0.0',
            'X-Gamification-API': 'Organization',
          },
        },
      );
      const responseTypes = response.data
        ?.reduce((acc, current) => {
          if (!acc.some(item => item.code === current.code)) {
            acc.push(current);
          }
          return acc;
        }, [])
        .map(c => ({ id: c.code, text: c.name }));

      responseTypes.unshift({
        id: 'individual',
        text: t('businessType.individual'),
      });

      setBusinessTypes(responseTypes);
      setIsBusinessTypesLoaded(true);
    } catch (err) {
      Notifications.showErrorFromRequest(err, 'error.get.businessTypes');
    }
  }, []);

  useEffect(() => {
    getBusinessTypes();
  }, [getBusinessTypes]);

  const {
    firstName,
    lastName,
    taxCode,
    company,
    email,
    phone,
    address_1,
    address_2,
    zipCode,
    city,
    state,
    countryCode,
    language,
    currency,
    businessType,
    NIF,
  } = form || {};
  const isBusinessIndividual = !businessType || businessType === 'individual';

  // region Handle state management
  const handleChange = useCallback(
    field => value => {
      switch (field) {
        case 'countryCode':
          setForm(prevForm => ({
            ...prevForm,
            [field]: value,
            country: countries.find(c => c.code === value)?.name,
          }));
          break;
        case 'businessType':
          if (field === 'businessType' && value === 'ES_AM') {
            setTimeout(() => {
              setForm(prevForm => {
                if (!isBusinessTypeESAMAllowed(prevForm?.countryCode)) {
                  Notifications.showNotificationError(
                    t('error'),
                    t('error.select.businessType.es_am'),
                  );
                  $('#businessType').val('').trigger('change');
                }
                return {
                  ...prevForm,
                  businessType: null,
                };
              });
            }, 300);
          } else {
            setForm(prevForm => ({
              ...prevForm,
              businessType: value,
            }));
          }
          break;
        default:
          setForm(prevForm => ({
            ...prevForm,
            [field]: value,
          }));
          break;
      }
    },
    [setForm, form.countryCode],
  );

  const handleAddressSelection = useCallback(
    value => {
      setForm(prevForm => ({
        ...prevForm,
        ...value,
      }));
    },
    [setForm],
  );

  const handleTaxChange = useCallback(
    value => {
      const taxSelected = vatTypes.find(tax => tax.taxCode === value);
      if (taxSelected) {
        setPlaceholder(taxSelected.placeholder);
        setForm(prevForm => ({
          ...prevForm,
          taxType: taxSelected.taxType,
          taxCode: taxSelected.taxCode,
        }));
      }
    },
    [setForm, setPlaceholder, vatTypes],
  );
  // endregion

  const handleClose = useCallback(() => {
    onClose(null);
  }, [onClose]);

  const handleSubmit = useCallback(async () => {
    try {
      setIsLoading(true);
      if (
        form?.businessType === 'ES_AM' &&
        !isBusinessTypeESAMAllowed(form?.countryCode)
      ) {
        Notifications.showNotificationError(
          t('error'),
          t('error.select.businessType.es_am'),
        );
        return;
      }
      const url = '/api/user/restricted?sendEmail=false';
      const params = {
        username: `${form.firstName} ${form.lastName}`,
        firstName: form.firstName,
        lastName: form.lastName,
        company: isBusinessIndividual ? '' : form.company,
        email: form.email,
        phone: form.phone,
        address: {
          address: form.address,
          address_1: form.address_1,
          address_2: form.address_2,
          zipCode: form.zipCode,
          city: form.city,
          state: form.state,
          country: form.country,
          countryCode: form.countryCode,
          location: form.location,
        },
        personalInfo: isBusinessIndividual
          ? null
          : {
              companyName: isBusinessIndividual ? '' : form.company,
              NIF: form.NIF,
              taxCode: form.taxCode,
              taxType: form.taxType,
              businessType: form.businessType,
            },
        shippingCountry: form.countryCode,
        currency: form.currency,
        language: form.language,
      };

      const { data: response } = await axios.post(url, params, {
        headers: {
          'Accept-Version': '1.0.0',
          'X-Gamification-API': 'Organization',
          'X-CSRF-Token': _csrf,
          _csrf,
        },
      });

      onClose(response.data);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      Notifications.showErrorFromRequest(error);
      setIsLoading(false);
    }
  }, [form, onClose, setIsLoading]);

  const validationRules = {
    firstName: { required: true, minlength: 3, maxlength: 20 },
    lastName: { required: true, minlength: 3, maxlength: 20 },
    email: { required: true, email: true },
    address_1: { required: true, minlength: 4, maxlength: 35 },
    phone: { required: true, maxlength: 20 },
    zipCode: { required: true, maxlength: 10 },
    city: { required: true, maxlength: 20 },
    state: { maxlength: 20 },
    countryCode: { required: true },
    language: { required: true },
    currency: { required: true },
    NIF: {
      required: () => $('#businessType').val() !== 'individual',
    },
    taxCode: {
      required: () => $('#businessType').val() !== 'individual',
    },
    taxType: {
      required: () => $('#businessType').val() !== 'individual',
    },
  };

  const inputProps = useCallback(
    field => {
      switch (field) {
        case 'businessType':
          return {
            items: businessTypes,
            searchable: false,
            dropdownParent: '#userSelectorAddUser',
          };
        case 'taxType':
          return {
            placeholder: t('profile_taxType'),
            items: vatTypes.map(c => ({
              id: c.taxCode,
              text:
                c.taxType === 'eu_vat'
                  ? `${c.flagEmoji} ${c.countryName} VAT number`
                  : `${c.flagEmoji} ${c.name}`,
            })),
            dropdownParent: '#userSelectorAddUser',
          };
        case 'country':
          return {
            searchable: true,
            items: countries.map(c => ({
              id: c.code,
              text: `${c.flagEmoji} ${c.name}`,
            })),
            dropdownParent: '#userSelectorAddUser',
          };
        case 'language':
          return {
            searchable: true,
            items: organizationLanguages.map(c => ({
              id: c.code,
              text: c.name,
            })),
            dropdownParent: '#userSelectorAddUser',
          };
        case 'currency':
          return {
            searchable: true,
            items: currencies.map(c => ({
              id: c.code,
              text: `${c.name} ${c.symbol}`,
            })),
            dropdownParent: '#userSelectorAddUser',
          };
        default:
          return {};
      }
    },
    [currencies, countries, vatTypes, organizationLanguages, businessTypes],
  );

  const handleInvalidForm = useCallback(() => {
    const selectElement = document.querySelector('.select2');
    const typeErrorElement = document.getElementById('type-error');

    if (typeErrorElement) {
      selectElement.parentNode.insertBefore(
        typeErrorElement,
        selectElement.nextSibling,
      );
    }
  }, []);

  useEffect(() => {
    FormValidation.config();
    FormValidation.init('#addNewUserForm', {
      rules: validationRules,
    });
  }, []);

  return (
    <Modal id="userSelectorAddUser" onClose={handleClose}>
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content">
          <ModalHeader title={t('add.new.user')} />
          <div className="modal-body">
            <Form
              id="addNewUserForm"
              submitMethod={handleSubmit}
              validationRules={validationRules}
              onInvalidForm={handleInvalidForm}
            >
              {submitHandler => (
                <>
                  <FormInput
                    id="firstName"
                    name="firstName"
                    value={firstName}
                    onChange={handleChange('firstName')}
                    label={t('profile_firstName')}
                    placeholder={t('profile_firstName')}
                    groupProps={{ className: 'row' }}
                    labelClassName="col-2 text-right"
                    containerClassName="col-10"
                  />
                  <FormInput
                    id="lastName"
                    name="lastName"
                    value={lastName}
                    onChange={handleChange('lastName')}
                    label={t('profile_lastName')}
                    placeholder={t('profile_lastName')}
                    groupProps={{ className: 'row' }}
                    labelClassName="col-2 text-right"
                    containerClassName="col-10"
                  />
                  <FormInput
                    id="email"
                    name="email"
                    value={email}
                    onChange={handleChange('email')}
                    label={t('email')}
                    placeholder={t('email')}
                    groupProps={{ className: 'row' }}
                    labelClassName="col-2 text-right"
                    containerClassName="col-10"
                  />
                  {isBusinessTypesLoaded ? (
                    <FormInput
                      id="businessType"
                      name="businessType"
                      value={businessType}
                      onChange={handleChange('businessType')}
                      label={t('profile_businessType')}
                      placeholder={t('profile_businessType')}
                      groupProps={{ className: 'row' }}
                      labelClassName="col-2 text-right"
                      containerClassName="col-10"
                      inputProps={inputProps('businessType')}
                      type="select2"
                    />
                  ) : (
                    <div className="form-group m-form__group  row ">
                      <label
                        htmlFor="businessTypePlacheholder"
                        className="col-form-label col-lg-2 col-sm-12"
                      >
                        {t('profile_businessType')}
                      </label>
                      <div className="businessTypeWrapperContainer businessTypeWrapperContainerPlaceholder col-7">
                        {`${t('loading.businessTypes')} ...`}
                      </div>
                    </div>
                  )}
                  {!isBusinessIndividual ? (
                    <>
                      <FormInput
                        id="company"
                        name="company"
                        value={company}
                        onChange={handleChange('company')}
                        label={t('company')}
                        placeholder={t('company')}
                        groupProps={{ className: 'row' }}
                        labelClassName="col-2 text-right"
                        containerClassName="col-10"
                      />
                      <FormInput
                        id="taxType"
                        name="taxType"
                        value={taxCode}
                        onChange={handleTaxChange}
                        label={t('profile_taxType')}
                        placeholder={
                          placeholder?.length === 0
                            ? t('profile_taxType')
                            : placeholder
                        }
                        groupProps={{ className: 'row' }}
                        labelClassName="col-2 text-right"
                        containerClassName="col-10"
                        type="select2"
                        inputProps={inputProps('taxType')}
                      />
                      <FormInput
                        name="NIF"
                        value={NIF}
                        onChange={handleChange('NIF')}
                        label={t('profile_taxCode')}
                        placeholder={
                          placeholder.length > 0
                            ? placeholder
                            : t('profile_NIF')
                        }
                        groupProps={{ className: 'row' }}
                        labelClassName="col-2 text-right"
                        containerClassName="col-10"
                      />
                    </>
                  ) : null}
                  <FormInput
                    id="phone"
                    name="phone"
                    value={phone}
                    onChange={handleChange('phone')}
                    label={t('phone')}
                    placeholder={t('phone')}
                    groupProps={{ className: 'row' }}
                    labelClassName="col-2 text-right"
                    containerClassName="col-10"
                  />
                  <FormInput
                    id="address_1"
                    name="address_1"
                    value={address_1}
                    onChange={handleAddressSelection}
                    label={t('access_session.modal.address1')}
                    placeholder={t('access_session.modal.address1')}
                    groupProps={{ className: 'row' }}
                    labelClassName="col-2 text-right"
                    containerClassName="col-10"
                    type="googleSearch"
                    inputProps={{
                      dropdownParent: '#userSelectorAddUser',
                    }}
                  />
                  <FormInput
                    id="address_2"
                    name="address_2"
                    value={address_2}
                    onChange={handleChange('address_2')}
                    label={t('access_session.modal.address2')}
                    placeholder={t('access_session.modal.address2')}
                    groupProps={{ className: 'row' }}
                    labelClassName="col-2 text-right"
                    containerClassName="col-10"
                  />
                  <FormInput
                    id="zipCode"
                    name="zipCode"
                    value={zipCode}
                    onChange={handleChange('zipCode')}
                    label={t('access_session.modal.zipCode')}
                    placeholder={t('access_session.modal.zipCode')}
                    groupProps={{ className: 'row' }}
                    labelClassName="col-2 text-right"
                    containerClassName="col-10"
                  />
                  <FormInput
                    id="city"
                    name="city"
                    value={city}
                    onChange={handleChange('city')}
                    label={t('access_session.modal.city')}
                    placeholder={t('access_session.modal.city')}
                    groupProps={{ className: 'row' }}
                    labelClassName="col-2 text-right"
                    containerClassName="col-10"
                  />
                  <FormInput
                    id="state"
                    name="state"
                    value={state}
                    onChange={handleChange('state')}
                    label={t('access_session.modal.state')}
                    placeholder={t('access_session.modal.state')}
                    groupProps={{ className: 'row' }}
                    labelClassName="col-2 text-right"
                    containerClassName="col-10"
                  />
                  <FormInput
                    id="countryCode"
                    name="countryCode"
                    value={countryCode}
                    onChange={handleChange('countryCode')}
                    label={t('access_session.modal.country')}
                    placeholder={t('access_session.modal.country')}
                    groupProps={{ className: 'row' }}
                    labelClassName="col-2 text-right"
                    containerClassName="col-10"
                    type="select2"
                    inputProps={inputProps('country')}
                  />
                  <FormInput
                    id="language"
                    name="language"
                    value={language}
                    onChange={handleChange('language')}
                    label={t('language')}
                    placeholder={t('language')}
                    groupProps={{ className: 'row' }}
                    labelClassName="col-2 text-right"
                    containerClassName="col-10"
                    type="select2"
                    inputProps={inputProps('language')}
                  />
                  <FormInput
                    id="currency"
                    name="currency"
                    value={currency}
                    onChange={handleChange('currency')}
                    label={t('currency')}
                    placeholder={t('currency')}
                    groupProps={{ className: 'row' }}
                    labelClassName="col-2 text-right"
                    containerClassName="col-10"
                    type="select2"
                    inputProps={inputProps('currency')}
                  />
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={handleClose}
                      disabled={isLoading}
                    >
                      {t('cancel')}
                    </button>
                    <button
                      type="submit"
                      className={`btn btn-dynamic ${
                        isLoading
                          ? ' m-loader m-loader--right m-loader--light'
                          : ''
                      }`}
                      disabled={isLoading}
                      onClick={submitHandler}
                    >
                      {t('confirm')}
                    </button>
                  </div>
                </>
              )}
            </Form>
          </div>
        </div>
      </div>
    </Modal>
  );
}

AddNewUserModal.propTypes = {
  t: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  _csrf: PropTypes.string.isRequired,
};
AddNewUserModal.defaultProps = {};
export default compose(translate, withCSRF)(AddNewUserModal);
