import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ImageCropModal from '../imageCrop/ImageCropModal';
import translate from '../translate';
import { useModal } from '../../services/customHooks';
import Loader from '../../services/loader';

function ImageInput(props) {
  const {
    t,
    id,
    inputId,
    name,
    type,
    groupClassName,
    labelClassName,
    containerClassName,
    inputClassName,
    label,
    initialValue,
    placeholder,
    onChange,
    disabled,
    inputProps,
    handleCrop,
    newButtons,
    hint,
    showSelectImageBtn,
    isCustom,
  } = props;

  const loader = useRef(new Loader(`#image_input_container_${inputId}`));
  const [isLoading, setLoading] = useState(false);
  const [url, setUrl] = useState(initialValue || placeholder);
  const [showActions, setShowActions] = useState(!!initialValue);
  const [{ show: showCropTool }, setShowCropTool] = useModal({ show: false });
  const [{ originalFile, fileName, fileType }, setFile] = useState({
    originalFile: null,
    fileName: '',
    fileType: '',
  });

  const canChange = !disabled && onChange;
  const isImage = type === 'image';
  const isVideo = type === 'video';

  const resetState = () => {
    const input = document.querySelector(`#${inputId}`);
    if (input) {
      input.value = null;
    }
    setShowCropTool({ show: false });
    setFile({ originalFile: null, fileName: '' });
  };

  const readAsDataUrl = blob => {
    setLoading(true);
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.onload = readerEvt => resolve(readerEvt.target.result);
      reader.readAsDataURL(blob);
      setLoading(false);
    });
  };

  const handleModalClose = blob => {
    if (blob) {
      onChange({ blob, fileName });
      readAsDataUrl(blob).then(dataUrl => {
        resetState();
        setShowActions(true);
        setUrl(dataUrl);
      });
    } else {
      resetState();
    }
  };

  const clearImage = () => {
    setUrl(placeholder);
    setShowActions(false);
    resetState();
    onChange(null);
  };

  const handleChange = evt => {
    const file = evt.currentTarget.files[0];
    if (isImage && handleCrop) {
      readAsDataUrl(file).then(dataUrl => {
        setFile({
          originalFile: dataUrl,
          fileName: file.name,
          fileType: file.type,
        });
        setShowCropTool({ show: true });
      });
    } else {
      setFile({ originalFile: file, fileName: file.name, fileType: file.type });
    }
  };

  const handleImageClick = () => {
    const input = document.querySelector(`#${inputId}`);
    if (input) {
      input.click();
    }
  };

  useEffect(() => {
    if (isVideo || !handleCrop) {
      handleModalClose(originalFile);
    }
  }, [originalFile]);

  useEffect(() => {
    if (loader.current) {
      if (isLoading) {
        loader.current.show();
      } else {
        loader.current.hide();
      }
    }
  }, [isLoading]);

  return (
    <div id={`image_input_container_${inputId}`} className={groupClassName}>
      <label htmlFor={inputId} className={labelClassName}>
        {label}
        {canChange && (
          <input
            type="file"
            accept="image/*"
            id={inputId}
            name={inputId}
            style={{ display: 'none' }}
            onChange={handleChange}
          />
        )}
      </label>
      <div className={`image-input-container ${containerClassName}`}>
        {(isImage || !showActions) && (
          <>
            <img
              style={canChange ? { cursor: 'pointer' } : undefined}
              onClick={handleImageClick}
              id={id}
              name={name}
              className={inputClassName}
              src={url}
              alt="upload placeholder"
            />
            {showSelectImageBtn && !showActions ? (
              <button
                id={`selectImage_${id}`}
                type="button"
                className="btn-selectImage"
                onClick={handleImageClick}
              >
                {t('access_session.detail.selectImage')}
              </button>
            ) : null}
          </>
        )}
        {isVideo && showActions && (
          <video
            style={canChange ? { cursor: 'pointer' } : undefined}
            onClick={handleImageClick}
            id={id}
            className={inputClassName}
            src={url}
          />
        )}
        {!isCustom && showActions && !disabled && (
          <div
            className={
              !newButtons
                ? 'image-input-actions'
                : 'new-image-input-actions col-7'
            }
          >
            <button
              onClick={!newButtons ? clearImage : handleImageClick}
              type="button"
              className={
                !newButtons
                  ? 'btn m-btn--square btn-danger'
                  : 'newButtons newButtons-edit'
              }
            >
              {!newButtons ? t('remove') : <i className="fa fa-pencil-alt" />}
            </button>
            <button
              onClick={!newButtons ? handleImageClick : clearImage}
              type="button"
              className={
                !newButtons
                  ? 'btn m-btn--square btn-secondary'
                  : 'newButtons newButtons-remove'
              }
            >
              {!newButtons ? t('change') : <i className="fa fa-trash" />}
            </button>
          </div>
        )}
        {isCustom ? (
          <div className="button-wrapper">
            <button
              className="btn btn-dynamic upload-icon-btn"
              type="button"
              onClick={handleImageClick}
            >
              {t('settings.general.levels.upload_icon')}
            </button>
            {/* NOT TO IMPLEMENT NOW */}
            {/* <button */}
            {/*  className="default-icons-btn" */}
            {/*  type="button" */}
            {/*  onClick={() => {}} */}
            {/* > */}
            {/*  {t('settings.general.levels.choose_icon_from_list')} */}
            {/* </button> */}
          </div>
        ) : null}
        <span
          id={`error-image_${id}`}
          className="help-block help-block-error"
          style={{ display: 'none' }}
        >
          {t('ERROR_FIELD_REQUIRED')}
        </span>
        {hint && (
          <span id={`hintImage_${id}`} className="m-form__help">
            {hint}
          </span>
        )}
      </div>
      {showCropTool && (
        <ImageCropModal
          t={t}
          title={t('image_crop')}
          originalFile={originalFile}
          fileType={fileType}
          onFinished={handleModalClose}
          {...inputProps}
        />
      )}
    </div>
  );
}

ImageInput.propTypes = {
  t: PropTypes.func.isRequired,
  hint: PropTypes.string,
  id: PropTypes.string,
  inputId: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.oneOf(['image', 'video']),
  label: PropTypes.string,
  initialValue: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  groupClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  disabled: PropTypes.bool,
  inputProps: PropTypes.shape({
    cropWidth: PropTypes.number,
    cropHeight: PropTypes.number,
    cropAspect: PropTypes.number,
    cropLocked: PropTypes.bool,
  }),
  handleCrop: PropTypes.bool,
  newButtons: PropTypes.bool,
  showSelectImageBtn: PropTypes.bool,
  isCustom: PropTypes.bool,
};

ImageInput.defaultProps = {
  id: 'image',
  inputId: 'fileInputUpload',
  name: 'image',
  type: 'image',
  label: '',
  hint: '',
  initialValue: null,
  placeholder: '/images/no_image_upload.png',
  onChange: null,
  groupClassName: 'form-group m-form__group row',
  labelClassName: 'col-2',
  containerClassName: 'col-7',
  inputClassName: 'image-input-preview',
  disabled: false,
  inputProps: {},
  handleCrop: true,
  newButtons: false,
  showSelectImageBtn: false,
  isCustom: false,
};

export default translate(ImageInput);
