import axios from 'axios';
import * as moment from 'moment';
import Request from './request';
import Notifications from './notifications';
import i18n from './i18n';

const USER_ROLE = {
  SUPER_ADMIN: 'super_admin',
  INSTANCE_ADMIN: 'instance_admin',
  ADMIN: 'admin',
  USER: 'user',
  MEMBER: 'member',
  GUEST: 'guest',
};

const USER_ROLES = Object.values(USER_ROLE);

const USER_TYPE = {
  COLLABORATOR: 'collaborator',
  CUSTOMER: 'customer',
  LEAD: 'lead',
  RECOMMENDATION: 'recommendation',
  VISITOR: 'visitor',
};

const USER_TYPES = Object.values(USER_TYPE);

const SUBSCRIPTION_TYPE = {
  FREE: 'free', // Used for internal use, or specific cases with full access
  TRIAL: 'trial', // Organizations that Subscribed the cloud platform and have a trial period
  CLOUD_FREE: 'cloud_free', // Organizations that Subscribed the cloud platform and have less than 10 users
  CLOUD: 'cloud', // Organization on the cloud platform that finished the trial or over passed the 10 users
  ENTERPRISE: 'enterprise', // Enterprise clients with specific tenants
  RESELLER_ARENDT: 'reseller_arendt',
  RESELLER_WEMANITY: 'reseller_wemanity',
};
const SUBSCRIPTION_TYPES = Object.values(SUBSCRIPTION_TYPE);

const PROFILE_BLOCK = {
  INFO: 'info',
  COMPANY: 'company',
  CAREER: 'career',
  EXPERIENCE: 'experience',
  HOBBIES: 'hobbies',
  SOFT_SKILLS: 'softSkills',
  HARD_SKILLS: 'hardSkills',
  LANGUAGES: 'languages',
  CONTACTS: 'contacts',
  MOODS: 'moods',
  MEDIA: 'media',
  SOCIAL_MEDIA: 'socialMedia',
  LEGAL_FINANCES: 'legalFinances',
  AVAILABILITY: 'availability',
  DOCUMENTS: 'documents',
};

const PROFILE_BLOCKS = Object.values(PROFILE_BLOCK);

const PROFILE_TAB = {
  ...PROFILE_BLOCK,
  SETTINGS: 'settings',
  PERFORMANCE: 'performance',
  POSTER: 'poster',
  WALLET: 'wallet',
  SESSIONS: 'sessions',
  COURSES: 'courses',
  AVAILABILITY: 'availability',
  TIMELINE: 'timeline',
  DOCUMENTS: 'documents',
  SIGNED_BY_ME: 'signed_by_me',
};

const TRAINER_BLOCK = {
  GENERAL: 'general',
  EXPERIENCE: 'experience',
  SKILLS: 'skills',
  LANGUAGE: 'language',
  AVAILABILITY: 'availability',
};
const TRAINER_BLOCKS = Object.values(TRAINER_BLOCK);
const TRAINER_TAB = {
  ...TRAINER_BLOCK,
  SETTINGS: 'settings',
  SESSIONS: 'sessions',
  COURSES: 'Courses',
};

const PROFILE_TABS = Object.values(PROFILE_TAB);

const CHALLENGE_TAB = {
  TIMELINE: 'timeline',
  STATS: 'stats',
  INFO: 'info',
  LEADERBOARD: 'leaderboard',
  LEADS: 'leads',
  RECOMMENDATIONS: 'recommendations',
  COURSE: 'course',
  PERFORMANCE: 'performance',
};

const CHALLENGE_TABS = Object.values(CHALLENGE_TAB);

const INBOX_MSG_TYPE = {
  INFO: 'info',
  RATING: 'rating',
  LIKE: 'like',
  COMMENT: 'comment',
  GAME_START: 'game_start',
  GAME_END: 'game_end',
  TROPHY: 'trophy',
  NEWS: 'news',
  MAGAZINE: 'magazine',
  SURVEY: 'survey',
  CUSTOM: 'custom',
  CHAT_JOINED: 'chat_joined',
  HIGHLIGHT_SURVEY_QUESTION: 'highlight_survey_question',
  EVENT: 'event',
  NEW_TIP: 'new_tip',
  PROFILING: 'profiling',
  DRIVE: 'drive',
};

const INBOX_MSG_TYPES = Object.values(INBOX_MSG_TYPE);

const INBOX_FILTER = {
  CHALLENGES: 'challenges',
  NEWS: 'news',
  FEED: 'feed',
  SURVEYS: 'surveys',
};

const INBOX_FILTERS = Object.values(INBOX_FILTER);

const TROPHY_CONFIG = {
  scores: {
    translationKey: 'trophy_scores',
    imageURL: '/images/trophies/trophy_scores.png',
  },
  points: {
    translationKey: 'trophy_points',
    imageURL: '/images/trophies/trophy_points.png',
  },
  winner: {
    translationKey: 'trophy_winner',
    imageURL: '/images/trophies/trophy_winner.png',
  },
  stimulus: {
    translationKey: 'trophy_stimulus',
    imageURL: '/images/trophies/trophy_stimulus.png',
  },
  topplayer: {
    translationKey: 'trophy_top_player',
    imageURL: '/images/trophies/trophy_topplayer.png',
  },
  'e-learning_1': {
    translationKey: 'trophy_level_1',
    imageURL: '/images/trophies/trophy_level_1.svg',
  },
  'e-learning_2': {
    translationKey: 'trophy_level_2',
    imageURL: '/images/trophies/trophy_level_2.svg',
  },
  'e-learning_3': {
    translationKey: 'trophy_level_3',
    imageURL: '/images/trophies/trophy_level_3.svg',
  },
  'e-learning_4': {
    translationKey: 'trophy_level_4',
    imageURL: '/images/trophies/trophy_level_4.svg',
  },
  custom: {
    translationKey: 'trophy_custom',
    imageURL: '/images/trophies/trophy_custom.svg',
  },
};

const GAME_TYPE = {
  PRODUCTIVITY: 'productivity',
  CROSS_SELLING: 'cross-selling',
  PROFILING: 'profiling',
  E_LEARNING: 'e-learning',
  CANDIDATES: 'candidates',
};

const GAME_TYPES = Object.values(GAME_TYPE);

const GAME_STATUS = {
  UNSTARTED: 'unstarted',
  ON_GOING: 'ongoing',
  FINISHED: 'finished',
  PERMANENT: 'permanent',
  NONE: '',
};

const GAME_UNITY = {
  POINTS: 'points',
  PERCENTAGE: 'percentage',
  CURRENCY: 'currency',
  VA: 'VA',
};

const GAME_FREQUENCY = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  QUARTERLY: 'quarterly',
  YEARLY: 'yearly',
};

const GAME_OBJECTIVE = {
  PERSONAL: 'personal',
  TEAM: 'team',
};

const GAME_SCORE_TYPE = {
  FIXED: 'fixed',
  CUSTOM: 'custom',
};

const RECOMMENDATIONS_CATEGORY = {
  PROFILES: 'profiles',
  SOLUTIONS: 'solutions',
  PARTNERS: 'partners',
  OTHERS: 'others',
};

const GAME_SERVICE = {
  JIRA: 'jira',
  WOOCOMMERCE: 'wooCommerce',
  TROPHY: 'trophy',
  RATING: 'rating',
  MARKETPLACE: 'marketPlace',
  OTHER: 'other',
  CONNECT: 'connect',
};

const GAME_STATUSES = Object.values(GAME_STATUS);

const GAME_EVENT_TYPE = {
  INFO: 'info',
  RANKING_POSITION: 'raking_position',
  GAME_START: 'game_start',
  GOAL_80: 'goal_80',
  GOAL_90: 'goal_90',
  GOAL_100: 'goal_100',
  GAME_WIN: 'game_win',
  TROPHY: 'trophy',
  LEAD_CREATED: 'lead_created',
  LEAD_CHANGED_OWNER: 'lead_changed_owner',
  LEAD_ATTACH_OWNER: 'lead_attach_owner',
  LEAD_NO_OWNER: 'lead_no_owner',
  LEAD_CHANGED: 'lead_changed',
  LEAD_WON: 'lead_won',
  LEAD_LOST: 'lead_lost',
};

const GAME_EVENT_TYPES = Object.values(GAME_EVENT_TYPE);

const LEAD_CONDITION = {
  LEADS: 'leads',
  NEED_DISCOVERED: 'need_discovered',
  PROPOSAL: 'proposal',
};

const LEAD_CONDITIONS = Object.values(LEAD_CONDITION);

const LEAD_STATUS = {
  NO_OWNER: 'no_owner',
  WAITING_OWNER: 'waiting_owner',
  IN_PROGRESS: 'in-progress',
  LOST: 'lost',
  WON: 'won',
};

const LEAD_STATUSES = Object.values(LEAD_STATUS);

const LEAD_DETAILS_TAB = {
  TIMELINE: 'timeline',
  INFO: 'info',
};

const LEAD_DETAILS_TABS = Object.values(LEAD_DETAILS_TAB);

const COURSE_STATUS = {
  UN_STARTED: 'un_started',
  FINISHED: 'finished',
  IN_PROGRESS: 'in_progress',
  WAITING: 'waiting',
  OPENED: 'opened',
};

const COURSE_STATUSES = Object.values(COURSE_STATUS);

const COURSE_PURCHASE_STATUS = {
  FREE: 'free',
  PAID: 'paid',
  UNPAID: 'unpaid',
};

const PRODUCT_TYPE = {
  PRODUCT: 'product',
  GIFT: 'gift',
  PACK: 'pack',
  DOWNLOADABLE: 'downloadable',
};

const PRODUCT_TYPES = Object.values(PRODUCT_TYPE);

const isCourseEnrolled = course =>
  course.purchaseStatus === COURSE_PURCHASE_STATUS.PAID ||
  course.status === COURSE_STATUS.IN_PROGRESS ||
  course.status === COURSE_STATUS.FINISHED;

const isCourseEnrolledOrFree = (loggedUser, course) => {
  const isFree = !course.inAppProduct || !course.inAppProduct.price;
  return loggedUser && (isCourseEnrolled(course) || isFree);
};

const COURSE_PROVIDER = {
  BRAVON: 'bravon',
  SCORM: 'scorm',
};

const COURSE_PROVIDERS = Object.values(COURSE_PROVIDER);

const VOUCHER_STATUS = {
  EXPIRED: 'expired',
  USED: 'used',
  REVOKED: 'revoked',
  ACTIVE: 'active',
};

const VOUCHER_STATUSES = Object.values(VOUCHER_STATUS);

const MODULE_TYPE = {
  LESSONS: 'lessons',
  TROPHY: 'trophy',
  CERTIFICATE: 'certificate',
  INFO: 'info',
};

const MODULE_TYPES = Object.values(MODULE_TYPE);

const MODULE_STATUS = {
  DONE: 'done',
  LOCKED: 'locked',
  UNLOCKED: 'unlocked',
  IN_PROGRESS: 'in_progress',
};

const MODULE_STATUSES = Object.values(MODULE_STATUS);

const LESSON_TYPE = {
  VIDEO: 'video',
  PDF: 'pdf',
  IMAGES: 'images',
  QUIZ: 'quiz',
  YOUTUBE: 'youtube',
};

const LESSON_TYPES = Object.values(LESSON_TYPE);

const LESSON_CONTENT_TYPE = {
  VIDEO: 'video',
  PDF: 'pdf',
  IMAGE: 'image',
  MOTION: 'motion',
  WHITEBOARD: 'whiteboard',
  INTERVIEW: 'interview',
};

const LESSON_CONTENT_TYPES = Object.values(LESSON_CONTENT_TYPE);

const LESSON_STATUS = {
  DONE: 'done',
  LOCKED: 'locked',
  UNLOCKED: 'unlocked',
};

const LESSON_STATUSES = Object.values(LESSON_STATUS);

const QUESTION_STATUS = {
  CORRECT: 'correct',
  INCORRECT: 'incorrect',
  NOT_ANSWERED: 'not_answered',
};

const QUESTION_STATUSES = Object.values(QUESTION_STATUS);

const JOURNEY_STATUS = {
  UN_STARTED: 'un_started',
  IN_PROGRESS: 'in_progress',
  FINISHED: 'finished',
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected',
};

const JOURNEY_STATUSES = Object.values(JOURNEY_STATUS);

const JOURNEY_REPORT_STEP_STATUS = {
  DONE: 'done',
  NOT_DONE: 'not_done',
};

const JOURNEY_REPORT_STEP_STATUSES = Object.values(JOURNEY_REPORT_STEP_STATUS);

const JOURNEY_STEP_STATUS = {
  UNLOCKED: 'unlocked',
  LOCKED: 'locked',
  IN_PROGRESS: 'in_progress',
  DONE: 'done',
  WAITING_APPROVAL: 'waiting_approval',
  REJECTED: 'rejected',
};

const JOURNEY_STEP_STATUSES = Object.values(JOURNEY_STEP_STATUS);

const JOURNEY_TASK_STATUS = {
  PENDING: 'pending',
  APPROVED: 'approved',
  REJECTED: 'rejected',
};

const JOURNEY_TASK_STATUSES = Object.values(JOURNEY_TASK_STATUS);

const JOURNEY_STEP_TYPE = {
  TASK: 'task',
  GAME: 'game',
  COURSE: 'course',
  SURVEY: 'survey',
  SESSION: 'session',
  SIGNATURE: 'signature',
  TROPHY: 'trophy',
  PROFILE_INFO: 'profile_info',
  PROFILE_COMPANY: 'profile_company',
  PROFILE_CAREER: 'profile_career',
  PROFILE_EXPERIENCE: 'profile_experience',
  PROFILE_HOBBIES: 'profile_hobbies',
  PROFILE_SOFT_SKILLS: 'profile_soft_skills',
  PROFILE_HARD_SKILLS: 'profile_hard_skills',
  PROFILE_LANGUAGES: 'profile_languages',
  PROFILE_CONTACTS: 'profile_contacts',
  PROFILE_MOODS: 'profile_moods',
  PROFILE_MEDIA: 'profile_media',
  PROFILE_SOCIAL_MEDIA: 'profile_social_media',
  PROFILE_LEGAL_FINANCES: 'profile_legal_finances',
  PROFILE_DOCUMENTS: 'profile_documents',
  MARKET_PRODUCT_PURCHASE: 'market_product_purchase',
  DOCUMENT: 'document',
  FILL_AND_SIGN: 'fill_and_sign',
};

const JOURNEY_STEP_TYPES = Object.values(JOURNEY_STEP_TYPE);

const journeyStepTitle = step => {
  switch (step.type) {
    case JOURNEY_STEP_TYPE.GAME:
      return step.game ? step.game.name : 'N/A';
    case JOURNEY_STEP_TYPE.COURSE:
      return step.course ? step.course.name : 'N/A';
    case JOURNEY_STEP_TYPE.SURVEY:
      return step.survey ? step.survey.title : 'N/A';
    default:
      return i18n.__(`journey_stepType_${step.type}`);
  }
};

const SURVEY_TYPE = {
  SURVEY: 'survey',
  EVENT: 'event',
};

const SURVEY_TYPES = Object.values(SURVEY_TYPE);

const SURVEY_QUESTION_TYPE = {
  CHOICE: 'choice',
  OPTIONS: 'options',
  MULTIPLE_ANSWERS: 'multiple_answers',
  EVALUATION: 'evaluation',
  OPINION: 'opinion',
};

const SURVEY_QUESTION_TYPES = Object.values(SURVEY_QUESTION_TYPE);

const WORK_TIME = {
  H7_16: '7h-16h',
  H8_17: '8h-17h',
  H9_18: '9h-18h',
  H10_19: '10-19h',
  OTHER: 'other',
};

const WORK_TIMES = Object.values(WORK_TIME);

const VEHICLE = {
  ON_FOOT: 'on_foot',
  BICYCLE: 'bicycle',
  MOTORCYCLE: 'motorcycle',
  CAR: 'car',
  TRUCK: 'truck',
  BUS: 'bus',
  TAXI: 'taxi',
  SUBWAY: 'subway',
  TRAIN: 'train',
  SHIP: 'ship',
  PLANE: 'plane',
};

const VEHICLES = Object.values(VEHICLE);

const IN_APP_PRODUCT_TYPE = {
  POSTER: 'poster',
  CERTIFICATE: 'certificate',
  DIGITAL_CERTIFICATE: 'digital_certificate',
};

const IN_APP_PRODUCT_TYPES = Object.values(IN_APP_PRODUCT_TYPE);

const TOPIC_CHAPTER = {
  WALL: 'wall',
  PERFORM: 'perform',
  ACADEMY: 'academy',
  WHOSWHO: 'whoswho',
};

const TOPIC_CHAPTERS = Object.values(TOPIC_CHAPTER);

const TOPIC_TYPE = {
  TEXT: 'text',
  BANNER: 'banner',
};

const TOPIC_TYPES = Object.values(TOPIC_TYPE);

const SOCIAL_MEDIA_CONFIG = {
  facebook: { icon: 'socicon-facebook', name: 'Facebook' },
  instagram: { icon: 'socicon-instagram', name: 'Instagram' },
  linkedin: { icon: 'socicon-linkedin', name: 'Linked In' },
  periscope: { icon: 'socicon-periscope', name: 'Periscope' },
  twitter: { icon: 'socicon-twitter', name: 'Twitter' },
  weibo: { icon: 'socicon-weibo', name: 'Weibo' },
  youtube: { icon: 'socicon-youtube', name: 'YouTube' },
};

const SOCIAL_MEDIA_SITE = {
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  LINKEDIN: 'linkedin',
  PERISCOPE: 'periscope',
  TWITTER: 'twitter',
  WEIBO: 'weibo',
  YOUTUBE: 'youtube',
};

const SOCIAL_MEDIA_SITES = Object.values(SOCIAL_MEDIA_SITE);

const ACADEMY_LIST_ITEM_TYPE = {
  JOURNEY: 'journey',
  COURSE: 'course',
};

const ACADEMY_LIST_ITEM_TYPES = Object.values(ACADEMY_LIST_ITEM_TYPE);

const USER_DOC_TYPE = {
  NONE: 'none',
  NIB_VALIDATION: 'nib_validation',
  EMPLOYEE_FORM: 'employee_form',
  ADDITIONAL_IDENTITY: 'addicional_identity',
};

const USER_DOC_TYPES = Object.values(USER_DOC_TYPE);

const DISCOUNT_TYPE = {
  PERCENTAGE: 'percentage',
  FIXED_AMOUNT: 'fixed_amount',
  FIXED_AMOUNT_PER_PRODUCT: 'fixed_amount_per_product',
  FIXED_AMOUNT_CART: 'fixed_amount_cart',
};

const DISCOUNT_TYPES = Object.values(DISCOUNT_TYPE);

const DISCOUNT_PRODUCT_TYPE = {
  POSTER: 'poster',
  CERTIFICATE: 'certificate',
  DIGITAL_CERTIFICATE: 'digital_certificate',
  COURSE: 'course',
  JOURNEY: 'journey',
  SESSION: 'session',
};

const DISCOUNT_PRODUCT_TYPES = Object.values(DISCOUNT_PRODUCT_TYPE);

const DISCOUNT_MARKETPLACE_PRODUCT_TYPE = {
  PRODUCT_CATEGORIES: 'product_categories',
  PRODUCT: 'product',
};

const DISCOUNT_MARKETPLACE_PRODUCT_TYPES = Object.values(
  DISCOUNT_MARKETPLACE_PRODUCT_TYPE,
);

const SESSION_STATUS = {
  FINISHED: 'finished',
  LEAD: 'lead',
  CLOSED: 'closed',
  ONGOING: 'ongoing',
  PLANNED: 'planned',
  CONFIRMED: 'confirmed',
  UNSTARTED: 'un_started',
  CANCELED: 'canceled',
};

const SESSION_STATUSES = Object.values(SESSION_STATUS);

const SESSION_TYPE = {
  ONLINE: 'online',
  WEBINAR: 'webinar',
  CLASSROOM: 'classroom',
};

const SESSION_TYPES = Object.values(SESSION_TYPE);

const SESSION_MODE = {
  PRIVATE: 'private',
  PUBLIC: 'public',
};

const numberWithSpaces = x =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

const displayNumber = number => {
  if (number === '') {
    return '';
  }

  try {
    let value = numberWithSpaces(Number(number).toFixed(2));

    const decimalPlaces = value.split('.');
    if (decimalPlaces[1]) {
      value = decimalPlaces[1].length < 2 ? `${value}0` : value;
    } else {
      value = `${value}.00`;
    }

    let lang = 'en';
    if (document) {
      const data = document.querySelector('script[data-lang]');
      if (data) {
        lang = data.getAttribute('data-lang');
      }
    }
    if (lang.startsWith('fr')) {
      value = value.replace('.', ',');
    }

    return value;
  } catch (err) {
    return '';
  }
};

const displayMoney = number => {
  try {
    const numberValue = Number(number) / 100;
    const value = displayNumber(numberValue);
    return value ? `${value} €` : value;
  } catch (err) {
    return '';
  }
};

const displayUserName = user => {
  if (!user) {
    return '';
  }
  if (user.firstName && user.lastName) {
    return `${user.firstName} ${user.lastName}`;
  }
  return user.username;
};

const displayRegionalAddress = address => {
  if (!address) {
    return '';
  }
  const state = address.state ? ` ${address.state}` : '';
  return `${address.zipCode}${address.state ? ` ${state}` : ''} ${
    address.city
  }`;
};

const debounce = (func, delay) => {
  let inDebounce;
  return function debounced(...args) {
    const context = this;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => {
      func.apply(context, args);
    }, delay);
  };
};

const throttle = (func, limit) => {
  let lastFunc;
  let lastRan;
  return function throttled(...args) {
    const context = this;
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(() => {
        if (Date.now() - lastRan >= limit) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
};

const compose = (fn, ...rest) =>
  rest.length === 0 ? fn : (...args) => fn(compose(...rest)(...args));

const displayCardNumber = last4 => `•••• ${last4}`;

const capitalize = s => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

const colors = colorParam => {
  const color = colorParam === 'primary' ? 'dynamic' : colorParam;

  const fallback = {
    dynamic: '#abb7b6',
    secondary: '#ebedf2',
    metal: '#c4c5d6',
    light: '#ffffff',
    accent: '#00c5dc',
    success: '#34bfa3',
    info: '#36a3f7',
    warning: '#ffb822',
    danger: '#f4516c',
    focus: '#9816f4',
  };
  if (typeof window !== 'undefined' && window.getComputedStyle) {
    const cssVal = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue(`--${color}`);
    return cssVal || fallback[color];
  }
  return fallback[color];
};

const hexToHSL = hex => {
  // Convert hex to RGB first
  let r = 0;
  let g = 0;
  let b = 0;

  if (hex.length === 4) {
    r = `0x${hex[1]}${hex[1]}`;
    g = `0x${hex[2]}${hex[2]}`;
    b = `0x${hex[3]}${hex[3]}`;
  } else if (hex.length === 7) {
    r = `0x${hex[1]}${hex[2]}`;
    g = `0x${hex[3]}${hex[4]}`;
    b = `0x${hex[5]}${hex[6]}`;
  }

  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  const cmin = Math.min(r, g, b);
  const cmax = Math.max(r, g, b);
  const delta = cmax - cmin;
  let h = 0;
  let s;
  let l;

  if (delta === 0) {
    h = 0;
  } else if (cmax === r) {
    h = ((g - b) / delta) % 6;
  } else if (cmax === g) {
    h = (b - r) / delta + 2;
  } else {
    h = (r - g) / delta + 4;
  }

  h = Math.round(h * 60);

  if (h < 0) {
    h += 360;
  }

  l = (cmax + cmin) / 2;
  s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return { h, s, l };
};

const hslToHex = (pH, pS, pl) => {
  const h = pH / 360;
  const s = pS / 100;
  const l = pl / 100;
  let r;
  let g;
  let b;

  if (s === 0) {
    r = l;
    g = l;
    b = l;
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }
  const toHex = x => {
    const hex = Math.round(x * 255).toString(16);
    return hex.length === 1 ? `0${hex}` : hex;
  };
  return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
};

const changeLightness = (amount, color) => {
  if (!color) {
    return '';
  }
  const { h, s, l } = hexToHSL(color);
  return hslToHex(h, s, l * amount);
};

const handleImageUpload = (t, _csrf, imageState, isVideo) =>
  new Promise(resolve => {
    if (!imageState || !imageState.blob) {
      resolve(null);
    } else {
      const { fileName, blob } = imageState;
      const formData = new FormData();
      formData.append(isVideo ? 'video' : 'image', blob, fileName);
      formData.append('upload_file', true);
      const url = isVideo ? '/upload/video' : '/upload/image';

      new Request(_csrf)
        .formMulti('POST', url, formData)
        .done(result => resolve(result.data))
        .fail(err => {
          const error = isVideo ? 'error_videoUpload' : 'error_imageUpload';
          Notifications.showErrorFromRequest(err, error);
        });
    }
  });

const partitionArray = (array, partitionSize) =>
  Array(Math.ceil(array.length / partitionSize))
    .fill(0)
    .map((_, index) => index * partitionSize)
    .map(begin => array.slice(begin, begin + partitionSize));

const getSizeFromString = sizeString => {
  const SIZE_REGEX = /^([0-9]+\.?[0-9]+)px$/;
  const matches = sizeString.match(SIZE_REGEX);
  if (!matches || matches.length < 2) {
    return 0;
  }
  return Number(matches[1]);
};

const getWidthForElement = element => {
  const style = getComputedStyle(element);
  const [width, margin] = ['width', 'margin-right'].map(attr =>
    getSizeFromString(style[attr]),
  );
  return width + margin * 2;
};

const formatDateTime = (time, format = "DD MMM, 'YY - HH:mm") =>
  moment.utc(time).format(format);

const formatDate = (time, format = "DD MMM, 'YY") =>
  moment.utc(time).format(format);

const dateWithoutTimeZone = date => {
  let dateWithoutZone = date;
  if (!date.isUtc()) {
    const zone = date.utcOffset();
    dateWithoutZone = date.add(zone, 'minutes');
    dateWithoutZone = dateWithoutZone.utcOffset(0);
  }
  return dateWithoutZone;
};

const newDateWithoutTimeZone = valueInMs => {
  const timeZone = new Date(valueInMs).getTimezoneOffset() * 60000;
  return new Date(valueInMs + timeZone);
};

const uuid = () =>
  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    // eslint-disable-next-line no-bitwise
    const r = (Math.random() * 16) | 0;
    // eslint-disable-next-line no-bitwise
    const v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });

const ORDER_STATUSES = [
  { status: 'pending_payment', text: 'order_status_pending_payment', order: 0 },
  { status: 'pending', text: 'order_status_pending', order: 1 },
  { status: 'processing', text: 'order_status_processing', order: 2 },
  { status: 'completed', text: 'order_status_completed', order: 3 },
  {
    status: 'processing_error',
    text: 'order_status_processing_error',
    order: 4,
  },
  { status: 'out_of_stock', text: 'out_of_stock', order: 5 },
  { status: 'canceled', text: 'order_status_canceled', order: 6 },
  { status: 'refunded', text: 'order_status_refunded', order: 7 },
  { status: 'failed', text: 'order_status_failed', order: 8 },
  { status: 'lost', text: 'order_status_lost', order: 9 },
];

const ACADEMY_ORDER_STATUSES = [
  { status: 'pending_payment', text: 'order_status_pending_payment', order: 1 },
  { status: 'completed', text: 'order_status_completed', order: 5 },
  { status: 'canceled', text: 'order_status_canceled', order: 6 },
  { status: 'refunded', text: 'order_status_refunded', order: 7 },
  { status: 'failed', text: 'order_status_failed', order: 8 },
];

const ORDER_HISTORY_TYPES = [
  { type: 'status_change', text: 'order_status_change', order: 1 },
  { type: 'payment_method_change', text: 'payment_method_change', order: 4 },
  { type: 'refund', text: 'order_refund', order: 2 },
  { type: 'rma_add', text: 'order_rma_add', order: 3 },
  { type: 'rma_update', text: 'order_rma_update', order: 10 },
];

const findCurrencySymbol = (code, currencies) =>
  currencies.find(c => c.code === code.toUpperCase()).symbol;
const formatPrice = (centsValue, symbol) =>
  `${(Math.round(centsValue) / 100).toFixed(2)}${symbol}`;
const getNavigatorLanguage = () => {
  if (typeof window !== 'undefined') {
    if (navigator) {
      return navigator.language;
    }
  }
  return undefined;
};
const newFormatPrice = (number, isCents, code, isFloor = false) => {
  let value = Number(number);
  if (Number.isNaN(value)) return '';

  if (isCents) {
    value = isFloor ? Math.floor(number) / 100 : Math.round(number) / 100;
  }

  let strValue = `${value.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: code ? code.toUpperCase() : 'EUR',
    style: 'currency',
  })}`;
  strValue = strValue.replace(/,/g, ' ');
  return strValue;
};

const formatNumbers = (number, isCents = false, hasDecimalPlaces = true) => {
  if (typeof number !== 'number') return '';
  let value = number;
  if (isCents) {
    value = Math.round(number / 100);
  }
  let options = {};
  if (hasDecimalPlaces) {
    options = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
  }

  let strValue = value.toLocaleString('en-US', options);
  strValue = strValue.replace(/,/g, ' ');
  return strValue;
};

const formatNumbersWithZero = (
  number,
  isCents = false,
  hasDecimalPlaces = true,
) => {
  if (number === 0) return number;
  return formatNumbers(number, isCents, hasDecimalPlaces);
};

const getSeparator = separatorType => {
  const numberWithGroupAndDecimalSeparator = 10000.1;
  const separatorsArray = Intl.NumberFormat('en-US').formatToParts(
    numberWithGroupAndDecimalSeparator,
  );

  // Adapted it to our format decimal = "." group (thousands) = " "
  const ourSeparator = separatorsArray.map(s => {
    let separatorItem = s;
    if (s.type === 'group') {
      separatorItem = { type: s.type, value: ' ' };
    }
    return separatorItem;
  });

  const separator = ourSeparator.find(
    part => part.type === separatorType,
  ).value;
  return separator;
};
const showProgress = progress => {
  if (progress % 1 > 0) {
    return progress.toFixed(2);
  }
  return progress;
};

const gameStatus = (startDate, endDate) => {
  if (startDate && endDate) {
    const today = moment().valueOf();

    if (today < startDate) {
      return 'unstarted';
    }
    if (today > startDate && today < endDate) {
      return 'ongoing';
    }
    if (today > endDate) {
      return 'finished';
    }
    return '';
  }
  return 'permanent';
};
const gameStatusColor = status => {
  switch (status) {
    case 'unstarted':
      return 'success';
    case 'ongoing':
      return 'info';
    case 'finished':
      return 'warning';
    case 'permanent':
    default:
      return 'default';
  }
};

// Gets and the unity type
const getUnity = (unity, t) => {
  let infoUnity = '';
  if (unity === 'points') {
    infoUnity = 'pts';
  } else if (unity === 'percentage') {
    infoUnity = '%';
  } else if (unity === 'VA' || unity === 'va') {
    infoUnity = t('VA');
  }
  return infoUnity;
};

// Add the optional fields in billing address
const handleBillingAddressInOrder = (user, address) => {
  const billingAddress = address;
  // Add the optionals fields to billing address
  if (user?.address?.firstName) {
    billingAddress.firstName = user.address?.firstName;
  } else if (user?.firstName) {
    billingAddress.firstName = user.firstName;
  }
  if (user?.address?.lastName) {
    billingAddress.lastName = user.address?.lastName;
  } else if (user?.lastName) {
    billingAddress.lastName = user.lastName;
  }
  if (user?.address?.email) {
    billingAddress.email = user.address?.email;
  } else if (user?.email) {
    billingAddress.email = user.email;
  }
  if (user?.address?.phone) {
    billingAddress.phone = user.address?.phone;
  } else if (user?.phone) {
    billingAddress.phone = user.phone;
  }
  return billingAddress;
};
// Check if the optional fields don't exist in shipping address
const handleShippingAddressInOrder = (user, shippingAddress) => {
  const shippingAddressUpdated = shippingAddress;
  if (shippingAddress && user) {
    if (!shippingAddress.firstName) {
      shippingAddressUpdated.firstName = user?.firstName;
    }
    if (!shippingAddress.lastName) {
      shippingAddressUpdated.lastName = user?.lastName;
    }
    if (!shippingAddress.email) {
      shippingAddressUpdated.email = user?.email;
    }
    if (!shippingAddress.phone) {
      shippingAddressUpdated.phone = user?.phone;
    }
  }
  return shippingAddressUpdated;
};

const validateEmail = email =>
  String(email)
    .toLowerCase()
    .match(
      // eslint-disable-next-line max-len
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );

const convertStringToPrice = (number, isCent = true) => {
  if (number === '') {
    return null;
  }
  let updatedNumber =
    typeof number === 'number' || (number && number !== '') ? number : null;
  if (typeof updatedNumber === 'string') {
    updatedNumber = parseFloat(number.replace(',', '.'));
  }
  if (isCent) {
    updatedNumber *= 100;
  }
  return updatedNumber;
};

const PAYMENT_METHOD = {
  CREDIT_CARD: 'credit_card',
  BANK_TRANSFER: 'bank_transfer_manual',
  BANCONTACT: 'bancontact',
  PAID_BY_COMPANY: 'paid_by_company',
  PAYPAL: 'paypal',
};

const PAYMENT_METHODS = Object.values(PAYMENT_METHOD);

const checkIfQueryExist = query => {
  const conditions = {};
  Object.keys(query).forEach(k => {
    if (query[k] && query[k] !== 'null') {
      if (k === 'generalSearch') {
        conditions.search = query[k];
      } else {
        conditions[k] = query[k];
      }
    }

    if (query[k] === true || query[k] === 'true') {
      conditions[k] = true;
    }

    if (query[k] === false || query[k] === 'false') {
      conditions[k] = false;
    }
  });

  return conditions;
};

const PROMO_ERRORS = {
  CURRENT_TIME_GREATER: 'currentTimeIsGreater',
  START_DATE_GREATER: 'startDateIsGreater',
  NO_ERRORS: 'noErrors',
};

const checkPromoDate = (isEdit, accessSession) => {
  const currentTime = moment().valueOf();
  const startDateInMS = moment(
    $('#promotionPriceDateStart').val(),
    'DD MMM YYYY - HH:mm',
  ).valueOf();
  const endDateInMS = moment(
    $('#promotionPriceDateEnd').val(),
    'DD MMM YYYY - HH:mm',
  ).valueOf();

  const isEqualToInitialStartDate =
    isEdit && startDateInMS === accessSession?.promotionPriceDateStart;
  const isEqualToInitialEndDate =
    isEdit && endDateInMS === accessSession?.promotionPriceDateEnd;

  if (isEdit && isEqualToInitialStartDate && isEqualToInitialEndDate) {
    return PROMO_ERRORS.NO_ERRORS;
  }
  if (startDateInMS >= endDateInMS) {
    return PROMO_ERRORS.START_DATE_GREATER;
  }
  if (endDateInMS <= currentTime) {
    return PROMO_ERRORS.CURRENT_TIME_GREATER;
  }
  return PROMO_ERRORS.NO_ERRORS;
};

const convertMinutes = (minutes, round = false) => {
  // Calculate the number of days
  let days = Math.floor(minutes / 1440);
  if (round) {
    days = Math.round(days);
  }
  // Calculate the number of hours
  let hours = Math.floor((minutes % 1440) / 60);
  if (round) {
    hours = Math.round(hours);
  }
  // Calculate the number of minutes
  let remainingMinutes = minutes % 60;
  if (round) {
    remainingMinutes = Math.round(remainingMinutes);
  }
  if (days > 0) {
    return hours > 0 || remainingMinutes > 0
      ? i18n.__('time.days.hours.minutes', days, hours, remainingMinutes)
      : i18n.__('time.days', days);
  }
  if (hours > 0) {
    return remainingMinutes > 0
      ? i18n.__('time.hours.minutes', hours, remainingMinutes)
      : i18n.__('time.hours', hours);
  }
  return i18n.__('time.minutes', remainingMinutes);
};

const returnDateMsg = (days, hours, minutes) => {
  const daysStr = i18n.__('time.days', days);
  const hoursStr = i18n.__('time.hours', hours);
  const minutesStr = i18n.__('time.minutes', minutes);
  return `${days > 0 ? `${daysStr}` : ''}${hours > 0 ? `, ${hoursStr}` : ''}
  ${minutes > 0 ? `, ${minutesStr}` : ''}`;
};

const getDifferenceBetweenDates = (startDate, endDate) => {
  const start = moment(startDate).endOf('millisecond');
  const end = moment(endDate).endOf('millisecond');

  const days = end.diff(start, 'days');
  const hours = end.diff(start, 'hours') - days * 24;

  const minutes = end.diff(start, 'minutes') - days * 24 * 60 - hours * 60;

  return { days, hours, minutes };
};

const renderPromotionMessage = (
  t,
  promotionPriceDateStart,
  promotionPriceDateEnd,
) => {
  const currentDate = moment().valueOf();

  const promoStartDate =
    promotionPriceDateStart &&
    moment(promotionPriceDateStart).format("DD MMM, 'YY - HH:mm");
  const promoEndDate =
    promotionPriceDateEnd &&
    moment(promotionPriceDateEnd).format("DD MMM, 'YY - HH:mm");

  if (
    (!promotionPriceDateStart && !promotionPriceDateEnd) ||
    (!promotionPriceDateStart && currentDate > promotionPriceDateEnd) ||
    (promotionPriceDateStart &&
      promotionPriceDateEnd &&
      promotionPriceDateStart > promotionPriceDateEnd)
  ) {
    return null;
  }
  if (promoStartDate && !promoEndDate) {
    return t('product.variation.promotion.message.1', promoStartDate);
  }
  if (!promoStartDate && promoEndDate) {
    const { days, hours, minutes } = getDifferenceBetweenDates(
      currentDate,
      promotionPriceDateEnd,
    );
    return t(
      'product.variation.promotion.message.2',
      returnDateMsg(days, hours, minutes),
      promoEndDate,
    );
  }
  const { days, hours, minutes } = getDifferenceBetweenDates(
    promotionPriceDateStart,
    promotionPriceDateEnd,
  );
  return i18n.__(
    'product.variation.promotion.message.3',
    returnDateMsg(days, hours, minutes),
    promoStartDate,
  );
};

const getTimezoneOffsetHour = () => new Date().getTimezoneOffset() / 60;

const getMimeType = ext => {
  switch (ext) {
    case 'docx':
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    case 'xlsx':
      return 'application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    case 'csv':
    default:
      return 'text/csv';
  }
};
function downloadFile(fileToDownload) {
  const { url, ext: fileExt } = fileToDownload;
  const fallbackExt = fileToDownload?.name?.split('.')
    ? fileToDownload?.name?.split('.')[1]
    : ';';
  const ext = fileExt || fallbackExt;
  const name = fileToDownload?.originalName || fileToDownload?.name || '';
  axios({
    url,
    params: {},
    method: 'get',
    responseType: 'blob',
  }).then(response => {
    const file = new Blob([response.data], { type: getMimeType(ext) });
    const fileURL = URL.createObjectURL(file);
    const a = document.createElement('A');
    a.href = fileURL;
    a.download = `${name}.${ext}`;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  });
}

const PRODUCTS_LIST_TAB = {
  PRODUCTS: 'products',
  EXTERNAL_PRODUCTS: 'external',
};

const normalizeFilters = filters => {
  const entries = Object.entries(filters)
    // eslint-disable-next-line no-unused-vars
    .filter(([_, value]) => {
      if (Array.isArray(value)) {
        return value.length > 0;
      }

      if (typeof value === 'boolean' || typeof value === 'number') {
        return true;
      }

      return !!value;
    });

  return entries.reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: value,
    }),
    {},
  );
};

const createUUID = () => {
  const s = [];
  const hexDigits = '0123456789abcdef';
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[8] = s[13] = s[18] = s[23] = '-';
  return s.join('');
};

// FOR PDF
const handleUploadFile = async (
  _csrf,
  event,
  dataParam = null,
  fileId = null,
) => {
  let data;
  let originalName;
  let size;
  let ext;

  if (!dataParam) {
    const file = event.target.files[0];

    if (!file) {
      throw new Error('No file selected');
    }

    originalName = file.name;
    const { size: sizeParam } = file;
    const nameParts = originalName.split('.');
    const extParam = `.${nameParts[nameParts.length - 1]}`;
    size = sizeParam;
    ext = extParam;
    data = new FormData();
    data.append('file', file);
  } else {
    data = dataParam.formData;
    size = dataParam.size || null;
    ext = dataParam.ext || null;
    originalName = dataParam.originalName || null;
  }

  let endpoint = '/upload/file';
  if (fileId) {
    endpoint += `?id=${fileId}`;
  }

  const { data: response } = await axios.post(endpoint, data, {
    headers: {
      'Accept-Version': '1.0.0',
      'X-Gamification-API': 'Games',
      'X-CSRF-Token': _csrf,
      _csrf,
    },
  });

  const newNameParts = response.data.split('/');
  const newName = newNameParts[newNameParts.length - 1];

  return {
    url: response.data,
    name: newName,
    originalName: originalName || newName,
    ext,
    size,
  };
};

const snakeToCamel = str =>
  str.replace(/([-_][a-z])/g, group =>
    group.toUpperCase().replace('-', '').replace('_', ''),
  );

const ACCESSIBLE_PLATFORM = {
  BRAVON: 'bravon',
  VOD: 'vod',
};

const ACCESSIBLE_PLATFORMS = Object.values(ACCESSIBLE_PLATFORM);

/**
 * Check if the country code ES_AM is allowed for billingAddress countryCode of the user
 * accepts Spain (es), Canary Islands (ic), Ceuta (es-ce), Melilla (es-me)
 * returns true if it is
 * @param countryCode
 * @returns {boolean}
 */
const isBusinessTypeESAMAllowed = countryCode => {
  if (!countryCode) {
    return false;
  }
  return ['es', 'ic', 'es-ce', 'es-me'].includes(countryCode.toLowerCase());
};

export {
  PRODUCTS_LIST_TAB,
  USER_ROLE,
  USER_ROLES,
  USER_TYPE,
  USER_TYPES,
  SUBSCRIPTION_TYPE,
  SUBSCRIPTION_TYPES,
  PROFILE_BLOCK,
  PROFILE_BLOCKS,
  PROFILE_TAB,
  TRAINER_TAB,
  TRAINER_BLOCK,
  TRAINER_BLOCKS,
  PROFILE_TABS,
  CHALLENGE_TAB,
  CHALLENGE_TABS,
  INBOX_MSG_TYPE,
  INBOX_MSG_TYPES,
  INBOX_FILTER,
  INBOX_FILTERS,
  TROPHY_CONFIG,
  GAME_TYPE,
  GAME_TYPES,
  GAME_UNITY,
  GAME_FREQUENCY,
  GAME_OBJECTIVE,
  GAME_SERVICE,
  RECOMMENDATIONS_CATEGORY,
  GAME_STATUS,
  GAME_STATUSES,
  GAME_EVENT_TYPE,
  GAME_EVENT_TYPES,
  LEAD_CONDITION,
  LEAD_CONDITIONS,
  LEAD_STATUS,
  LEAD_STATUSES,
  LEAD_DETAILS_TAB,
  LEAD_DETAILS_TABS,
  COURSE_STATUS,
  COURSE_STATUSES,
  PRODUCT_TYPE,
  PRODUCT_TYPES,
  isCourseEnrolled,
  isCourseEnrolledOrFree,
  COURSE_PROVIDER,
  COURSE_PROVIDERS,
  VOUCHER_STATUS,
  VOUCHER_STATUSES,
  MODULE_TYPE,
  MODULE_TYPES,
  MODULE_STATUS,
  MODULE_STATUSES,
  LESSON_TYPE,
  LESSON_TYPES,
  LESSON_CONTENT_TYPE,
  LESSON_CONTENT_TYPES,
  LESSON_STATUS,
  LESSON_STATUSES,
  QUESTION_STATUS,
  QUESTION_STATUSES,
  JOURNEY_STATUS,
  JOURNEY_STATUSES,
  JOURNEY_STEP_STATUS,
  JOURNEY_STEP_STATUSES,
  JOURNEY_TASK_STATUS,
  JOURNEY_TASK_STATUSES,
  JOURNEY_STEP_TYPE,
  JOURNEY_STEP_TYPES,
  journeyStepTitle,
  SURVEY_TYPE,
  SURVEY_TYPES,
  SURVEY_QUESTION_TYPE,
  SURVEY_QUESTION_TYPES,
  WORK_TIME,
  WORK_TIMES,
  VEHICLE,
  VEHICLES,
  IN_APP_PRODUCT_TYPE,
  IN_APP_PRODUCT_TYPES,
  TOPIC_CHAPTER,
  TOPIC_CHAPTERS,
  TOPIC_TYPE,
  TOPIC_TYPES,
  SOCIAL_MEDIA_CONFIG,
  SOCIAL_MEDIA_SITE,
  SOCIAL_MEDIA_SITES,
  ACADEMY_LIST_ITEM_TYPE,
  ACADEMY_LIST_ITEM_TYPES,
  USER_DOC_TYPE,
  USER_DOC_TYPES,
  DISCOUNT_PRODUCT_TYPE,
  DISCOUNT_PRODUCT_TYPES,
  DISCOUNT_MARKETPLACE_PRODUCT_TYPE,
  DISCOUNT_MARKETPLACE_PRODUCT_TYPES,
  DISCOUNT_TYPE,
  DISCOUNT_TYPES,
  SESSION_STATUS,
  SESSION_STATUSES,
  SESSION_TYPE,
  SESSION_TYPES,
  SESSION_MODE,
  displayNumber,
  displayMoney,
  displayUserName,
  displayRegionalAddress,
  handleShippingAddressInOrder,
  handleBillingAddressInOrder,
  debounce,
  throttle,
  compose,
  colors,
  changeLightness,
  displayCardNumber,
  capitalize,
  hexToHSL,
  hslToHex,
  handleImageUpload,
  partitionArray,
  getSizeFromString,
  getWidthForElement,
  formatDate,
  formatDateTime,
  uuid,
  dateWithoutTimeZone,
  ORDER_STATUSES,
  ACADEMY_ORDER_STATUSES,
  ORDER_HISTORY_TYPES,
  findCurrencySymbol,
  formatPrice,
  showProgress,
  gameStatus,
  gameStatusColor,
  newFormatPrice,
  formatNumbers,
  formatNumbersWithZero,
  getSeparator,
  validateEmail,
  convertStringToPrice,
  PAYMENT_METHOD,
  PAYMENT_METHODS,
  GAME_SCORE_TYPE,
  checkIfQueryExist,
  checkPromoDate,
  PROMO_ERRORS,
  newDateWithoutTimeZone,
  JOURNEY_REPORT_STEP_STATUS,
  JOURNEY_REPORT_STEP_STATUSES,
  getUnity,
  convertMinutes,
  getDifferenceBetweenDates,
  returnDateMsg,
  renderPromotionMessage,
  getTimezoneOffsetHour,
  downloadFile,
  normalizeFilters,
  createUUID,
  handleUploadFile,
  snakeToCamel,
  ACCESSIBLE_PLATFORM,
  ACCESSIBLE_PLATFORMS,
  isBusinessTypeESAMAllowed,
};
