import React from 'react';

const context = {
  meta: {},
  share: {},
  configs: {},
  publicModules: {},
  branding: {},
};

export default React.createContext(context);
