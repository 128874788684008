import * as moment from 'moment';

export default {
  init(formId, config) {
    const form = $(formId);
    const error = $('.has-danger', form);
    const success = $('.has-success', form);

    const defaultConfig = {
      doNotHideMessage: true, // this option enables to show the error/success messages on tab switch.
      errorElement: 'span', // default input error message container
      errorClass: 'help-block help-block-error', // default input error message class
      focusInvalid: false, // do not focus the last invalid input

      errorPlacement(innerError, element) {
        $(element)
          .closest('div[class*="col-"]')
          .append(innerError);
      },

      invalidHandler() {
        // display error alert on form submit
        success.hide();
        error.show();
        window.scrollTo(error, -200);
      },

      highlight(element) {
        // highlight error inputs
        $(element)
          .closest('.form-group')
          .removeClass('has-success')
          .addClass('has-danger'); // set error class to the control group
      },

      unhighlight(element) {
        // revert the change done by highlight
        $(element)
          .closest('.form-group')
          .removeClass('has-danger'); // set error class to the control group
      },

      success(label) {
        // display success icon for other inputs
        label
          .addClass('valid') // mark the current input as valid and display OK icon
          .closest('.form-group')
          .removeClass('has-danger')
          .addClass('has-success'); // set success class to the control group
      },

      submitHandler(validatedForm) {
        error.hide();
        validatedForm.submit();
      },
    };

    const formConfig = { ...defaultConfig, ...config };

    const validator = form.validate(formConfig);

    return [form, error, success, validator];
  },

  config() {
    if ($.validator) {
      $.validator.addMethod(
        'gt',
        function validate(value, element, param) {
          const $min = $(param);
          if (this.settings.onfocusout) {
            $min.off('.validate-gt').on('blur.validate-gt', () => {
              $(element).valid();
            });
          }
          return parseInt(value, 10) > parseInt($min.val(), 10);
        },
        'Max must be greater than min',
      );

      $.validator.addMethod(
        'gte',
        function validate(value, element, param) {
          const $min = $(param);
          if (this.settings.onfocusout) {
            $min.off('.validate-gte').on('blur.validate-gte', () => {
              $(element).valid();
            });
          }
          return parseInt(value, 10) >= parseInt($min.val(), 10);
        },
        'Max must be greater or equal than min',
      );

      $.validator.addMethod(
        'ifDifferent',
        function validate(value, element, param) {
          const $min = $(param);
          if (this.settings.onfocusout) {
            $min.off('.validate-ifDifferent').on('blur.validate-ifDifferent', () => {
              $(element).valid();
            });
          }
          return value === param;
        },
        'Values are not the same',
      );

      $.validator.addMethod(
        'reservedWord',
        function validate(value, element, param) {
          const $min = $(param);
          if (this.settings.onfocusout) {
            $min.off('.validate-reservedWord').on('blur.validate-reservedWord', () => {
              $(element).valid();
            });
          }
          // Only gives error if word is equal
          return value.toLowerCase().trim() !== param.toLowerCase().trim();
        },
        'Word not allowed',
      );

      $.validator.addMethod(
        'lt',
        function validate(value, element, param) {
          const $min = $(param);
          if (this.settings.onfocusout) {
            $min.off('.validate-lt').on('blur.validate-lt', () => {
              $(element).valid();
            });
          }
          return parseInt(value, 10) < parseInt($min.val(), 10);
        },
        'Max must be less than min',
      );

      $.validator.addMethod(
        'lte',
        function validate(value, element, param) {
          const $min = $(param);
          if (this.settings.onfocusout) {
            $min.off('.validate-lte').on('blur.validate-lte', () => {
              $(element).valid();
            });
          }
          return parseInt(value, 10) <= parseInt($min.val(), 10);
        },
        'Max must be less or equal than min',
      );

      $.validator.addMethod(
        'date_gt',
        function validate(value, element, param) {
          const $min = $(param);
          if (!$min.val() && !value) {
            return true;
          }
          const date1 = moment($min.val(), 'DD MMM YYYY - HH:mm').valueOf();
          const date2 = moment(value, 'DD MMM YYYY - HH:mm').valueOf();

          if (this.settings.onfocusout) {
            $min.off('.validate-date_gt').on('blur.validate-date_gt', () => {
              $(element).valid();
            });
          }
          return date2 > date1;
        },
        'End date must be greater than start date',
      );

      $.validator.addMethod(
        'image_upload',
        function validate(value, element, param) {
          const $min = $(param);

          if (this.settings.onfocusout) {
            $min
              .off('.validate-image_upload')
              .on('blur.validate-image_upload', () => {
                $(element).valid();
              });
          }

          const haveImageJson = $min.val().length > 0;
          const haveImageFile = $(element)[0] && $(element)[0].files.length > 0;

          return haveImageJson || haveImageFile;
        },
        'You must select an image',
      );

      $.validator.addMethod(
        'date_gt_1',
        function validate(value, element, param) {
          const $min = $(param);
          if (!value) {
            return true;
          }
          const date1 = moment($min.val(), 'DD MMM YYYY - HH:mm').valueOf();
          const date2 = moment(value, 'DD MMM YYYY - HH:mm').valueOf();

          if (this.settings.onfocusout) {
            $min.off('.validate-date_gt_1').on('blur.validate-date_gt_1', () => {
              $(element).valid();
            });
          }
          return date2 > date1;
        },
        'End date must be greater than start date',
      );

      $.validator.addMethod(
        'image_required',
        function validate(value, element, param) {
          const $imageId = $(param[0]);
          let $conditions = true;

          for (let i = 1; i < param.length; i++) {
            const cond = $(param[i]).val();
            if (cond) {
              $conditions = $conditions && cond;
            }
          }

          if (this.settings.onfocusout) {
            $imageId
              .off('.validate-image_required')
              .on('blur.validate-image_required', function() {
                $(element).valid();
              });
          }
          const haveImageJson = $imageId.val().length > 0;
          const haveImageFile = $(element)[0] && $(element)[0].files.length > 0;

          return (haveImageJson || haveImageFile) && $conditions;
        },
        'You must select an image',
      );

      $.validator.addMethod(
        'swift',
        value => {
          if (!value) {
            return true;
          }

          const reg = /^[A-Z]{6}[2-9A-Z][0-9A-NP-Z](XXX|[0-9A-WYZ][0-9A-Z]{2})?$/i;
          return reg.test(value);
        },
        'Please enter a valid Swift/BIC code',
      );

      $.validator.addMethod(
        'IBAN',
        value => {
          if (!value) {
            return true;
          }

          const mod97 = function validate(iban) {
            let checksum = iban.slice(0, 2);

            let fragment;
            for (let offset = 2; offset < iban.length; offset += 7) {
              fragment = String(checksum) + iban.substring(offset, offset + 7);
              checksum = parseInt(fragment, 10) % 97;
            }
            return checksum;
          };

          const CODE_LENGTHS = {
            AD: 24,
            AE: 23,
            AT: 20,
            AZ: 28,
            BA: 20,
            BE: 16,
            BG: 22,
            BH: 22,
            BR: 29,
            CH: 21,
            CR: 21,
            CY: 28,
            CZ: 24,
            DE: 22,
            DK: 18,
            DO: 28,
            EE: 20,
            ES: 24,
            FI: 18,
            FO: 18,
            FR: 27,
            GB: 22,
            GI: 23,
            GL: 18,
            GR: 27,
            GT: 28,
            HR: 21,
            HU: 28,
            IE: 22,
            IL: 23,
            IS: 26,
            IT: 27,
            JO: 30,
            KW: 30,
            KZ: 20,
            LB: 28,
            LI: 21,
            LT: 20,
            LU: 20,
            LV: 21,
            MC: 27,
            MD: 24,
            ME: 22,
            MK: 19,
            MR: 27,
            MT: 31,
            MU: 30,
            NL: 18,
            NO: 15,
            PK: 24,
            PL: 28,
            PS: 29,
            PT: 25,
            QA: 29,
            RO: 24,
            RS: 22,
            SA: 24,
            SE: 24,
            SI: 19,
            SK: 24,
            SM: 27,
            TN: 24,
            TR: 26,
          };
          const iban = String(value)
            .toUpperCase()
            .replace(/[^A-Z0-9]/g, '');
          const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/);
          // check syntax and length
          if (!code || iban.length !== CODE_LENGTHS[code[1]]) {
            return false;
          }
          // rearrange country code and check digits, and convert chars to ints
          const digits = (code[3] + code[1] + code[2]).replace(
            /[A-Z]/g,
            letter => letter.charCodeAt(0) - 55,
          );
          // final check
          return mod97(digits);
        },
        'Please enter a valid IBAN',
      );
    }
  },
};
