import PropTypes from 'prop-types';

const propType = {
  appName: PropTypes.string,
  primaryColor: PropTypes.string,
  primaryText: PropTypes.string,
  horizontalLogo: PropTypes.string,
  squareLogo: PropTypes.string,
};

export default { propType };
