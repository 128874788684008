import React from 'react';
import PropTypes from 'prop-types';
import translate from '../../translate';

/**
 * List TextFilter, used to filter lists based on a text string
 */
class TextFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: props.value };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange({ currentTarget }) {
    const { value } = currentTarget;
    this.setState({ value }, () => this.props.onChange(value));
  }

  render() {
    const { id, placeholder, isSearch, wrapperClass } = this.props;
    const { value } = this.state;

    return (
      <div className={`m-input-icon m-input-icon--left ${wrapperClass}`}>
        <input
          type="text"
          className="form-control m-input"
          placeholder={placeholder}
          id={isSearch === true ? id : 'stringSearch'}
          value={value}
          onChange={this.handleChange}
        />
        {isSearch === true ? (
          <span className="m-input-icon__icon m-input-icon__icon--left">
            <span>
              <i className="la la-search" />
            </span>
          </span>
        ) : null}
      </div>
    );
  }
}

TextFilter.propTypes = {
  /** Input html placeholder */
  placeholder: PropTypes.string,
  /** onChange handler, the text is passed as an argument */
  onChange: PropTypes.func.isRequired,
  /* Input html id */
  id: PropTypes.string,
  value: PropTypes.string,
  wrapperClass: PropTypes.string,
  isSearch: PropTypes.bool,
};

TextFilter.defaultProps = {
  id: 'generalSearch',
  placeholder: 'Search ...',
  value: '',
  wrapperClass: '',
  isSearch: true,
};

export default translate(TextFilter);
