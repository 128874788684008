function showLoader() {
  const button = $('.loader-button');
  if (button.length) {
    button
      .addClass('m-loader m-loader--right m-loader--light')
      .attr('disabled', true);
  }
}

function hideLoader() {
  const button = $('.loader-button');
  if (button.length) {
    button
      .removeClass('m-loader m-loader--right m-loader--light')
      .attr('disabled', false);
  }
}

export { showLoader, hideLoader };

export default class Request {
  static form(type, url, form) {
    showLoader();
    const serializedData = $(form).serialize();

    return $.ajax({
      url,
      type,
      data: serializedData,
      success: Request.success,
      error: Request.error,
    });
  }

  static success() {
    hideLoader();
  }

  static error(err) {
    console.error(err);
    hideLoader();
  }

  constructor(_csrf) {
    this._csrf = _csrf;
  }

  ajax(type, url, params, shouldHideLoader, isJSONParam) {
    if (!shouldHideLoader) {
      showLoader();
    }

    const data = Object.assign(
      type === 'GET' ? {} : { _csrf: this._csrf },
      params === null ? {} : params,
    );

    const isJSON = isJSONParam === undefined ? true : isJSONParam;
    const defaults = {
      url,
      type,
      data: isJSON ? JSON.stringify(data) : data,
      dataType: 'json',
      async: true,
      success: Request.success,
      error: Request.error,
    };

    const JSONSettings = { ...defaults, contentType: 'application/json' };

    return $.ajax(isJSON ? JSONSettings : defaults);
  }

  form(type, url, form) {
    showLoader();

    return $.ajax({
      url,
      type,
      data: $(form).serialize(),
      headers: {
        'X-CSRF-Token': this._csrf,
        _csrf: this._csrf,
      },
      success: Request.success,
      error: Request.error,
    });
  }

  formMulti(type, url, data) {
    showLoader();

    return $.ajax({
      url: window.location.origin + url,
      type,
      data,
      headers: {
        'X-CSRF-Token': this._csrf,
        _csrf: this._csrf,
      },
      processData: false,
      contentType: false,
      enctype: 'multipart/form-data',
      success: Request.success,
      error: Request.error,
    });
  }

  get(url, params, shouldHideLoader, isJSON) {
    return this.ajax(
      'GET',
      window.location.origin + url,
      params,
      shouldHideLoader,
      isJSON,
    );
  }

  post(url, params, shouldHideLoader, isJSON) {
    return this.ajax(
      'POST',
      window.location.origin + url,
      params,
      shouldHideLoader,
      isJSON,
    );
  }

  put(url, params, shouldHideLoader, isJSON) {
    return this.ajax(
      'PUT',
      window.location.origin + url,
      params,
      shouldHideLoader,
      isJSON,
    );
  }

  delete(url, params, shouldHideLoader, isJSON) {
    return this.ajax(
      'DELETE',
      window.location.origin + url,
      params,
      shouldHideLoader,
      isJSON,
    );
  }

  getCSRF() {
    return this._csrf;
  }
}
