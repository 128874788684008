import React from 'react';
import PropTypes from 'prop-types';
import CountryProp from '../Address/CountryProp';
import translate from '../../translate';

function LanguageInput(props) {
  const { id, label, language, onChange, value, disabled, inputProps } = props;
  return (
    <div className="language-inputContainer">
      <label htmlFor={id} className="language-label">
        {label}
      </label>
      <div className="language-inputWrapper">
        <div className="language-flag-wrapper">
          <img
            className="language-flag"
            src={language.flag.thumb}
            alt={language.name}
          />
        </div>
        <input
          className="form-control m-input language-input"
          id={id}
          type="text"
          placeholder={label}
          name={id}
          value={value}
          onChange={e => onChange(id, language.code, e.target.value)}
          disabled={disabled}
          {...inputProps}
        />
      </div>
    </div>
  );
}

LanguageInput.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  language: PropTypes.shape(CountryProp.propType).isRequired,
  value: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  inputProps: PropTypes.shape(),
};
LanguageInput.defaultProps = {
  label: '',
  value: '',
  disabled: false,
  inputProps: {},
};

export default translate(LanguageInput);
