import React from 'react';
import PropTypes from 'prop-types';
import { compose } from '../../services/utilityFunctions';

import translate from '../translate';

const ReactLoader = props => {
  const { t, children, isLoading, progress, message: messageProp } = props;

  let message = `${progress ? `${progress.toFixed(0)}% ` : ''}${t('order_status_processing')}`;
  if (!progress && messageProp) {
    message = messageProp;
  }

  return (
    <div className="loading-parent-wrapper">
      {isLoading && (
        <div className="loading-overlay">
          <div className="loading-message">
            <div className="loading-wrapper">
              <span className="message-container">{message}</span>
              <span className="m-loader m-loader--dynamic m-loader--lg" />
            </div>
          </div>
        </div>
      )}
      <div className={isLoading ? 'loading-content-container' : ''}>
        {children}
      </div>
    </div>
  );
};

ReactLoader.propTypes = {
  t: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  progress: PropTypes.number,
  message: PropTypes.string,
};

ReactLoader.defaultProps = {
  isLoading: false,
  progress: null,
  message: null,
};

export default compose(
  translate,
)(ReactLoader);
