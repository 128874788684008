import React from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';

/**
 * DatePicker component to be used as an <input type="date" /> in forms
 */
export default class DatePicker extends React.Component {
  componentDidMount() {
    const {
      id,
      value,
      onChange,
      autoClose,
      endDate,
      startDate,
      startView,
      required,
      autoComplete,
    } = this.props;

    const jqueryPicker = $(`#${id}`);
    const endDateString =
      endDate || endDate === 0
        ? moment.utc(endDate).format('DD/MM/YYYY')
        : undefined;
    const startDateString =
      startDate || startDate === 0
        ? moment.utc(startDate).format('DD/MM/YYYY')
        : undefined;
    jqueryPicker.datepicker({
      autoclose: autoClose,
      endDate: endDateString,
      startDate: startDateString,
      format: 'dd/mm/yyyy',
      startView,
    });

    if (autoComplete) {
      jqueryPicker.attr('autocomplete', autoComplete);
    }

    if (value || value === 0) {
      const date = moment.utc(value).format('DD/MM/YYYY');
      jqueryPicker.datepicker().val(date);
    }

    jqueryPicker.on('hide', evt => {
      if (required) {
        if (evt.target.value) {
          $(this)
            .closest('.form-group')
            .removeClass('has-danger')
            .addClass('has-success');
        } else {
          $(this)
            .closest('.form-group')
            .removeClass('has-success')
            .addClass('has-danger');
        }
      }

      const { value: newValue } = evt.target;
      const date = newValue
        ? moment.utc(newValue, 'DD/MM/YYYY').valueOf()
        : null;
      onChange(date);
    });
  }

  componentDidUpdate(prevProps) {
    const { value, id } = this.props;
    if (value !== prevProps.value) {
      const date = value ? moment.utc(value).format('DD/MM/YYYY') : null;
      $(`#${id}`).datepicker('setDate', date);
    }
  }

  componentWillUnmount() {
    const { id } = this.props;
    $(`#${id}`).datepicker('destroy');
  }

  render() {
    const { id, className, name, placeholder, disabled } = this.props;

    return (
      <input
        id={id}
        name={name}
        type="text"
        className={className}
        placeholder={placeholder}
        disabled={disabled}
      />
    );
  }
}

DatePicker.propTypes = {
  /** Element id, required for jquery functionality */
  id: PropTypes.string.isRequired,
  /** Date value in unix epoch format from moment.valueOf() or date.getTime() */
  value: PropTypes.number,
  placeholder: PropTypes.string,
  /** Change handler, receives the value in epoch format */
  onChange: PropTypes.func.isRequired,
  /** Additional class names for the <input /> */
  className: PropTypes.string,
  /** Html name */
  name: PropTypes.string,
  /** Last valid date, passed to jquery, unlike the jQuery component the date is in epoch format
   * see https://bootstrap-datepicker.readthedocs.io/en/latest/options.html */
  endDate: PropTypes.number,
  /** First valid date, passed to jquery, unlike the jQuery component the date is in epoch format
   * see https://bootstrap-datepicker.readthedocs.io/en/latest/options.html */
  startDate: PropTypes.number,
  /** Component start view, passed to jquery */
  startView: PropTypes.string,
  /** Whether the component should auto close on date select, passed to jquery */
  autoClose: PropTypes.bool,
  /** Whether the input is required in the form */
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  autoComplete: PropTypes.string,
};

DatePicker.defaultProps = {
  placeholder: undefined,
  endDate: undefined,
  startView: undefined,
  autoClose: true,
  required: false,
  className: '',
  name: '',
  value: null,
  disabled: false,
  autoComplete: null,
};
