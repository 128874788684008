import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import Request from '../../../services/request';
import Notifications from '../../../services/notifications';
import { debounce } from '../../../services/utilityFunctions';

/**
 *  Google location search functionality as a searchable dropdown,
 *  should return the address details on dropdown selection
 */
export default function GoogleInput(props) {
  const {
    id,
    name,
    onChange,
    defaultValue,
    placeholder,
    onRequestStart,
    onRequestEnd,
    updateValue,
    onSetValue,
    disabled,
    filterByCountry,
    endpoint,
    onPlaceLocationSelection,
    maxLength,
  } = props;

  const [inputValue, setInputValue] = useState(defaultValue || '');
  const [places, setPlaces] = useState([]);

  const fetchSuggestions = useCallback(
    debounce(({ value }) => {
      let url = `${endpoint}${value}`;
      // if filter by country exist
      // send country to api current implemented in Profile - Contacts Form
      if (filterByCountry) {
        url += `&country=${filterByCountry}`;
      }
      new Request()
        .get(url, undefined, true)
        .done(({ data }) => setPlaces(data));
    }, 300),
    [filterByCountry],
  );

  useEffect(() => {
    if (updateValue) {
      setInputValue(updateValue);
    }
  }, [updateValue]);

  const handleSelection = (event, { suggestion }) => {
    event.preventDefault();
    setInputValue(suggestion.address);

    if (onRequestStart) {
      onRequestStart();
    }
    if (onPlaceLocationSelection && suggestion) {
      onPlaceLocationSelection(suggestion);
    }
    new Request()
      .get(`/address/api/${suggestion.id}`, undefined, true)
      .done(result => {
        onChange(result.data);
        if (onRequestEnd) {
          onRequestEnd();
        }
      })
      .fail(err => {
        Notifications.showErrorFromRequest(err, 'error_getAddress');
        if (onRequestEnd) {
          onRequestEnd();
        }
      });
  };

  const clearSuggestions = () => {
    setPlaces([]);
    // onChange(null);
  };
  const getSuggestionValue = address => address.address;
  const renderSuggestion = address => <div>{address.address}</div>;
  const shouldRenderSuggestions = value => value.trim().length > 2;

  const inputProps = {
    id,
    name,
    placeholder,
    value: inputValue,
    onChange: ({ currentTarget }) => {
      if (onSetValue) {
        onSetValue(currentTarget.value);
      }
      setInputValue(currentTarget.value);
    },
    autocomplete: 'new-address1',
    disabled,
  };

  if (maxLength) {
    inputProps.maxLength = maxLength;
  }

  return (
    <Autosuggest
      suggestions={places}
      onSuggestionsFetchRequested={fetchSuggestions}
      onSuggestionsClearRequested={clearSuggestions}
      getSuggestionValue={getSuggestionValue}
      onSuggestionSelected={handleSelection}
      renderSuggestion={renderSuggestion}
      shouldRenderSuggestions={shouldRenderSuggestions}
      inputProps={inputProps}
    />
  );
}

GoogleInput.propTypes = {
  /** Input html id */
  id: PropTypes.string,
  /** Input html name */
  name: PropTypes.string,
  /** onChange handler, the details of the selected address are passed as an argument */
  onChange: PropTypes.func.isRequired,
  /** Input placeholder */
  placeholder: PropTypes.string.isRequired,
  onRequestStart: PropTypes.func,
  onRequestEnd: PropTypes.func,
  updateValue: PropTypes.string,
  onSetValue: PropTypes.func,
  defaultValue: PropTypes.string,
  disabled: PropTypes.bool,
  filterByCountry: PropTypes.string,
  endpoint: PropTypes.string,
  onPlaceLocationSelection: PropTypes.func,
  maxLength: PropTypes.number,
};

GoogleInput.defaultProps = {
  id: 'googleInput',
  name: 'googleInput',
  endpoint: '/address/api/autocomplete?term=',
  defaultValue: '',
  onRequestStart: null,
  onRequestEnd: null,
  onSetValue: null,
  updateValue: null,
  disabled: false,
  filterByCountry: null,
  onPlaceLocationSelection: null,
  maxLength: null,
};
