import PropTypes from 'prop-types';
import BrandingProp from './brandingProp';
import AddressProp from '../forms/Address/AddressProp';

const propType = {
  termsAndConditions: PropTypes.string,
  privacyPolicy: PropTypes.string,
  branding: PropTypes.shape(BrandingProp.propType),
  workplaceLocations: PropTypes.shape({
    enabled: PropTypes.bool,
    list: PropTypes.arrayOf(PropTypes.shape(AddressProp.propType)),
  }),
  competenciesDomains: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      _id: PropTypes.string,
    }),
  ),
  cryptoCurrencies: PropTypes.arrayOf(PropTypes.string),
};

const defaultProp = {
  termsAndConditions: '',
  privacyPolicy: '',
  branding: {},
  cryptoCurrenciesNetwork: [],
  cryptoCurrencies: [],
  competenciesDomains: [],
  workplaceLocations: {
    enabled: false,
    list: [],
  },
};

export default { propType, defaultProp };
