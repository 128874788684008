import { useEffect, useMemo, useRef, useState } from 'react';
import Notifications from './notifications';
import Request from './request';
import i18n from './i18n';
import Loader from './loader';

function useLoader(
  url,
  defaultError,
  initialData = null,
  dependencies = [url],
  containerId,
) {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(initialData);

  useEffect(() => {
    setIsLoading(true);
    new Request()
      .get(url, undefined, true)
      .done(result => {
        setData(result.data);
        setIsLoading(false);
      })
      .fail(err => {
        setIsLoading(false);
        Notifications.showErrorFromRequest(err, defaultError);
      });
  }, dependencies);

  const loader = useRef(containerId ? new Loader(`#${containerId}`) : null);
  useEffect(() => {
    if (loader.current) {
      if (isLoading) {
        loader.current.show();
      } else {
        loader.current.hide();
      }
    }
  }, [isLoading]);

  return { isLoading, data, setData };
}

function usePaymentMethods(_csrf, containerId, propCards = [], onChange) {
  const loader = useRef(null);

  const performRequest = (method, path, params, errorKey, onSuccess) => {
    loader.current.show();
    const result = new Request(_csrf)[method](path, params);
    try {
      result
        .done(data => {
          loader.current.hide();
          onSuccess(data);
        })
        .fail(err => {
          Notifications.showErrorFromRequest(err, errorKey);
          loader.current.hide();
        });
    } catch (err) {
      Notifications.showNotificationError(i18n.__('error'), err);
      loader.current.hide();
    }
  };

  const [paymentMethods, setPaymentMethods] = useState(propCards.slice());
  useEffect(() => {
    loader.current = new Loader('#credit-card-container');
    performRequest(
      'get',
      '/payments/methods/list',
      {},
      'error_cardSelect',
      ({ data }) => setPaymentMethods(data),
    );
  }, []);

  useEffect(() => {
    if (onChange) {
      if (paymentMethods.length !== propCards.length) {
        onChange(paymentMethods.slice());
      } else {
        for (const [i, card] of paymentMethods.entries()) {
          if (card.isDefault && !propCards[i].isDefault) {
            onChange(paymentMethods.slice());
            break;
          }
        }
      }
    }
  }, [paymentMethods]);

  const onSelect = paymentMethodId => {
    const onSuccess = () => {
      setPaymentMethods(prev =>
        prev.map(c =>
          c.id === paymentMethodId
            ? { ...c, isDefault: true }
            : { ...c, isDefault: false },
        ),
      );
    };

    performRequest(
      'post',
      '/payments/methods/default',
      { paymentMethodId },
      'error_cardSelect',
      onSuccess,
    );
  };

  const onAdd = ({ token: cardToken }) => {
    const onSuccess = ({ data: card }) => {
      setPaymentMethods(prev =>
        prev
          .concat(card)
          .map(c =>
            c.id === card.id
              ? { ...c, isDefault: true }
              : { ...c, isDefault: false },
          ),
      );
    };

    performRequest(
      'post',
      '/payments/methods/credit-card',
      { cardToken },
      'error_cardAdd',
      onSuccess,
    );
  };

  const onRemove = cardId => {
    const onSuccess = () =>
      setPaymentMethods(prev => prev.filter(c => c.id !== cardId));

    performRequest(
      'delete',
      '/payments/methods',
      { cardId },
      'error_cardRemove',
      onSuccess,
    );
  };

  return {
    paymentMethods,
    handleAddCard: onAdd,
    handleRemovePaymentMethod: onRemove,
    handleSelectPaymentMethod: onSelect,
  };
}

function useScrollAnchor() {
  const contentElement = useRef(null);

  const scrollToContent = () => {
    if (contentElement.current) {
      const headerOffset = window.matchMedia('(max-width: 1024px)').matches
        ? 85
        : 120;
      const elementPosition =
        contentElement.current.getBoundingClientRect().top;
      window.scrollBy({
        top: elementPosition - headerOffset,
        behavior: 'smooth',
      });
    }
  };

  return { contentElement, scrollToContent };
}

function useModal({ show = false, data = {} }) {
  const [showModal, setRawShowModal] = useState({ show, data });

  const setShowModal = value => {
    if (!value.show) {
      document
        .querySelectorAll('.modal-backdrop.fade.show')
        .forEach(element => element.parentNode.removeChild(element));
    }
    setRawShowModal(value);
  };
  return [showModal, setShowModal];
}

function useSwipeable(receivedProps) {
  const defaultProps = {
    preventDefaultTouchmoveEvent: false,
    delta: 10,
    rotationAngle: 0,
  };

  const initialState = {
    xy: [0, 0],
    swiping: false,
    eventData: undefined,
    start: undefined,
  };

  const DIRECTION = {
    LEFT: 'Left',
    RIGHT: 'Right',
    UP: 'Up',
    DOWN: 'Down',
  };
  const EVENT = {
    TOUCH_START: 'touchstart',
    TOUCH_MOVE: 'touchmove',
    TOUCH_END: 'touchend',
  };

  function getDirection(absX, absY, deltaX, deltaY) {
    if (absX > absY) {
      if (deltaX > 0) {
        return DIRECTION.LEFT;
      }
      return DIRECTION.RIGHT;
    }
    if (deltaY > 0) {
      return DIRECTION.UP;
    }
    return DIRECTION.DOWN;
  }

  function rotateXYByAngle(pos, angle) {
    if (angle === 0) return pos;
    const angleInRadians = (Math.PI / 180) * angle;
    const x =
      pos[0] * Math.cos(angleInRadians) + pos[1] * Math.sin(angleInRadians);
    const y =
      pos[1] * Math.cos(angleInRadians) - pos[0] * Math.sin(angleInRadians);
    return [x, y];
  }

  function getHandlers(set) {
    const onStart = event => {
      // if more than a single touch don't track, for now...
      if (event.touches && event.touches.length > 1) return;

      set((state, props) => {
        const { clientX, clientY } = event.touches ? event.touches[0] : event;
        const xy = rotateXYByAngle([clientX, clientY], props.rotationAngle);
        return {
          ...state,
          ...initialState,
          eventData: { initial: [...xy], first: true },
          xy,
          start: event.timeStamp || 0,
        };
      });
    };

    const onMove = event => {
      set((state, props) => {
        if (
          !state.xy[0] ||
          !state.xy[1] ||
          (event.touches && event.touches.length > 1)
        ) {
          return state;
        }
        const { clientX, clientY } = event.touches ? event.touches[0] : event;
        const [x, y] = rotateXYByAngle([clientX, clientY], props.rotationAngle);
        const deltaX = state.xy[0] - x;
        const deltaY = state.xy[1] - y;
        const absX = Math.abs(deltaX);
        const absY = Math.abs(deltaY);
        const time = (event.timeStamp || 0) - state.start;
        const velocity = Math.sqrt(absX * absX + absY * absY) / (time || 1);

        // if swipe is under delta and we have not started to track a swipe: skip update
        if (absX < props.delta && absY < props.delta && !state.swiping) {
          return state;
        }

        const dir = getDirection(absX, absY, deltaX, deltaY);
        const eventData = {
          ...state.eventData,
          event,
          absX,
          absY,
          deltaX,
          deltaY,
          velocity,
          dir,
        };

        if (props.onSwiping) {
          props.onSwiping(eventData);
        }

        // track if a swipe is cancelable(handler for swiping or swiped(dir) exists)
        // so we can call preventDefault if needed
        let cancelablePageSwipe = false;
        if (props.onSwiping || props.onSwiped || props[`onSwiped${dir}`]) {
          cancelablePageSwipe = true;
        }

        if (
          cancelablePageSwipe &&
          props.preventDefaultTouchmoveEvent &&
          event.cancelable
        ) {
          event.preventDefault();
        }

        // first is now always false
        return {
          ...state,
          eventData: { ...eventData, first: false },
          swiping: true,
        };
      });
    };

    const onEnd = event => {
      set((state, props) => {
        let eventData;
        if (state.swiping) {
          eventData = { ...state.eventData, event };

          if (props.onSwiped) {
            props.onSwiped(eventData);
          }

          if (props[`onSwiped${eventData.dir}`]) {
            props[`onSwiped${eventData.dir}`](eventData);
          }
        }
        return { ...state, ...initialState, eventData };
      });
    };

    const attachTouch = el => {
      if (!el || !el.addEventListener) {
        return undefined;
      }
      // attach touch event listeners and handlers
      const tls = [
        [EVENT.TOUCH_START, onStart],
        [EVENT.TOUCH_MOVE, onMove],
        [EVENT.TOUCH_END, onEnd],
      ];
      tls.forEach(([e, h]) => el.addEventListener(e, h));
      // return properly scoped cleanup method for removing listeners
      return () => tls.forEach(([e, h]) => el.removeEventListener(e, h));
    };

    const onRef = el => {
      // "inline" ref functions are called twice on render, once with null then again with DOM element
      // ignore null here
      if (el === null) return;
      set(state => {
        // if the same DOM el as previous just return state
        if (state.el === el) return state;

        const addState = {};
        // if new DOM el clean up old DOM and reset cleanUpTouch
        if (state.el && state.el !== el && state.cleanUpTouch) {
          state.cleanUpTouch();
          addState.cleanUpTouch = null;
        }
        // only attach if we want to track touch
        if (el) {
          addState.cleanUpTouch = attachTouch(el);
        }

        // store event attached DOM el for comparison, clean up, and re-attachment
        return { ...state, el, ...addState };
      });
    };

    return [onRef, attachTouch];
  }

  function updateTransientState(state, props, attachTouch) {
    const addState = {};
    // clean up touch handlers if no longer tracking touches
    if (!state.cleanUpTouch) {
      // attach/re-attach touch handlers
      if (state.el) {
        addState.cleanUpTouch = attachTouch(state.el);
      }
    }
    return { ...state, ...addState };
  }

  const transientState = useRef({ ...initialState, type: 'hook' });
  const transientProps = useRef();
  transientProps.current = { ...defaultProps, ...receivedProps };

  const [onRef, attachTouch] = useMemo(
    () =>
      getHandlers(cb => {
        transientState.current = cb(
          transientState.current,
          transientProps.current,
        );
      }),
    [],
  );

  transientState.current = updateTransientState(
    transientState.current,
    transientProps.current,
    attachTouch,
  );

  return onRef;
}

function useLikesAndComments(setData, _csrf) {
  const [commentsModal, setCommentsModal] = useModal({ show: false, id: null });

  const hideCommentsModal = () => setCommentsModal({ show: false, id: null });

  const showCommentsModal = event =>
    setCommentsModal({ show: true, id: event._id });

  const addLiked = eventId => {
    setData(oldData =>
      oldData.map(e =>
        e._id !== eventId ? e : { ...e, isLiked: true, likes: e.likes + 1 },
      ),
    );
  };

  const revertLiked = eventId => {
    setData(oldData =>
      oldData.map(e =>
        e._id !== eventId ? e : { ...e, isLiked: false, likes: e.likes - 1 },
      ),
    );
  };

  const addComment = eventId => {
    setData(oldData =>
      oldData.map(e =>
        e._id !== eventId ? e : { ...e, comments: e.comments + 1 },
      ),
    );
  };

  const revertComment = eventId => {
    setData(oldData =>
      oldData.map(e =>
        e._id !== eventId ? e : { ...e, comments: e.comments - 1 },
      ),
    );
  };

  const performRequest = (url, params, defaultError) =>
    new Promise((resolve, reject) => {
      new Request(_csrf)
        .post(url, params)
        .done(resolve)
        .fail(err => {
          Notifications.showErrorFromRequest(err, defaultError);
          reject();
        });
    });

  const deleteLikeRequest = (url, params, defaultError) =>
    new Promise((resolve, reject) => {
      new Request(_csrf)
        .delete(url, params)
        .done(resolve)
        .fail(err => {
          Notifications.showErrorFromRequest(err, defaultError);
          reject();
        });
    });
  const handleAddLike = event => {
    if (!event.isLiked) {
      addLiked(event._id);
      performRequest(
        `/api/game/event/${event._id}/like`,
        undefined,
        i18n.__('error_eventLike'),
      ).catch(() => revertLiked(event._id));
    } else {
      revertLiked(event._id);
      deleteLikeRequest(
        `/api/game/event/${event._id}/like`,
        undefined,
        i18n.__('error_eventLike'),
      ).catch(() => revertLiked(event._id));
    }
  };

  const handleAddComment = (eventId, message) => {
    addComment(eventId);
    performRequest(
      '/api/comment',
      { message, event: eventId },
      i18n.__('error_eventComment'),
    ).catch(() => revertComment(eventId));
  };

  return {
    commentsModal,
    showCommentsModal,
    hideCommentsModal,
    handleAddLike,
    handleAddComment,
  };
}

const useHost = () => {
  const [host, setHost] = useState('');
  useEffect(() => {
    setHost(window.location.origin);
  }, []);
  return host;
};

export {
  useLoader,
  usePaymentMethods,
  useLikesAndComments,
  useScrollAnchor,
  useModal,
  useSwipeable,
  useHost,
};
